import { useState, useEffect, useContext } from "react";
import ViewUser from "../components/ViewUser";
import { getContent, getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllVerifyImgRequestEndPoint } from "../adapters/endpoints";
import Pagination from "../components/Pagination/Pagination";
import usePagination from "../components/Pagination/usePagination";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import { ReusableTable } from "../components/ReusableTable";

const VerificationRequests = () => {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [userIds, setUserIds] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);

  const [verificationPendingUsers, setVerificationPendingUsers] = useState<any>(
    []
  );
  const [verificationCount, setVerificationCount] = useState<any>(0);

  const [currentPage, setCurrentPage, itemsPerPage] =
    usePagination(verificationPendingUsers);

  function onSuccess(data: any) {
    setVerificationPendingUsers(data.userVerificationRequests);
    setVerificationCount(data.countUserVerificationReq)
    setLoading(false);
  }
  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContentWithPagination(findAllVerifyImgRequestEndPoint, onSuccess, onFail,currentPage);
    setLoading(true);
  }, [currentPage]);

  function handleRowClick(_id: string) {
    const user = verificationPendingUsers.find((item: any) => {
      return item?.userInfoId[0]?._id === _id;
    });

    const userIds: any = {
      _id: _id,
      verificationRequestId: user._id,
    };
    setUserIds(userIds);
    setShowUser(true);
  }

  const users = verificationPendingUsers.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?.userInfoId[0]?._id,
      name: item?.userInfoId[0]?.firstName,
      country: item?.userInfoId[0]?.address?.country,
      date: item?.createdAt?.split("T")[0],
    };
    return user;
  });

  const columns = [
    // {
    //   key: "no",
    //   label: "No.",
    // },
    {
      key: "name",
      label: "Name",
    },
    {
      key: "country",
      label: "Country",
    },
    {
      key: "date",
      label: "Date",
      isSortable: true,
    },
  ];

  return (
    <div className=" p-8  ">
      {loading ? (
        <Spinner />
      ) : (
        <div className="  max-w-3xl caret-transparent px-8 py-4    rounded-lg  bg-white shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">
              {verificationCount}
            </p>
          </div>
          <ReusableTable
            data={users}
            columns={columns}
            onRowClick={handleRowClick}
          />
          {verificationCount > itemsPerPage && (
            <div className="mt-8">
              <Pagination
                totalItems={verificationCount}
                itemsPerPage={Number(itemsPerPage)}
                setCurrentPage={setCurrentPage}
                currentPage={Number(currentPage)}
              />
            </div>
          )}
        </div>
      )}

      {showUser && (
        <ViewUser
          setShowUser={setShowUser}
          userIds={userIds}
          setVerificationPendingUsers={setVerificationPendingUsers}
        />
      )}
    </div>
  );
};

export default VerificationRequests;
