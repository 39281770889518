import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AdminManagerContext } from './components/AdminManager/AdminProvider'; 
import { SIGN_IN_ROUTE } from './constants';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, ...props }) => {
  const { admin } = useContext(AdminManagerContext);
  const [isAdminLoaded, setIsAdminLoaded] = useState(false);
  
  useEffect(() => {
    if (admin && admin?._id) {
      setIsAdminLoaded(true);
    }
  }, [admin]);

  if (!isAdminLoaded) {
    // If admin data is not yet loaded, show loading indicator or return null
    return null;
  }

  return admin && admin?.isAccountActive ? (
    <>{element}</>
  ) : (
    <Navigate to={SIGN_IN_ROUTE} replace />
  );
};

export default ProtectedRoute;
