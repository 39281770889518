import { useState, useEffect, useContext } from "react";
import ViewUser from "../components/ViewUser";
import { getContent, getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllPendingMatchPartners, listReviewVerifiedUsers } from "../adapters/endpoints";
import Pagination from "../components/Pagination/Pagination";
import usePagination from "../components/Pagination/usePagination";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import { ReusableTable } from "../components/ReusableTable";
import ViewPendingMatchPartner from "../components/ViewPendingMatchPartner";

const ReviewPendingMatchPartnerUsers = () => {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [userIds, setUserIds] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);

  const [matchPartnerUsers, setMatchPartnerUsers] = useState<any>(
    []
  );
  const [marriageCount, setMarriageCount] = useState<any>(0);

  const [currentPage, setCurrentPage, itemsPerPage] =
    usePagination(matchPartnerUsers);

  function onSuccess(data: any) {
    setMatchPartnerUsers(data.findAllPendingMatchPartner);
    setMarriageCount(data.countAllPendingMatchPartners)
    setLoading(false);
  }
  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContentWithPagination(findAllPendingMatchPartners, onSuccess, onFail,currentPage);
    setLoading(true);
  }, [currentPage]);

  function handleRowClick(_id: string) {
    const user = matchPartnerUsers.find((item: any) => {
      return item?._id === _id;
    });

    const userIds: any = {
      _id: _id,
      userId: user.userId,
      imageIds: user.imageIds
    };
    setUserIds(userIds);
    setShowUser(true);
  }

  const users = matchPartnerUsers.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?._id,
      firstName: item?.firstName,
      lastName: item?.lastName,
      createdAt: item?.createdAt.split("T")[0],
      category: item?.category
    };
    return user;
  });

  const columns = [
    // {
    //   key: "no",
    //   label: "No.",
    // },
    { //
      key: "firstName",
      label: "Name",
    },
    {
      key: "lastName",
      label: "Last name",
    },
    {
      key: "category",
      label: "Category",
    },
    {
      key: "createdAt",
      label: "Date",
      isSortable: true,
    },
  ];

  return (
    <div className=" p-8  ">
      {loading ? (
        <Spinner />
      ) : (
        <div className="  max-w-3xl caret-transparent px-8 py-4    rounded-lg  bg-white shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">
              {marriageCount}
            </p>
          </div>
          <ReusableTable
            data={users}
            columns={columns}
            onRowClick={handleRowClick}
          />
          {marriageCount > itemsPerPage && (
            <div className="mt-8">
              <Pagination
                totalItems={marriageCount}
                itemsPerPage={Number(itemsPerPage)}
                setCurrentPage={setCurrentPage}
                currentPage={Number(currentPage)}
              />
            </div>
          )}
        </div>
      )}
  {showUser && (
        <ViewPendingMatchPartner
          setShowUser={setShowUser}
          partnerIds={userIds}
          setPendingMatchPartners={setMatchPartnerUsers}
        />
      )}
    </div>
  );
};

export default ReviewPendingMatchPartnerUsers;
