import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

export const ReusableTable = ({
  data,
  columns,
  onRowClick,
}: {
  data: any;
  columns: any;
  onRowClick: any;
}) => {
  const [sortDirection, setSortDirection] = useState("desc");
  const [selectedRow, setSelectedRow] = useState<number>();

  const sortedData = [...data].sort((user1: any, user2: any) => {
    const date1: any = new Date(user1.date);
    const date2: any = new Date(user2.date);

    if (sortDirection === "asc") {
      return date1 - date2;
    } else {
      return date2 - date1;
    }
  });

  const handleTableSorting = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };
// console.log(sortedData);

  return (
    <table className="w-full">
      <thead>
        <tr className="">
        <th className="text-slate-900 border-b-2 py-4 pr-2 font-semibold text-left">
      No.
    </th>
          {columns.map((column: any) => {
            return (
              <th
                key={column.key}
                className={`text-slate-900 border-b-2  pl-20 font-semibold text-left `}
              >
                {column.label}
                {column.isSortable && (
                  <>
                    {sortDirection === "asc" ? (
                      <ChevronUp
                        onClick={() => handleTableSorting()}
                        className=" cursor-pointer ml-4 inline "
                      />
                    ) : (
                      <ChevronDown
                        onClick={() => handleTableSorting()}
                        className=" cursor-pointer ml-4 inline "
                      />
                    )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item: any,index:number) => {
            const rowNumber = index + 1;
          return (
            <tr
              key={item._id}
              onClick={() => {
                onRowClick(item._id);
                setSelectedRow(item._id);
              }}
              className={` text-left text-gray-500 font-medium hover:bg-gray-100  hover:cursor-pointer  
                     ${selectedRow === item._id && "bg-gray-100"} `}
            >
               <td className="py-3">{rowNumber}</td> 
              {columns.map((column: any) => {
                return <td className="py-3 pl-20">{item[column.key]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
