interface Props {
  text?: string;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ disabled,text, onClick = () => {} }) => {
  return (
    <button
      disabled={disabled}
      type="submit"
      className={` font-semibold  ${
        disabled ? 'bg-gray-400' : 'bg-[#2e2e2eed]'
      }  hover:bg-[#2e2e2e] text-white px-14 rounded py-4 `}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
