import { useState } from "react";

export default function usePagination(initialItems: any) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 10;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = initialItems?.slice(firstItemIndex, lastItemIndex);

  return [currentPage, setCurrentPage, itemsPerPage, currentItems];
}
