import { useContext, useEffect, useState } from 'react'
import {
  getReportedUserImage,
  getReportedUserProfilePicture
} from '../adapters/endpoints'
import { getContent } from '../adapters/get'
import { UserNameAvatar } from './userNameAvatar'
import { InfoCard } from './ViewReportedUser'
import { UserType, error } from '../constants'
import { ToastContext } from './Toast/ToastProvider'
import ImageViewer from './ImageViewer'
import { LoadingDots, SideBarSpinner } from './Spinner'
interface ResponsibleProps {
  userReportData: any
  tab: number
}
export const ResponsibleUserDetail = ({
  userReportData,
  tab
}: ResponsibleProps) => {
  const [userProfilePic, setUserProfilePic] = useState<any>()
  const [responsibleImg, setResponsibleImg] = useState<any>(null)
  const { handleCreateToast } = useContext(ToastContext)
  const [OpenZoomPic, setOpenZoomPic] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  function onSucessGettingUsersImgs (data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image.data)
  }
  useEffect(() => {
    if (Array.isArray(userReportData) && userReportData.length > 0) {
      const ResponsibleUserId = userReportData[0]?.responsibleId[0]?._id
      const USER_PROFILE_IMG = `${getReportedUserProfilePicture}${ResponsibleUserId}`
      const RESPONSIBE_IMAGE = `${getReportedUserImage}${ResponsibleUserId}`

      getContent(USER_PROFILE_IMG, onSucessGettingUsersImgs, handleCreateToast)
      getContent(RESPONSIBE_IMAGE, setResponsibleImg, handleCreateToast)
      
    }
  }, [userReportData])
  
  return (
    <div
      className={`${
        tab === 2 ? 'block  ' : 'hidden'
      } scrollbar-hide h-[75vh] overflow-auto `}
    >
      {userReportData ? (
        <div>
          {userReportData.map((reportData: any, index: number) => {
            const responsible = reportData.responsibleId[0]
            const avatar = userProfilePic ? (
              userProfilePic
            ) : (
              <UserNameAvatar
                userName={responsible?.firstName}
                userType={UserType.SENDER}
              />
            )
            return (
              <div key={index}>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-col gap-1'>
                    {avatar ? (
                      <img
                      className=' object-cover		border 
                    rounded-full  h-16 w-16 '
                      src={`data:image/jpeg;base64,${userProfilePic}`}
                      alt={responsible?.firstName}
                    />
                    ) : (
                      <UserNameAvatar
                        userName={responsible?.firstName}
                        userType={UserType.SENDER}
                      />
                    )}
                  </div>
                  <div className='space-y-2'>
                    <p className='font-bold text-gray-800 text-2xl'>{`${responsible?.firstName} ${responsible?.lastName ?? ""}`}</p>
                    {responsible?.phoneNumber && <p>
                      {responsible?.phoneNumber}
                      </p>}
                    <p className='text-gray-400'>{responsible?.bio}</p>
                  </div>
                 
                  <div className='h-[60vh] overflow-auto'>
                  <div className='gap-2 divide-y-2 capitalize my-4'>
                  {responsible?.accountStatus&& (
                  <InfoCard
                    value={responsible?.accountStatus}
                    label='Account Status'
                  />
                 )}
                   {responsible?.rating&& (
                  <InfoCard
                    value={responsible?.rating}
                    label='Rate'
                  />
                 )}
                  {responsible?.gender && (
                      <InfoCard value={responsible?.gender} label='Gender' />
                    )}
                    {responsible?.age && (
                      <InfoCard value={responsible?.age} label='Age' />
                    )}

                    {responsible?.address?.country && (
                      <InfoCard
                        value={responsible?.address.country}
                        label='Country'
                      />
                    )}
                    {responsible?.region && (
                      <InfoCard value={responsible?.region} label='Region' />
                    )}
                      {responsible.lastSeenAt && <InfoCard value={responsible.lastSeenAt.split("T")[0]} label='Last Seen' />}

                 </div>
                <p className=' text-sm  text-[#999999]'>Uploaded Images</p>
                <div className='grid grid-cols-2 gap-4 my-4'>
                  {responsibleImg?.length > 0 ? (
                    <>
                      {responsibleImg?.map((img: any, idx: number) => {
                        const handleClick = () => {
                          setOpenZoomPic(true)
                          setSelectedImage(img?.image?.data)
                        }
                        return (
                          <>
                            <img
                              key={idx}
                              className=' object-cover w-full h-64 rounded-md'
                              src={`data:image/jpeg;base64,${img?.image?.data}`}
                              onClick={handleClick}
                              alt=' uploded images'
                            />
                            {OpenZoomPic && (
                              <ImageViewer
                                imageUrl={selectedImage}
                                onClose={() => setOpenZoomPic(false)}
                              />
                            )}
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <LoadingDots />

                  )}
                </div>
                {/* <div>
                  <p className='text-md text-gray-400'>Complaint</p>
                  <p className='text-gray-800 font-medium'>
                    {reportData.description}
                  </p>
                </div> */}
                </div>
              </div>

              </div>
            )
          }
          )}
        </div>
      ): <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
      <SideBarSpinner/>
      </div>}
    </div>
  )
}
