import React, { useEffect, useRef, useState } from 'react';
import { UserNameAvatar } from './userNameAvatar';
import { UserType } from '../constants';

interface UserChatListProps {
  users: any[];
  onSelect: (userId: string) => void;
}

const UserChatList: React.FC<UserChatListProps> = ({ users, onSelect}) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);


  return (
    <div   className="p-2 border border-gray-200 bg-white h-[90vh] w-fit overflow-y-auto">
    {users?.map((user,index) => (
       <React.Fragment key={user?.receiverId[0]?._id} >
       <div
            className={`cursor-pointer p-2  ${
              selectedUser === user?.receiverId[0]?._id ? 'bg-gray-200' : 'hover:bg-gray-50'
            }`}
            onClick={() => {
              setSelectedUser(user?.receiverId[0]?._id);
              onSelect(user?.receiverId[0]?._id);
            }}
          >
       {user?.receiverId[0]?.image === undefined || user?.receiverId[0]?.image === '' ? (
                      <UserNameAvatar
                        userName={user?.receiverId[0]?.firstName}
                        userType={UserType.SENDER}
                      />
                    ) : (
                      <img
                        className=' object-cover		border 
                      rounded-full  h-16 w-16 '
                        src={`data:image/jpeg;base64,${user?.receiverId[0]?.image?.data}`}
                        alt={user?.receiverId[0]?.firstName}
                      />
                    )}  
      </div>
       {index < users.length - 1 && <hr className="my-1 border-t border-gray-100" />}
     </React.Fragment> 
    ))}
  </div>
  );
};

export default UserChatList;
