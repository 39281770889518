import { useState, useEffect, useContext, useRef } from 'react';
import { findAllAdminsEndPoint } from '../../adapters/endpoints';
import { getContent } from '../../adapters/get';
import { AdminRole, error, getEnumKeyFromValue } from '../../constants';
import { ToastContext } from '../Toast/ToastProvider';
import Reviewer from './Reviewer';
import Pagination from '../Pagination/Pagination';
import usePagination from '../Pagination/usePagination';
import Spinner from '../Spinner';
import { ReusableTable } from '../ReusableTable';
import { getCountUsersRequestsBasedOnReviewerId } from '../../adapters/endpoints';

interface admin {
  createdAt: string;
  firstName: string;
  isAdminManager: boolean;
  lastName: string;
  role: string;
  __v: 0;
  _id: string;
  total: number;
  totalReviewed: number;
  totalPending: number;
}

export default function Admins() {
  const { handleCreateToast } = useContext(ToastContext);

  const [admins, setAdmins] = useState<admin[]>([]);
  const [reviewerId, setReviewerId] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<any>();
  const [showList, setShowList] = useState(false);
  const [showUser, setShowUser] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [countUsers, setCountUsers] = useState<any>([]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false);
      }
    };

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showList]);


  function onSuccess(admins: admin[]) {
    setAdmins(admins);
    setReviewerAdmins(admins);
    setReviewerId(admins[0]?._id);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  function onCountUserRequestsSuccess(data: any) {
    setCountUsers(data.countUserRequests);
  }
  useEffect(() => {
    getContent(
      getCountUsersRequestsBasedOnReviewerId,
      onCountUserRequestsSuccess,
      onFail
    );
  }, []);

  useEffect(() => {
    getContent(findAllAdminsEndPoint, onSuccess, onFail);
  }, []);

  const [reviewerAdmins, setReviewerAdmins] = useState<any[]>([]);

  const [currentPage, setCurrentPage, itemsPerPage, currentItems] =
    usePagination(reviewerAdmins);
 
  const mergeArrays = (admins: admin[], countUsers: any[]) => {
    return admins.map((admin) => ({
      ...countUsers.find(
        (countUsersData: any) => countUsersData._id === admin._id && countUsersData
      ),
      ...admin,
    }));
  };

  const updateReviewerAdmins = (newAdmins: admin[]) => {
    const reviewers = newAdmins.map((reviewer: any, idx: number) => {
      const status = () =>{
        if (reviewer?.isAccountActive === true){
          return (
            <div>
              <div className="w-4 h-4 bg-green-500 rounded-full"></div>
            </div>
          ) 
        } else{
          return (
            <div>
              <div className="w-4 h-4 bg-red-500 rounded-full"></div>
            </div>
          )
        }
      }
      const newReviewer = {
        _id: reviewer?._id,
        reviewer: reviewer?.firstName,
        role: getEnumKeyFromValue(AdminRole, reviewer?.role)
          ?.split('_')
          .join(' ')
          .toLowerCase(),
          status:status(),
        totalUsers: reviewer?.total,
        totalActions: reviewer?.totalReviewed,
        pendingActions: reviewer?.totalPending,
      };

      if (
        !newReviewer.totalActions &&
        !newReviewer.totalUsers &&
        !newReviewer.pendingActions
      ) {
        newReviewer.totalActions = 0;
        newReviewer.totalUsers = 0;
        newReviewer.pendingActions = 0;
      }
      return newReviewer;
    });
    setReviewerAdmins(reviewers);
  };

  useEffect(() => {
    const mergedArray = mergeArrays(admins, countUsers);
    updateReviewerAdmins(mergedArray);
  }, [admins, countUsers]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    const filteredAdmins = admins.filter((admin: any) =>
      admin?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    const mergedArray = mergeArrays(filteredAdmins, countUsers);
    updateReviewerAdmins(mergedArray);
  };


  const handleClick = (_id: any) => {
    setShowUser(true);
    setReviewerId(_id);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  
  return (
    <div className="m-8 ">
      <div className="flex flex-col relative space-y-2  max-w-sm ">
        <label htmlFor="duration" className="font-semibold">
          Reviewers
        </label>
        <input
          type="text"
          placeholder="Search admins"
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
          className="block w-full     rounded border-2 bg-gray-50 p-2 focus:border-blue-500  focus:ring-blue-500  dark:placeholder-gray-400  dark:focus:border-blue-500 dark:focus:ring-blue-500"
        />

        {/* {showList && (
          <ul  ref={listRef} className="shadow-md absolute top-20 w-full   bg-white max-h-[19.3rem] scrollbar-hide overflow-auto   rounded ">
            {reviewerAdmins.map((admin: any) => (
              <li
                onClick={() => {
                  setReviewerId(admin._id);
                  setShowList(false);
                  setShowUser(true);
                }}
                className="p-1 px-4 hover:bg-gray-100 "
                key={admin._id}
              >
                {admin.firstName}
              </li>
            ))}
          </ul>
        )} */}
      </div>

      {showUser && <Reviewer _id={reviewerId} setShowUser={setShowUser} />}

      {loading ? (
        <Spinner />
      ) : (
        <div className=" my-8  caret-transparent px-8 py-4  rounded-lg  bg-white shadow-sm">
          <ReusableTable
            data={reviewerAdmins}
            columns={[
              // {
              //   key: "no",
              //   label: "No.",
              // },
              {
                key: 'reviewer',
                label: 'Reviewer',
              },
              {
                key: 'role',
                label: 'Role',
              },
              {
                key: 'totalUsers',
                label: 'Total Users',
              },
              {
                key: 'totalActions',
                label: 'Total Actions',
              },
              {
                key: 'pendingActions',
                label: 'Pending Actions',
              },
              {
                key: 'status',
                label: 'Status',
              },
            ]}
            onRowClick={handleClick}
          />

          {reviewerAdmins?.length > itemsPerPage && (
            <div className="mt-8">
              <Pagination
                totalItems={reviewerAdmins?.length}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
