import { useContext, useState } from "react";
import { createContent } from "../adapters/create";
import { changePasswordEndPoint } from "../adapters/endpoints";
import Button from "../components/Button";
import InputPassword from "../components/InputForPassword";
import {
  error,
  passwordLength,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  success,
} from "../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ToastContext } from "../components/Toast/ToastProvider";
import { signOut } from "../adapters/general";
import { useNavigate } from "react-router-dom";
import { DetailSpinner } from "../components/Spinner";

const EditPassword = () => {
  const { handleCreateToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
    newPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
    confirmNewPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .oneOf([Yup.ref("newPassword")], "New Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) => {
    handleCreateToast(response.message, success);

    setValue("currentPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    setLoading(false)
  };

  const onFail = (message: string) => {
    handleCreateToast(message, error)
    setLoading(false)
  };

  return (
    <div className={`bg-white p-8`}>
      {" "}
      <div>
        <div className="grid  grid-cols-2 gap-4 ">
          <InputPassword
            label="Current Password"
            type="password"
            name="currentPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />
          <InputPassword
            label="New Password"
            type="password"
            name="newPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />{" "}
          <InputPassword
            label="Confirm New Password"
            type="password"
            name="confirmNewPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className=" divide-y-2  ">
        <div
          onClick={handleSubmit(async (data) => {
            const { newPassword, currentPassword } = data;

            const passwords = {
              newPassword,
              oldPassword: currentPassword,
            };

            createContent(changePasswordEndPoint, passwords, onSuccess, onFail);
            setLoading(true)
          })}
          className="w-max mt-6 rounded "
        >
          <div className="w-max mt-6 rounded ">
          <button className=" mt-6  font-semibold  bg-[#2e2e2e] hover:bg-[#2e2e2eed] text-white px-14 rounded py-4 " disabled = {loading}>  {loading ? <DetailSpinner /> : 'Save'}</button>
        </div>
        </div>
      </div>
    </div>
  );
};

function Settings() {
  return (
    <div className="ml-8  w-full max-w-max scrollbar-hide h-[100vh] py-8 overflow-auto  ">
      <EditPassword />{" "}
    </div>
  );
}

export default Settings;
