interface ITextArea {
  text: string;
  change?: (e: any) => void;
}

const TextArea = (Props: ITextArea) => {
  return (
    <div className="my-4 space-y-2">
      <h2 className="text-base md:text-lg font-semibold  sm:left-2">
        {Props.text}
      </h2>
      <textarea
        placeholder=""
        className="border-2 rounded  h-36 p-2 w-full"
        onChange={Props.change}
      />
    </div>
  );
};

export default TextArea;
