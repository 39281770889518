import React, { ChangeEvent, useState, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import AlertPopup from './AlertPopup'
import Button from './Button'
import Dropdown from './Dropdown'
import TextArea from './TextArea'
import {
  takeActionTextAreaText,
  giveWarningToUser,
  reportActionType,
  noteToAdmin,
  selectActionType,
  TakeActionAlertText
} from '../constants'
import { ToastContext } from './Toast/ToastProvider'
import { error, success } from '../constants'
import { createContent } from '../adapters/create'
import {
  findReportedUserEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  takeActionOnReportedUserEndPoint
} from '../adapters/endpoints'
import { getContent } from '../adapters/get'
import { UserNameAvatar } from './userNameAvatar'
import UserDetailComponent from './UserDetailComponent'
import { ResponsibleUserDetail } from './ResponsibleUserDetail'
import ImageViewer from './ImageViewer'
import Spinner, { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'

interface userReportData {
  img: string
  uploadedImgs: Array<string>
  description: string
  responsibleId: ResponsibleUser[]
  reporterId: ReporterUser[]
  report?: {
    type: string
    complaint: string
    reportedByUserId: string
    userId: string
    dateOfReporting: string
  }
}
interface ReporterUser {
  region: any
  rating: any
  _id: string
  firstName?: string
  lastName?: string
  bio?: string
  age?: string
  gender?: string
  height?: {ft:string, m:string}
  location?: string
  address?: {
    country: string
  }
  jobTitle?: string
}
interface ResponsibleUser {
  _id: string
  firstName?: string
  lastName?: string
  bio?: string
  age?: string
  gender?: string
  height?: {ft:string, m:string}
  location?: string
  address?: {
    country: string
  }
  jobTitle?: string
}

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>
  reportId: number | string
  setReports: any
}

export const InfoCard = ({ label, value }: any) => {
  return (
    <div className='py-2 px-2 bg-white border rounded-md max-w-sm my-2 mx-auto'>
      <div className='flex justify-between items-center'>
        <p className='text-black text-sm font-bold'>{label}</p>
        <p className='text-black font-bold'>{value}</p>
      </div>
    </div>
  )
}

const ViewReportedUser: React.FC<Props> = ({
  setShowUser,
  reportId,
  setReports
}) => {
  const { handleCreateToast } = useContext(ToastContext)

  const [tab, setTab] = useState(1)
  const [userReportData, setUserReportData] = useState<userReportData[]>([])
  const [userProfilePic, setUserProfilePic] = useState()
  const [OpenZoomPic, setOpenZoomPic] = useState(false)
  function onSuccessGetReportedChat (data: any) {
    setUserReportData(data.reportedUser)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  function onSucessGettingUsersImgs (data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image.data)
  }

  useEffect(() => {
    if (reportId !== 0) {
      const GET_REPORTED_USER = `${findReportedUserEndPoint}${reportId}`
      getContent(GET_REPORTED_USER, onSuccessGetReportedChat, onFail)
    }
  }, [reportId])

  const UserDetail = () => {
    const [userProfilePic, setUserProfilePic] = useState('')
    const [reporterImg, setReporterImg] = useState<any>(null)

    function onSucessGettingUsersImgs (data: any) {
      setUserProfilePic(data.userProfilePicture[0]?.image.data)
    }

    function onSucessGettingUsersUploadedImgs (data: any) {
      setReporterImg(data)
    }

    useEffect(() => {
      if (Array.isArray(userReportData) && userReportData?.length > 0) {
        const ReporterUserId = userReportData[0]?.reporterId[0]?._id
        const REPORTER_USER_PROFILE_IMG = `${getReportedUserProfilePicture}${ReporterUserId}`
        getContent(REPORTER_USER_PROFILE_IMG, onSucessGettingUsersImgs, onFail)

        const REPORTER_IMAGE = `${getReportedUserImage}${ReporterUserId}`
        getContent(REPORTER_IMAGE, onSucessGettingUsersUploadedImgs, onFail)
      }
    }, [userReportData])

    return (
      <div
        className={`${
          tab === 1 ? 'block  ' : 'hidden'
        } scrollbar-hide h-[75vh] overflow-auto  `}
      >
        {userReportData ? (
          <div>
            <SideBarSpinner />
            {userReportData.map((chatReport: any, index: number) => {
              return (
                <div className='flex flex-col gap-6 mb-4'>
                  <div className='flex flex-col gap-1 '>
                    {userProfilePic === '' || null || undefined ? (
                      <UserNameAvatar
                        userName={chatReport?.reporterId[0]?.firstName}
                        userType='sender'
                      />
                    ) : (
                      <img
                        className=' object-cover		border 
                      rounded-full  h-16 w-16 '
                        src={`data:image/jpeg;base64,${userProfilePic}`}
                        alt={chatReport?.reporterId[0]?.firstName}
                      />
                    )}
                  </div>
                  {userReportData[0].reporterId.map(
                    (reporter: ReporterUser, index: number) => {
                      return (
                        <div key={index}>
                          <div className='space-y-2'>
                            <p className='font-bold text-gray-800 text-2xl'>
                              {reporter.firstName} {reporter.lastName ?? ""}
                            </p>
                            <p className=' text-gray-400'>{reporter?.bio}</p>
                          </div>
                          <div className='grid grid-cols-2 gap-2  divide-y-2 capitalize  my-4 '>
                            {/* {reporter.height?.m ? (
                              <InfoCard
                                value={reporter?.height?.m}
                                label='Height'
                              />
                            ) : null} */}
                            {reporter.age ? (
                              <InfoCard value={reporter.age} label='Age' />
                            ) : null}
                            {reporter.gender ? (
                              <InfoCard
                                value={reporter.gender}
                                label='Gender'
                              />
                            ) : null}
                            {reporter?.address?.country ? (
                              <InfoCard
                                value={reporter?.address?.country}
                                label='Country'
                              />
                            ) : null}
                            {reporter.jobTitle ? (
                              <InfoCard value={reporter.jobTitle} label='Job' />
                            ) : null}
                             {reporter.rating ? (
                              <InfoCard value={reporter.rating} label='Rate' />
                            ) : null}
                              {reporter.region ? (
                              <InfoCard value={reporter.region} label='Region' />
                            ) : null}
                             {reporter.age ? (
                              <InfoCard value={reporter.age} label='Age' />
                            ) : null}
                          </div>
                          <p className=' text-sm  text-[#999999]'>
                            Uploaded Images
                          </p>
                          <div className='grid grid-cols-2 gap-4 my-4'>
                            {reporterImg?.length > 0 ? (
                              <div>
                                {reporterImg?.map((img: any, idx: number) => {
                                  return (
                                    <div className='grid grid-cols-2 gap-4 my-4'>
                                      <img
                                        key={idx}
                                        onClick={() => setOpenZoomPic(true)}
                                        className=' object-cover w-fit h-64 rounded-md'
                                        src={`data:image/jpeg;base64,${img?.image?.data}`}
                                        alt=' Img took for Verification'
                                      />
                                      {OpenZoomPic && (
                                        <ImageViewer
                                          imageUrl={img?.image?.data}
                                          onClose={() => setOpenZoomPic(false)}
                                        />
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            ) : (
                              <LoadingDots />

                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                  {/* <div>
                    <p className=' text-md text-gray-400'>Complaint</p>
                    <p className=' text-gray-800 font-medium'>
                      {chatReport.description}
                    </p>
                  </div> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <SideBarSpinner />
            <Spinner />
          </div>
        )}
      </div>
    )
  }

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false)
    const [selectedActionType, setSelectedActionType] = useState('')
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext)
    const [accountType,setAccountType] = useState<any>({})
    useEffect(() => {
  
      const existingAccount =localStorage.getItem('adminManager');
      if(existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, [])
    useEffect(() => {
      if(accountType?.isAdminManager === true){
        setNoteToAdminManager('adminManager')        
      }
    }, [accountType?.isAdminManager])
   
    const onSuccess = (data: any, response: any) => {
      setReports((prevReportedUsers: any) => {
        const nextReportedUsers = [...prevReportedUsers]?.filter(
          (user: any) => user._id !== reportId
        )
        return nextReportedUsers
      })

      setShowUser(false)

      handleCreateToast(response.message, success)
      setIsLoading(false);
    }

    const onFail = (message: string) =>{
      handleCreateToast(message, error);
      setIsLoading(false);
    }

    const submitReview = () => {
      setIsLoading(true); 
      
      const data = {
        // reviewerReasonForTakingAction: reviewDescription,
        actionType: selectedActionType,
        report_id: reportId,
        noteToAdminManager: noteToAdminManager
      }

      createContent(takeActionOnReportedUserEndPoint, data, onSuccess, onFail)
    }

    return (
      <div
        className={`h-full ${
          tab === 3 ? 'block  ' : 'hidden'
        } scrollbar-hide scrollbar-hide overflow-y-auto  overflow-auto  `}
      >
        <div className='flex flex-col justify-between h-full max-h-[85%] scrollbar-hide overflow-y-auto  '>
          <div>
            <h2 className=' text-lg font-semibold  mb-2 left-2'>
              {' '}
              Action Type{' '}
            </h2>
            <Dropdown
              name={selectActionType}
              list={reportActionType}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText)
              }}
            />
            {/* <TextArea
              text={takeActionTextAreaText}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setReviewDescription(e.target.value)
              }}
            /> */}
           
           {accountType?.isAdminManager === false && (
            <TextArea
              text={noteToAdmin}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setNoteToAdminManager(e.target.value)
              }}
            />
          )}
          </div>

          <Button  disabled={!selectedActionType  || !noteToAdminManager} text={giveWarningToUser} onClick={() => setShowPopup(true)} />
        </div>
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false)
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    )
  }

  return (
        <div className='  fixed inset-0 z-40 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 '>
        <div className='p-8 absolute  top-0 right-0 w-[30%] bg-white rounded-md z-50 h-full '>
          <div className='flex justify-end  '>
            <X onClick={() => setShowUser(false)} className=' cursor-pointer ' />
          </div>
          
          <div className='  h-full space-y-8 '>
            <div className='flex   justify-between font-bold '>
              <p
                onClick={() => setTab(1)}
                className={`p-2 pb-4  cursor-pointer ${
                  tab === 1 && 'border-b-4 border-[#2e2e2e]'
                }`}
              >
                Reporter Detail
              </p>
              <p
                onClick={() => setTab(2)}
                className={`p-2 pb-4  cursor-pointer ${
                  tab === 2 && 'border-b-4 border-[#2e2e2e]'
                }`}
              >
                Responsible Detail
              </p>
              <p
                onClick={() => setTab(3)}
                className={`p-2 pb-4  cursor-pointer ${
                  tab === 3 && 'border-b-4 border-[#2e2e2e]'
                }`}
              >
                Take Action
              </p>
            </div>
            {/* <UserDetail /> */}
            <UserDetailComponent userReportData={userReportData[0]} tab={tab} />
            <ResponsibleUserDetail userReportData={userReportData} tab={tab} />
            <TakeAction />
          </div>
        </div>
      </div>
   
  )
}

export default ViewReportedUser
