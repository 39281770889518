import React, { useContext, useEffect, useState } from 'react';
import UserChatList from '../components/UserChatList';
import { getChatBots } from '../adapters/endpoints';
import { getContent, getContentWithPagination } from '../adapters/get';
import { error } from '../constants';
import { ToastContext } from '../components/Toast/ToastProvider';
import ChatWindow from '../components/ChatWindow';
import Spinner from '../components/Spinner';
import { Users } from 'react-feather';

const ChatApp: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
	const [userList, setUserList] = useState([]);
  const [count, setCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setisLoading] = useState(true)
  const { handleCreateToast } = useContext(ToastContext);
  const handleUserSelect = (userId: string) => {

    setSelectedUser(userId);
  };

	const fetchNextPage = () => {
    getContentWithPagination(getChatBots, onSuccess, onFail, currentPage + 1);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const fetchPreviousPage = () => {
    if (currentPage > 1) {
      getContentWithPagination(getChatBots, onSuccess, onFail, currentPage - 1);
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

	function onSuccess(data: any) {
    setUserList(data.chatBots);
    setCount(data.countChatBots)
    setisLoading(false)
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContent(getChatBots, onSuccess, onFail);
  }, []);
  return (
    <div className='h-[100vh]'>
      {isLoading ? <Spinner /> : (
        <>
         <div className="flex text-gray-600  shadow my-2 max-w-max px-12  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">
              {count}
            </p>
          </div>
          <div className="flex flex-row  justify-center px-16 h-[70vh] pb-2">
        <div className="w-fit py-6">
        <UserChatList users={userList} onSelect={handleUserSelect}/>
      </div>
      <div className="w-3/4 p-6">
        { <ChatWindow userId={selectedUser ?? ""} />}
      </div>
      </div>
      {/* </div> */}
      </>
      )}
    </div>
  );
};

export default ChatApp;
