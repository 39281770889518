import { UserType } from "../constants";

export const UserNameAvatar = ({
    userType,
    userName,
  }: {
    userType: string;
    userName: string;
  }) => {
    return (
      <div
        className={`h-16 w-16 grid capitalize place-items-center font-semibold bg-gray-300 rounded-full ${
        
        
          userType === UserType?.USER ? "bg-yellow-200" : "bg-green-200"
        
        }`}
      >
        {userName && (userName[0] ?? "")}
      </div>
    );
  };

  export const UserNameAvatarForChat = ({
    userType,
    userName,
  }: {
    userType: string;
    userName: string;
  }) => {
    return (
      <div
        className={`h-10 w-10 grid capitalize place-items-center font-semibold bg-gray-300 rounded-full ${
          userType === UserType?.USER ? "bg-yellow-200" : "bg-green-200"
        }`}
      >
        {userName ? userName[0] :""}
      </div>
    );
  };
