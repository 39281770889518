import { useState, useEffect, useContext } from "react";
import ViewReportedUser from "../components/ViewReportedUser";
import { getContent, getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllReportedUsersEndPoint } from "../adapters/endpoints";
import Pagination from "../components/Pagination/Pagination";
import usePagination from "../components/Pagination/usePagination";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import { ReusableTable } from "../components/ReusableTable";

export default function ReportUsers() {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [reportId, setReportId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);

  const [reportedusers, setReportedUsers] = useState<any>([]);
  const [reportedUserCount, setReportedUserCount] = useState<any>(0);

  const [currentPage, setCurrentPage, itemsPerPage] =
    usePagination(reportedusers);

  function onSuccess(data: any) {
    setReportedUsers(data?.reportedUsers);
    setReportedUserCount(data?.countReportedUsers)
    setLoading(false);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContentWithPagination(findAllReportedUsersEndPoint, onSuccess, onFail,currentPage);
    setLoading(true);
  }, [currentPage]);

  function handleRowClick(_id: string) {
    setReportId(_id);
    setShowUser(true);
  }

  const columns = [
    // {
    //   key: "no",
    //   label: "No.",
    // },
    {
      key: "reporter",
      label: "Reporter",
    },
    {
      key: "responsible",
      label: "Responsible",
    },
    {
      key: "reportType",
      label: "Report Type",
    },
    {
      key: "date",
      label: "Date",
      isSortable: true,
    },
  ];
// console.log(currentItems);

  const users = reportedusers.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?._id,
      reporter: item?.reporterId[0]?.firstName,
      responsible: item?.responsibleId[0]?.firstName,
      reportType: item?.violationType,
      date: item?.createdAt?.split("T")[0],
    };
    return user;
  });

  return (
    <div className="  p-8 ">
      {loading ? (
        <Spinner />
      ) : (
        <div className="  max-w-3xl caret-transparent px-8 py-4    rounded-lg  bg-white shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">{reportedUserCount}</p>
          </div>
          <ReusableTable
            data={users}
            columns={columns}
            onRowClick={handleRowClick}
          />
          {reportedUserCount > itemsPerPage && (
            <div className="mt-8">
              <Pagination
                totalItems={reportedUserCount}
                itemsPerPage={Number(itemsPerPage)}
                setCurrentPage={setCurrentPage}
                currentPage={Number(currentPage)}
              />
            </div>
          )}
        </div>
      )}

      {showUser && (
        <ViewReportedUser
          setShowUser={setShowUser}
          reportId={reportId}
          setReports={setReportedUsers}
        />
      )}
    </div>
  );
}
