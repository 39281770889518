import { useState } from "react";
import { Edit, Edit3, Plus, X } from "react-feather";
import { subscriptionPlan } from "./Settings";

interface SubscriptionOptionProps {
  subscriptionOptions: subscriptionPlan[];
  setSubscriptionOptions: React.Dispatch<
    React.SetStateAction<subscriptionPlan[]>
  >;
  errors: any;
}

interface Option {
  _id: string;
  duration: string;
  price: number;
}

export default function SubscriptionOption({
  subscriptionOptions,
  setSubscriptionOptions,
  errors,
}: SubscriptionOptionProps) {
  const [options, setOptions] = useState<Option[] | any>(subscriptionOptions);

  const durations = ["Two days","One week", "Month", "Six months", "One year"];

  const [option, setOption] = useState<Option | any>(
    options[0] || {
      _id: null,
      duration: durations[0],
      price: null,
    }
  );

  const { duration, price } = option;

  const [shouldEditOptions, setShouldEditOptions] = useState(false);

  return (
    <div>
      {options.map((option: Option | any, key: number) => {
        return (
          <div
            key={option._id}
            className="flex my-2 justify-between gap-2  p-2 bg-[#EBEBEB] rounded-sm"
          >
            <div>
              {option.duration} : {option.price}
            </div>

            <div className="flex gap-8 min-w-[1.2rem] min-h-[1.2rem]">
              <Edit
                className=" cursor-pointer "
                onClick={() => {
                  setShouldEditOptions(true);
                  setOption({ ...option });
                }}
              />

              <X
                className=" cursor-pointer "
                onClick={() => {
                  const filteredOptions = options.filter(
                    (op: Option) => op._id !== option._id
                  );

                  setOptions([...filteredOptions]);
                  setSubscriptionOptions([...filteredOptions]);
                }}
              />
            </div>
          </div>
        );
      })}

      <div className="flex flex-col  space-y-2">
        <label htmlFor="duration" className="font-semibold">
          Duration
        </label>

        <select
          id="duration"
          value={duration}
          onChange={(e) => {
            setOption({ ...option, duration: e.target.value });
          }}
          className="bg-gray-50 border-2   rounded focus:ring-blue-500 focus:border-blue-500 block w-full  p-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {durations.map((value, key) => {
            return (
              <option value={value} key={key}>
                {value}
              </option>
            );
          })}
        </select>
      </div>

      <div className="flex flex-col  space-y-2 mt-4 ">
        <label htmlFor="price" className="font-semibold">
          Price
        </label>
        <input
          type="number"
          id="price"
          name="price"
          value={price}
          onChange={(e) => {
            const inputValue = Number(e.target.value);
            if (inputValue >= 0) {
              setOption({
                ...option,
                price: inputValue,
              });
            }
          }}
          className="outline-none min-w-[340px] focus:border-[#0F85F2] font-medium border-2 px-4 py-2 rounded"
        />
      </div>

      <div className="flex justify-between">
        <div
          onClick={() => {
            if (duration.trim().length === 0 && price <= 0) {
              return;
            }

            const { _id, ...rest } = option;

            setOptions([...(options || []), rest]);
            setSubscriptionOptions([...(options || []), rest]);
            setOption({
              duration: durations[0],
              price: '',
            });
          }}
          className=" self-end max-w-max mt-4   font-semibold  bg-[#2e2e2e]  text-white  rounded p-2 cursor-pointer "
        >
          <Plus />
        </div>

        {shouldEditOptions && (
          <div
            onClick={() => {
              if (duration.trim().length === 0 && price <= 0) {
                return;
              }

              const newOptions = [...options];
              const currOption = newOptions.find((o) => o._id === option._id);
              currOption.duration = duration;
              currOption.price = price;

              setOptions([...newOptions]);
              setSubscriptionOptions([...newOptions]);

              setOption({
                duration: durations[0],
                price: 0,
              });

              setShouldEditOptions(false);
            }}
            className=" self-end max-w-max mt-4   font-semibold  bg-[#2e2e2e]  text-white  rounded p-2 cursor-pointer "
          >
            <Edit3 />
          </div>
        )}
      </div>
      <p className="my-1 text-sm text-red-500">
        {errors["subscriptionOptions"]?.message}
      </p>
    </div>
  );
}
