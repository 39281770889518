import { useState } from "react";
import { Link } from "react-router-dom";
import { Eye, EyeOff } from "react-feather";
import {FORGET_PASSWORD_ROUTE} from "../constants"

interface Props {
    errors: any;
    register: any;
    type: string;
    label: string;
    name: string;
    defaultValue: string;
    forgotPassword?: boolean;
}
const InputPassword: React.FC<Props> = ({
    errors,
    register,
    type = "text",
    label = "",
    name = "",
    defaultValue = "",
    forgotPassword,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="flex flex-col  space-y-2">
            <div className="flex justify-between">
                <label htmlFor="name" className="font-semibold">
                    {label}
                </label>
            </div>
            <div
                className={`flex justify-between
								outline-none min-w-[340px] focus-within:border-[#0F85F2]  font-medium 
								border-2    px-4 py-2 rounded
                ${
                    errors[name]
                        ? "border-red-500 focus-within:border-red-500"
                        : null
                } 
			`}
            >
                <input
                    type={`${showPassword ? "text" : type}`}
                    id={name}
                    name={name}
                    defaultValue={defaultValue}
                    {...register(name)}
                    {...rest}
                    className={`outline-none  font-medium 	`}
                />
                {type === "password" && (
                    <div
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                        className=" cursor-pointer "
                    >
                        {showPassword ? (
                            <Eye className="text-dark scale-75" />
                        ) : (
                            <EyeOff className="text-dark scale-75" />
                        )}
                    </div>
                )}
            </div>
            <div className="flex justify-between">
            <p className="text-red-500">{errors[name]?.message}</p>
            {forgotPassword && (
                    <Link
                        to={FORGET_PASSWORD_ROUTE}
                        className="text-gray-400 text-sm "
                    >
                        Forgot Password ?
                    </Link>
                )}
            </div>
        </div>
    );
};
export default InputPassword;
