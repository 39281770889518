interface Props {
    errors: any;
    register: any;
    type: string;
    label: string;
    name: string;
    defaultValue: string;
}
const Input: React.FC<Props> = ({
    errors,
    register,
    type = "text",
    label = "",
    name = "",
    defaultValue = "",
    ...rest
}) => {
    return (
        <div className="flex flex-col  space-y-2">
            <label htmlFor={name} className="font-semibold">
                {label}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                defaultValue={defaultValue}
                {...register(name)}
                {...rest}
                className={`
								outline-none min-w-[340px] focus:border-[#0F85F2]  font-medium 
								border-2    px-4 py-2 rounded
                ${
                    errors[name]
                        ? "border-red-500 focus-within:border-red-500"
                        : null
                } 
								`}
            />
            <p className="text-red-500 text-sm">{errors[name]?.message}</p>
        </div>
    );
};
export default Input;
