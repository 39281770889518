import React, { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import { planProps } from "./Settings";
import { useForm } from "react-hook-form";
import ReusableTextArea from "./ReusableTextArea";
import {
  ERROR_MESSAGE_FOR_INVALID_SUBS_BENEFITS,
  ERROR_MESSAGE_FOR_INVALID_SUBS_DESCRIPTION,
  ERROR_MESSAGE_FOR_INVALID_SUBS_NAME,
  ERROR_MESSAGE_FOR_INVALID_SUBS_OPTIONS,
  PLAN_BENEFIT_IS_MANDATORY,
  PLAN_BENEFIT_MAX_CHAR_LENGTH,
  PLAN_DESCRIPTION_MAX_CHAR_LENGTH,
  PLAN_NAME_MAX_CHAR_LENGTH,
  PlansKeysMaxLengthsEnum,
  SubscriptionType,
} from "../../constants";
import SubscriptionOption from "./SubscriptionOption";
import AlertPopup from "../AlertPopup";

interface Props {
  formTitle: string;
  plan: planProps;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  apiCallingFucntion: (data: any) => void;
}

interface BenefitsProps {
  benefits: string[];
  setBenefits: React.Dispatch<React.SetStateAction<string[]>>;
  errors: any;
  setError: any;
  clearErrors: any;
}

const Benefits = ({
  benefits,
  setBenefits,
  errors,
  setError,
  clearErrors,
}: BenefitsProps) => {
  const [benefit, setBenefit] = useState("");
  const [currBenefits, setCurrBenefits] = useState(benefits);

  useEffect(() => {
    setCurrBenefits([...benefits]);
  }, [benefits]);

  return (
    <div className="flex flex-col  space-y-2">
      {currBenefits.map((b, index) => {
        return (
          <div
            key={index}
            className="flex justify-between gap-2 py-1 px-2 bg-[#EBEBEB] rounded-sm"
          >
            <p className="break-all">{b}</p>
            <X
              className=" cursor-pointer min-w-[1.2rem] min-h-[1.2rem]"
              onClick={() => {
                const filteredBenefits = currBenefits.filter(
                  (_, idx) => idx !== index
                );

                setCurrBenefits([...filteredBenefits]);
                setBenefits([...filteredBenefits]);
              }}
            />
          </div>
        );
      })}
      <label htmlFor="benefits" className="font-semibold">
        Benefits
      </label>
      <textarea
        onChange={(e) => {
          setBenefit(e.target.value);
        }}
        value={benefit}
        name="benefits"
        id="benefits"
        rows={4}
        className={`
                outline-none focus:border-[#0F85F2]  font-medium 
                border-2    px-4 py-2 rounded
${errors["benefits"] ? "border-red-500 focus-within:border-red-500" : null} 
                
                `}
      />
      <p className={`text-sm ${errors["benefits"] ? "text-red-500" : ""}`}>
        {errors["benefits"]?.message}
      </p>

      <div
        onClick={() => {
          if (benefit.trim().length === 0) {
            setError("benefits", {
              type: "custom",
              message: PLAN_BENEFIT_IS_MANDATORY,
            });
          } else if (benefit.trim().length > PlansKeysMaxLengthsEnum.BENEFIT) {
            setError("benefits", {
              type: "custom",
              message: PLAN_BENEFIT_MAX_CHAR_LENGTH,
            });
          } else {
            setCurrBenefits([...(currBenefits || []), benefit]);
            setBenefits([...(currBenefits || []), benefit]);
            setBenefit("");
            clearErrors("benefits");
          }
        }}
        className=" self-end max-w-max   font-semibold  bg-[#2e2e2e]  text-white  rounded p-2 cursor-pointer "
      >
        <Plus />
      </div>
    </div>
  );
};

export default function ReusableForm({
  formTitle,
  plan,
  setShowForm,
  apiCallingFucntion,
}: Props | any) {
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      planName: "",
      description: "",
      benefits: "",
      subscriptionOptions: "",
    },
  });

  const [currPlan, setCurrPlan] = useState<planProps>(
    JSON.parse(JSON.stringify(plan))
  );
  const [benefits, setBenefits] = useState(currPlan.benefits);
  const [openSubmitConfirmationPopup, setopenSubmitConfirmationPopup] = useState(false)
  const [subscriptionOptions, setSubscriptionOptions] = useState(
    currPlan.subscriptionOptions
  );

  useEffect(() => {
    if (currPlan.benefits.length > 0) {
      clearErrors("benefits");
    }
  }, [clearErrors, currPlan.benefits.length]);

  const noEmptyFieldFoundInPlan = (newPlan: planProps) => {
    const { planName, description, benefits, subscriptionOptions } = newPlan;
   
    if (
      planName.length !== 0 &&
      description.length !== 0 &&
      benefits.length !== 0 &&
      subscriptionOptions.length !== 0
    ) {
      
      return true;
    } else {
      
      return false;
    }
  };

  const setErrorsIfPLansFieldsAreNotValid = (newPlan: planProps) => {
    const { planName, description, benefits, subscriptionOptions } = newPlan;

    if (planName.trim().length === 0) {
      setError("planName", {
        type: "custom",
        message: ERROR_MESSAGE_FOR_INVALID_SUBS_NAME,
        
      });
    } else if (planName.trim().length > PlansKeysMaxLengthsEnum.NAME) {
      setError("planName", {
        type: "custom",
        message: PLAN_NAME_MAX_CHAR_LENGTH,
      });
    } else {
      clearErrors("planName");
    }

    if (description.trim().length === 0) {
      setError("description", {
        type: "custom",
        message: ERROR_MESSAGE_FOR_INVALID_SUBS_DESCRIPTION,
      });
    } else if (
      description.trim().length > PlansKeysMaxLengthsEnum.DESCRIPTIOIN
    ) {
      setError("description", {
        type: "custom",
        message: PLAN_DESCRIPTION_MAX_CHAR_LENGTH,
      });
    } else {
      clearErrors("description");
    }

    if (benefits.length === 0) {
      setError("benefits", {
        type: "custom",
        message: ERROR_MESSAGE_FOR_INVALID_SUBS_BENEFITS,
      });
    } else {
      clearErrors("benefits");
    }

    if (subscriptionOptions.length === 0) {
      setError("subscriptionOptions", {
        type: "custom",
        message: ERROR_MESSAGE_FOR_INVALID_SUBS_OPTIONS,
      });
    } else {
      clearErrors("subscriptionOptions");
    }
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const newPlan = { ...currPlan };
    newPlan.benefits = benefits;
    newPlan.subscriptionOptions = subscriptionOptions;

    setCurrPlan(newPlan);

    setErrorsIfPLansFieldsAreNotValid(newPlan);

    if (noEmptyFieldFoundInPlan(newPlan) && Object.keys(errors).length === 0) {
      apiCallingFucntion(newPlan);
      
      setopenSubmitConfirmationPopup(true); 
    }
  };

  return (
    <div className=" fixed inset-0 z-40 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 ">
      <div className="p-8  absolute space-y-8 top-0 right-0 w-[30%] bg-white rounded-tl-md rounded-bl-md z-50 h-full ">
        <div className="flex justify-between">
          <h1 className=" text-2xl ">{formTitle}</h1>
          <X onClick={() => setShowForm(false)} className=" cursor-pointer " />
        </div>
        {/* <form
          // onSubmit={(e) => handleSubmit(e)}
          className=" flex flex-col justify-between  h-full "
        > */}
          <div className=" space-y-8 scrollbar-hide h-[70vh] overflow-auto">
          <Input
              errors={errors}
              type="text"
              label="Plan Name"
              name="planName"
              value={currPlan.planName}
              setCurrPlan={setCurrPlan}
            />
            <ReusableTextArea
              name="description"
              label="Description"
              errors={errors}
              setCurrPlan={setCurrPlan}
              value={currPlan.description}
            />
            <Benefits
              benefits={benefits}
              setBenefits={setBenefits}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />

            <SubscriptionOption
              subscriptionOptions={subscriptionOptions}
              setSubscriptionOptions={setSubscriptionOptions}
              errors={errors}
            />
          </div>
          <button
            type="submit"
            className=" w-full my-12  font-semibold  bg-[#2e2e2e] hover:bg-[#2e2e2eed] text-white px-14 rounded py-4 "
            onClick={()=>{setopenSubmitConfirmationPopup(true)}}          
          >
            Submit
          </button>
        {/* </form> */}
        {openSubmitConfirmationPopup  && (
            <AlertPopup
              message={"Do you want to create subscription"}
              Close={() => setopenSubmitConfirmationPopup(false)}
              
              onSubmit={handleSubmit}
            ></AlertPopup>
        )}
      </div>
    </div>
  );
}

interface InputProps {
  errors: any;
  type: string;
  label: string;
  name: string;
  setCurrPlan: React.Dispatch<React.SetStateAction<planProps>>;
  value: string;
}
const Input: React.FC<InputProps> = ({
  errors,
  type = "text",
  label = "",
  name = "",
  setCurrPlan,
  value,
}) => {
  const [currValue, setCurrValue] = useState(value);
  return (
    <div className="flex flex-col  space-y-2">
      <label htmlFor={name} className="font-semibold">
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={currValue}
        onChange={(e) => {
          setCurrValue(e.target.value);
          setCurrPlan((prevPlan) => {
            prevPlan["planName"] = e.target.value;
            return prevPlan;
          });
        }}
        className={`
								outline-none min-w-[340px] focus:border-[#0F85F2]  font-medium 
								border-2    px-4 py-2 rounded
                ${
                  errors[name]
                    ? "border-red-500 focus-within:border-red-500"
                    : null
                } 
								`}
      />
      <p className="text-red-500 text-sm">{errors[name]?.message}</p>
    </div>
  );
};
