import React from "react";
import { Download, UserPlus, Users, UserCheck, UserX, XCircle, UserMinus } from "react-feather";

interface props {
  dashboardinfo: any;
  subscribedData:any;
}
interface countUsersBasedOnTheirStatus {
  activeUsers: number;
  deactivatedUsers: number;
  unVerifiedUsers: number;
  verifiedUsers: number;
}
const Dashboardinfo: React.FC<props> = ({ dashboardinfo,subscribedData }) => {
  return (
    <div>
      {dashboardinfo ? (
        <div className="bg-white w-fit flex flex-row gap-16 rounded py-2 px-10">
          {dashboardinfo.map(
            (item: countUsersBasedOnTheirStatus, index: number) => {
              return (
                <div key={index} className="flex flex-row gap-20">
                  {/* <div className="flex flex-row">
                    <div className="bg-teal-100 rounded w-10 h-10 mt-2 mb-2 flex justify-center items-center">
                      <Download className="w-4 h-4 " />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">64</p>
                      <p className="font-light text-xs text-gray-400">
                        Total Installs
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="bg-lime-100 rounded w-10 h-10 mt-2 mb-2 flex justify-center items-center">
                      <UserPlus className="content-center m-2 w-4 h-4" />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">{subscribedData?.subscriberUsers}</p>
                      <p className="font-light text-xs text-gray-400">
                        Subscribed User
                      </p>
                    </div>
                  </div> */}
                  <div className="flex flex-row">
                    <div className="bg-green-200 rounded w-10 h-10 mt-2 mb-2  flex justify-center items-center">
                      <Users className="content-center m-2 w-4 h-4" />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">{item.activeUsers}</p>
                      <p className="font-light text-xs text-gray-400">
                        Active Users
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="bg-blue-200 rounded w-10 h-10 mt-2 mb-2  flex justify-center items-center">
                      <UserCheck className="content-center m-2 w-4 h-4" />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">
                        {item.verifiedUsers}
                      </p>
                      <p className="font-light text-xs text-gray-400">
                        Verified Users
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="bg-lime-200 rounded w-10 h-10 mt-2 mb-2  flex justify-center items-center">
                      <UserMinus className="content-center m-2 w-4 h-4" />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">
                        {item.unVerifiedUsers}
                      </p>
                      <p className="font-light text-xs text-gray-400">
                        Unverified Users
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="bg-red-300 rounded w-10 h-10 mt-2 mb-2 flex justify-center items-center">
                      <UserX className="content-center m-2 w-4 h-4" />
                    </div>
                    <div className="ml-2 mt-2">
                      <p className="font-bold  text-base">{item?.deactivatedUsers}</p>
                      <p className="font-light text-xs text-gray-400">
                        deactivated User
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Dashboardinfo;
