import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  accFirstName,
  accLastName,
  AdminRole,
  EMAIL_IS_MANDATORY,
  ENTER_VALID_EMAIL,
  error,
  FIRST_NAME_IS_MANDATORY,
  FIRST_NAME_MAX_CHAR_LENGTH,
  LAST_NAME_IS_MANDATORY,
  LAST_NAME_MAX_CHAR_LENGTH,
  success,
} from "../constants";
import Input from "../components/Input";
import { useState, useContext } from "react";
import { addReviewer } from "../adapters/endpoints";
import { ToastContext } from "./Toast/ToastProvider";
import { createContent } from "../adapters/create";
import { DetailSpinner } from "./Spinner";

export default function AddNewReviewers() {
  const { handleCreateToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false)
  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(FIRST_NAME_IS_MANDATORY)
      .max(accFirstName, FIRST_NAME_MAX_CHAR_LENGTH),
    lastName: Yup.string()
      .required(LAST_NAME_IS_MANDATORY)
      .max(accLastName, LAST_NAME_MAX_CHAR_LENGTH),
    email: Yup.string().email(ENTER_VALID_EMAIL).required(EMAIL_IS_MANDATORY),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const [role, setRole] = useState<any>(AdminRole.NOT_AUTHORIZED_TO_REVIEW);
  const [roleType, setRoleType] = useState<any>(AdminRole.NOT_AUTHORIZED_TO_REVIEW);

  const SelectRoles = () => {
    return (
      <form className="flex flex-col my-2 py-2 max-w-max">
        <label htmlFor="role-select" className="font-semibold">
          Role
        </label>
        <select
          id="role-select"
          className="capitalize my-1 flex items-center gap-2 bg-gray-200 p-2 rounded"
          value={role}
          onChange={(event) => {
            const selectedRole = event.target.value;
            setRole(selectedRole);
            const roleType = AdminRole[selectedRole as keyof typeof AdminRole];
            setRoleType(roleType);
          }}
        >
         {Object.keys(AdminRole).map((option) => {
            const roleName = option?.split("_").join(" ").toLowerCase();
            return (
              <option
                key={option}
                value={option}
                className="capitalize bg-white "
              >
                {roleName}
              </option>
            );
          })}
        </select>
      </form>
    );
  };
  function onSuccess(data: any, response: any) {
    handleCreateToast(response.message, success);

    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setRole("");
    setLoading(false)
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
    setLoading(false)
  }

  return (
    <div className={` bg-white p-8 `}>
      <form>
        <div className="grid  grid-cols-2 gap-4 ">
          <Input
            label="First Name"
            type="text"
            name="firstName"
            register={register}
            errors={errors}
            defaultValue=""
          />

          <Input
            label="Last Name"
            type="text"
            name="lastName"
            register={register}
            errors={errors}
            defaultValue=""
          />

          <Input
            label="Email"
            type="email"
            name="email"
            register={register}
            errors={errors}
            defaultValue=""
          />
        </div>
      </form>

      <SelectRoles />
      <button
        className=" my-4  font-semibold  bg-[#2e2e2e] text-[#E7E7E9] px-14 rounded py-3 "
        onClick={handleSubmit(async (data) => {
          const nextReviewer = { ...data, roleType };

          createContent(addReviewer, nextReviewer, onSuccess, onFail);
          setLoading(true)
        })}
        disabled = {loading}
      >
       {loading ? <DetailSpinner /> : ' Add Reviewer'}  
      </button>
    </div>
  );
}
