import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { AdminSidebarData } from "../AdminSideBarData";
import Admins from "./Admin";
import Home from "./Home";
import Settings from "./Settings";
import Cookies from "universal-cookie";
import { SIGN_IN_ROUTE } from "../../constants";
import Users from "./Users";
import Chat from '../../pages/Chat';
import VerificationRequests from '../../pages/VerificationRequests';
import ReportUsers from '../../pages/ReportUsers';
import ReportChats from '../../pages/ReportChats';
import ReviewVerifiedUsers from "../../pages/VerifiedUsers";
import PendingMatchPartnerUser from "../../pages/PendingMatchPartnerUser";
import MatchPartners from "./MatchPartners";

export interface admin {
  createdAt: string;
  firstName: string;
  isAdminManager: boolean;
  lastName: string;
  email: string;
  _id: string;
}

export default function Dashboard() {
  const cookies = new Cookies();
  const isAdminAuth = cookies.get("isAuth");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminAuth) {
      navigate(SIGN_IN_ROUTE);
    }
  }, [isAdminAuth, navigate]);

  return (
    <div className="flex h-screen bg-[#F5F5F5]">
      <Sidebar routes={AdminSidebarData} />

      <main>
        <Routes>
          <Route path="dashboard" element={<Home />} />
          <Route path="admins" element={<Admins />} />
          <Route path="users" element={<Users />} />
          <Route path="MatchPartners" element={<MatchPartners />} />

          <Route path="settings" element={<Settings />} />
          <Route path="Chat" element={<Chat />} />
          <Route
            path="VerificationRequests"
            element={<VerificationRequests />}
          />
           <Route
            path="ReviewVerifiedUsers"
            element={<ReviewVerifiedUsers />}
          />
           <Route
            path="PendingMatchPartner"
            element={<PendingMatchPartnerUser />}
          />
          <Route path="ReportUsers" element={<ReportUsers />} />
          <Route path="ReportChats" element={<ReportChats />} />
        </Routes>
      </main>
    </div>
  );
}
