import React, { useState, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import AlertPopup from './AlertPopup'
import Button from './Button'
import Dropdown from './Dropdown'
import TextArea from './TextArea'
import {
  selectActionType,
  takeActionTextAreaText,
  reportActionType,
  noteToAdmin,
  giveWarningToUser,
  UserType,
  monthNames,
  TakeActionAlertText
} from '../constants'
import { ChangeEvent } from 'react'
import { ToastContext } from '../components/Toast/ToastProvider'
import { error, success } from '../constants'
import { createContent } from '../adapters/create'
import {
  findReportedChatEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  takeActionOnReportedChatEndPoint,
  findMessagesOfReportedChat
} from '../adapters/endpoints'
import { getContent } from '../adapters/get'
import { InfoCard } from './ViewReportedUser'
import { UserNameAvatar, UserNameAvatarForChat } from './userNameAvatar'
import ImageViewer from './ImageViewer'
import { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'

interface data {
  img: string
  uploadedImgs: Array<string>
  description: string
  responsibleId: {
    lastSeenAt: any
    rating: any
    phoneNumber: any
    accountStatus:string
    firstName: string
    lastName: string
    bio: string
    age: string
    gender: string
    height: {ft:string, m:string}
    location: string
    address: {
      region: any
      country: string
    }
    jobTitle: string
  }
  reporterId: {
    accountStatus:string
    firstName: string
    lastName: string
    bio: string
    age: string
    gender: string
    height: {ft:string, m:string}
    location: string
    address: {
      country: string
    }
    jobTitle: string
  }
  report?: {
    type: string
    complaint: string
    reportedByUserId: string
    userId: string
    dateOfReporting: string
  }
}
interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>
  reportId: number
  setReports: any
}

const ViewReportedChatsUser: React.FC<Props> = ({
  setShowUser,
  reportId,
  setReports
}) => {
  const [tab, setTab] = useState(1)
  const [chatReportData, setChatReportData] = useState<any>([])
  const { handleCreateToast } = useContext(ToastContext)

  function onSuccessGetReportedChat (data: any) {
    setChatReportData(data.reportedChat)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  useEffect(() => {
    if (reportId !== 0) {
      const GET_REPORTED_CHAT = `${findReportedChatEndPoint}${reportId}`
      getContent(GET_REPORTED_CHAT, onSuccessGetReportedChat, onFail)
    }
  }, [reportId])

  const UserDetail = () => {
    const [userProfilePic, setUserProfilePic] = useState('')
    const [reporterImg, setReporterImg] = useState<any>(null)
    const [OpenZoomPic, setOpenZoomPic] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [loading, setLoading] = useState(false)
    function onSucessGettingUsersImgs (data: any) {
      setUserProfilePic(data.userProfilePicture[0]?.image.data)
      setLoading(false)
    }

    function onSucessGettingUsersUploadedImgs (data: any) {
      setReporterImg(data)
      setLoading(false)
    }

    useEffect(() => {
      if (Array.isArray(chatReportData) && chatReportData?.length > 0) {
        const ReporterUserId = chatReportData[0]?.reporterId[0]?._id
        const REPORTER_USER_PROFILE_IMG = `${getReportedUserProfilePicture}${ReporterUserId}`
        const REPORTER_IMAGE = `${getReportedUserImage}${ReporterUserId}`

        getContent(REPORTER_USER_PROFILE_IMG, onSucessGettingUsersImgs, onFail)
        getContent(REPORTER_IMAGE, onSucessGettingUsersUploadedImgs, onFail)
      }
      setLoading(true)
    }, [chatReportData])

    return (
      <div
        className={`${
          tab === 1 ? 'block  ' : 'hidden'
        } scrollbar-hide h-[75vh] overflow-auto  `}
      >
        {loading ? (
         <DetailSpinner />
        ):(
          <>
          {chatReportData ? (
          <div>
            {chatReportData?.map((chatReport: any, index: number) => {
              return (
                <div className='flex flex-col gap-6 mb-4'>
                  <div className='flex flex-col gap-1 '>
                    {userProfilePic === undefined || userProfilePic === '' ? (
                      <UserNameAvatar
                        userName={chatReport?.reporterId[0]?.firstName}
                        userType={UserType.SENDER}
                      />
                    ) : (
                      <img
                        className=' object-cover		border 
                      rounded-full  h-16 w-16 '
                        src={`data:image/jpeg;base64,${userProfilePic}`}
                        alt={chatReport[0]?.reporterId?.firstName}
                      />
                    )}
                  </div>
                  <div className=' h-[60vh] overflow-auto'>
                  {chatReportData[0]?.reporterId?.map(
                    (reporter: data['responsibleId'], index: number) => {
                      return (
                        <div key={index}>
                          <div className='space-y-2'>
                            <p className='font-bold text-gray-800 text-2xl'>
                              {reporter.firstName} {reporter.lastName ?? ""}
                            </p>
                            {reporter.phoneNumber ? (
                              <p>{reporter.phoneNumber}
                              </p>
                            ) : null}
                            <p className=' text-gray-400'>{reporter?.bio}</p>
                          </div>
                          <div className='gap-2 divide-y-2 capitalize  my-4 '>
                          {reporter?.accountStatus&& (
                              <InfoCard
                                value={reporter?.accountStatus}
                                label='Account Status'
                              />
                            )}
                          {reporter.rating ? (
                              <InfoCard
                                value={reporter.rating}
                                label='Rate'
                              />
                            ) : null}
                                {reporter.gender ? (
                              <InfoCard
                                value={reporter.gender}
                                label='Gender'
                              />
                            ) : null}
                            {reporter.age ? (
                              <InfoCard value={reporter.age} label='Age' />
                            ) : null}
                            {reporter?.address?.country ? (
                              <InfoCard
                                value={reporter?.address?.country}
                                label='Country'
                              />
                            ) : null}
                             {reporter?.address?.region ? (
                              <InfoCard
                                value={reporter?.address?.region}
                                label='Region'
                              />
                            ) : null}
                            
                          </div>
                          <div>
                          {reporter.lastSeenAt ? (
                              <InfoCard
                                value={reporter.lastSeenAt.split('T')[0]} 
                                label = "Last Seen"
                              />
                            ) : null}
                          </div>
                        </div>
                      )
                    }
                  )}
                  <p className=' text-sm  text-[#999999]'> Uploaded Images </p>
                  <div className='grid grid-cols-2 gap-4 my-4'>
                    {reporterImg?.length > 0 ? (
                      <>
                        {reporterImg?.map((img: any, idx: number) => {
                          const handleClick = () => {
                            setOpenZoomPic(true)
                            setSelectedImage(img?.image?.data)
                          }
                          return (
                            <>
                              <img
                                key={idx}
                                className=' object-cover w-full h-64 rounded-md'
                                src={`data:image/jpeg;base64,${img?.image?.data}`}
                                onClick={handleClick}
                                alt='uploaded images'
                              />
                              {OpenZoomPic && (
                                <ImageViewer
                                  imageUrl={selectedImage}
                                  onClose={() => setOpenZoomPic(false)}
                                />
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <LoadingDots />
                    )}
                  </div>
                  </div>
                  {/* <div>
                    <p className=' text-md text-gray-400'>Complaint</p>
                    <p className=' text-gray-800 font-medium'>
                      {chatReport.description}
                    </p>
                  </div> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <SideBarSpinner />
          </div>
        )}
          </>
        )}
        
      </div>
    )
  }

  const ChatUI = ({
    report,
    messages,
    senderId,
    senderProfilePic,
    receiverProfilePic
  }: any) => {
    const ImgCircle = ({ userImg, userName }: any) => {
      return (
        <div className='h-10 w-10 overflow-hidden rounded-full'>
          <img
            className='object-cover h-full w-full'
            src={`data:image/jpeg;base64,${userImg}`}
            alt={userName}
          />
        </div>
      )
    }
    return (
      <div className='flex flex-col h-screen'>
        <div className='h-[60vh] overflow-auto '>
        <div className='flex-grow h-0'>
          {messages?.map((message: any) => {
            const dateString = message?.createdAt
            const date = new Date(dateString)
            const time = date.toLocaleTimeString()

            const repliedMessage = messages.find(
              (msg: any) => msg._id === message.repliedToMessageId
            )

            const isSender = message.senderId === senderId
            const messageClassName = `px-4 py-2 rounded-2xl bg-gray-200 ${
              isSender ? 'rounded-bl-none' : 'rounded-br-none'
            }`

            return (
              <div
                key={message._id}
                id={message._id}
                className={`flex gap-2 my-2 ${
                  isSender ? 'flex' : 'flex-row-reverse'
                }`}
              >
                <div className={`flex items-start `}>
                  {isSender ? (
                    <>
                      {senderProfilePic ? (
                        <ImgCircle
                          userImg={senderProfilePic}
                          userName={report?.reporterId[0]?.firstName}
                        />
                      ) : (
                        <UserNameAvatarForChat
                          userType={UserType.SENDER}
                          userName={report?.reporterId[0]?.firstName}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {receiverProfilePic ? (
                        <ImgCircle
                          userImg={receiverProfilePic}
                          userName={report?.responsibleId[0]?.firstName}
                        />
                      ) : (
                        <UserNameAvatarForChat
                          userType={UserType.RECIEVER}
                          userName={report?.responsibleId[0]?.firstName}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className='flex flex-col mt-6'>
                  {message.repliedToMessageId && repliedMessage ? (
                    <div
                      className='bg-gray-100 rounded-lg p-2 mb-2 cursor-pointer'
                      style={{ borderLeft: '4px solid #e0e0e0' }}
                      onClick={() => {
                        const originalMessageElement = document.getElementById(
                          repliedMessage?._id
                        )
                        if (originalMessageElement) {
                          originalMessageElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                          })
                        }
                      }}
                    >
                      <p className='text-sm font-semibold text-gray-600 '>
                        {repliedMessage.messageBody.slice(0, 50)}...
                      </p>
                    </div>
                  ) : null}
                  <div className={messageClassName}>
                    <p className='font-semibold'>{message.messageBody}</p>
                  </div>
                  <div className='flex justify-start'>
                    <p
                      className={`text-xs text-gray-400 mt-2 font-small ${
                        isSender ? 'mr-auto' : 'ml-auto'
                      }`}
                    >
                      {time}
                    </p>
                    <div className='flex justify-start'>
                      {message.reactionFromSender && (
                        <div
                          className={`h-8 w-8 bg-white border-2 border-gray-300 ${
                            isSender ? 'ml-0' : 'mr-0'
                          } rounded-full flex items-center justify-center`}
                        >
                          <span role='img' aria-label='emoji'>
                            {message.reactionFromSender}
                          </span>
                        </div>
                      )}
                      {message.reactionFromReceiver && (
                        <div
                          className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${
                            isSender ? 'mr-0' : 'ml-0'
                          } rounded-full flex items-center justify-center`}
                        >
                          <span role='img' aria-label='emoji'>
                            {message.reactionFromReceiver}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        </div>
      </div>
    )
  }

  const ReportedChat = ({ chatReportData }: any) => {
    const [message, setMesssage] = useState([])
    const [senderId, setSenderId] = useState()
    const [chats, setChats] = useState<any>()
    const [receiverId, setReceiverId] = useState()

    let chatId: any

    const monthIndex = new Date(chats?.updatedAt)?.getMonth()
    const monthName = monthNames[monthIndex]
    const day = new Date(chats?.updatedAt).getDate()

    const onSucess = (data: any) => {
      setChats(data.messagesOfReportedChat[0])
      setSenderId(data.messagesOfReportedChat[0]?.senderId)
      setReceiverId(data.messagesOfReportedChat[0]?.receiverId)
      setMesssage(data.messagesOfReportedChat[0]?.messageIds)
    }

    const onFail = (message: any) => {
      handleCreateToast(message, error)
    }

    useEffect(() => {
      if (chatReportData) {
        chatId = chatReportData[0]?.responsibleChatId

        if (chatId?.length > 0) {
          const FIND_MESSAGES_OF_REPORTED_CHAT = `${findMessagesOfReportedChat}${chatId}`
          getContent(FIND_MESSAGES_OF_REPORTED_CHAT, onSucess, onFail)
        }
      }
    }, [chatId, chatReportData])

    const [senderProfilePic, setSenderProfilePic] = useState('')
    const [receiverProfilePic, setReceiverProfilePic] = useState('')

    function onSucessGettingSenderImg (data: any) {
      setSenderProfilePic(data?.userProfilePicture[0]?.image?.data)
    }

    function onSucessGettingReceiverImg (data: any) {
      setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data)
    }

    useEffect(() => {
      if (chats) {
        const findSenderImg = `${getReportedUserProfilePicture}${senderId}`
        const findReceiverImg = `${getReportedUserProfilePicture}${receiverId}`

        getContent(findSenderImg, onSucessGettingSenderImg, onFail)
        getContent(findReceiverImg, onSucessGettingReceiverImg, onFail)
      }
    }, [chats, receiverId, senderId])

    return (
      <div
        className={`${
          tab === 4 ? 'block  ' : 'hidden'
        } scrollbar-hide h-[75vh] overflow-auto space-y-6 relative  `}
      >
        <div className='flex  '>
          <div className=' absolute rounded-full bg-gray-200 text-gray-400 px-4 max-w-max right-2/4  '>
            {monthName && monthName} {day && day}
          </div>
        </div>
        <ChatUI
          messages={message}
          senderId={senderId}
          senderProfilePic={senderProfilePic}
          receiverProfilePic={receiverProfilePic}
        />
      </div>
    )
  }

  const ReportedUserDetail = ({ chatReportData }: any) => {
    const [userProfilePic, setUserProfilePic] = useState('')
    const [responsibleImg, setResponsibleImg] = useState<any>(null)
    const [OpenZoomPic, setOpenZoomPic] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    function onSucessGettingUsersImgs (data: any) {
      setUserProfilePic(data.userProfilePicture[0]?.image.data)
      setLoading(false)
    }

    function onSucessGettingUsersUploadedImgs (data: any) {
      setResponsibleImg(data)
      setLoading(false)
    }

    useEffect(() => {
      if (Array.isArray(chatReportData) && chatReportData?.length > 0) {
        const ResponsibleUserId = chatReportData[0]?.responsibleId[0]?._id

        const RESPONSIBLE_USER_PROFILE_IMG = `${getReportedUserProfilePicture}${ResponsibleUserId}`
        getContent(
          RESPONSIBLE_USER_PROFILE_IMG,
          onSucessGettingUsersImgs,
          onFail
        )

        const RESPONSIBE_IMAGE = `${getReportedUserImage}${ResponsibleUserId}`
        getContent(RESPONSIBE_IMAGE, onSucessGettingUsersUploadedImgs, onFail)
      }
      setLoading(true)
    }, [chatReportData])
    return (
      <div
        className={`${
          tab === 2 ? 'block  ' : 'hidden'
        } scrollbar-hide h-[75vh] overflow-auto  `}
      >
        {loading ? (
          <DetailSpinner />
        ):(
       <>
        {chatReportData ? (
          <div>
            {chatReportData?.map((chatReport: any, index: number) => {
              return (
                <div className='flex flex-col gap-6 mb-4'>
                  <div className='flex flex-col gap-1 '>
                    {userProfilePic === '' || null || undefined ? (
                      <UserNameAvatar
                        userName={chatReport?.responsibleId[0]?.firstName}
                        userType={UserType.SENDER}
                      />
                    ) : (
                      <img
                        className=' object-cover		border 
                      rounded-full  h-16 w-16 '
                        src={`data:image/jpeg;base64,${userProfilePic}`}
                        alt={chatReport?.responsibleId[0]?.firstName}
                      />
                    )}
                  </div>
                  <div className=' h-[60vh] overflow-auto'>
                  {chatReportData[0]?.responsibleId?.map(
                    (responsible: data['responsibleId'], index: number) => {
                      return (
                        <div key={index}>
                          <div className='space-y-2'>
                            <p className='font-bold text-gray-800 text-2xl'>
                              {responsible.firstName} {responsible.lastName ?? ""}
                            </p>
                            {responsible.phoneNumber ? (
                              <p>{responsible.phoneNumber}</p>
                            ) : null}
                            <p className=' text-gray-400'>{responsible?.bio}</p>
                          </div>
                          <div className='gap-2 divide-y-2 capitalize  my-4 '>
                            {/* {responsible?.height?.m ? (
                              <InfoCard
                                value={responsible?.height?.m}
                                label='Height'
                              />
                            ) : null} */}
                            
                            {responsible?.accountStatus&& (
                              <InfoCard
                                value={responsible?.accountStatus}
                                label='Account Status'
                              />
                            )}
                          {responsible.rating ? (
                              <InfoCard
                                value={responsible.rating}
                                label='Rate'
                              />
                            ) : null}
                                {responsible.gender ? (
                              <InfoCard
                                value={responsible.gender}
                                label='Gender'
                              />
                            ) : null}
                            {responsible.age ? (
                              <InfoCard value={responsible.age} label='Age' />
                            ) : null}
                            {responsible?.address?.country ? (
                              <InfoCard
                                value={responsible?.address?.country}
                                label='Country'
                              />
                            ) : null}
                             {responsible?.address?.region ? (
                              <InfoCard
                                value={responsible?.address?.region}
                                label='Region'
                              />
                            ) : null}
                             {responsible.lastSeenAt ? (
                              <InfoCard
                                value={responsible.lastSeenAt.split('T')[0]} 
                                label='Last Seen'
                              />
                            ) : null}
                          </div>
                        </div>
                      )
                    }
                  )}
                  <p className=' text-sm  text-[#999999]'> Uploaded Images </p>
                  <div className='grid grid-cols-2 gap-4 my-4'>
                    {responsibleImg?.length > 0 ? (
                      <>
                        {responsibleImg?.map((img: any, idx: number) => {
                           const handleClick = () => {
                            setOpenZoomPic(true)
                            setSelectedImage(img?.image?.data)
                          }
                          return (
                            <>
                              <img
                                key={idx}
                                className=' object-cover w-full h-64 rounded-md'
                                src={`data:image/jpeg;base64,${img?.image?.data}`}
                                onClick={handleClick}
                                alt=' Img took for Verification'
                              />
                              {OpenZoomPic && (
                                <ImageViewer
                                  imageUrl={selectedImage}
                                  onClose={() => setOpenZoomPic(false)}
                                />
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <LoadingDots />

                    )}
                  </div>
                  </div>
                  {/* <div>
                    <p className=' text-md text-gray-400'>Complaint</p>
                    <p className=' text-gray-800 font-medium'>
                      {chatReport.description}
                    </p>
                  </div> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <SideBarSpinner />
          </div>
        )}
       </>
        )}
       
      </div>
    )
  }

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false)
    const [selectedActionType, setSelectedActionType] = useState('')
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { handleCreateToast } = useContext(ToastContext)
    const [accountType,setAccountType] = useState<any>({})

    useEffect(() => {
  
      const existingAccount =localStorage.getItem('adminManager');
      if(existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, [])
    useEffect(() => {
      if(accountType?.isAdminManager === true){
        setNoteToAdminManager('adminManager')        
      }
    }, [accountType?.isAdminManager])
    const onSuccess = (data: any, response: any) => {
      setReports((prevReports: any) => {
        const nextChats = [...prevReports]?.filter(
          (chat: any) => chat._id !== reportId
        )
        return nextChats
      })
      setShowUser(false)

      handleCreateToast(response.message, success)
      setIsLoading(false)
    }

    const onFail = (message: string) => {
      handleCreateToast(message, error)
      setIsLoading(false)
    }

    const submitReview = () => {
      setIsLoading(true)
      const data = {
        // reviewerReasonForTakingAction: reviewDescription,
        actionType: selectedActionType,
        report_id: reportId,
        noteToAdminManager: noteToAdminManager
      }

      createContent(takeActionOnReportedChatEndPoint, data, onSuccess, onFail)
    }

    return (
      <div
        className={`h-full ${
          tab === 3 ? 'block' : 'hidden'
        } scrollbar-hide h-[75vh] overflow-auto  `}
      >
        <div className='flex flex-col justify-between h-full max-h-[85%] scrollbar-hide overflow-auto '>
          <div>
            <h2 className=' text-lg font-semibold  mb-2 left-2'>
              {' '}
              Action Type
            </h2>
            <Dropdown
              name={selectActionType}
              list={reportActionType}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText)
              }}
            />
            {/* <TextArea
              text={takeActionTextAreaText}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setReviewDescription(e.target.value)
              }}
            /> */}
           {accountType?.isAdminManager === false && (
            <TextArea
              text={noteToAdmin}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setNoteToAdminManager(e.target.value)
              }}
            />
          )}
          </div>
          <Button
            disabled={
              !selectedActionType || !noteToAdminManager
            }
            text={giveWarningToUser}
            onClick={() => setShowPopup(true)}
          />
        </div>

        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false)
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    )
  }

  return (
    <div className=' fixed inset-0 z-40 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 '>
      <div className='p-8 absolute  top-0 right-0 w-[30%] bg-white rounded-md z-50 h-full '>
        <div className='flex justify-end  '>
          <X onClick={() => setShowUser(false)} className=' cursor-pointer ' />
        </div>
        <div className='  h-full space-y-8 '>
          <div className='flex  font-bold   justify-between'>
            <p
              onClick={() => setTab(1)}
              className={`p-2 pb-4   cursor-pointer ${
                tab === 1 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Reporter
            </p>
            <p
              onClick={() => setTab(2)}
              className={`p-2 pb-4  cursor-pointer ${
                tab === 2 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Responsible
            </p>
            <p
              onClick={() => setTab(4)}
              className={`p-2 pb-4   cursor-pointer ${
                tab === 4 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Chat
            </p>
            <p
              onClick={() => setTab(3)}
              className={`p-2 pb-4   cursor-pointer ${
                tab === 3 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Take Action
            </p>
          </div>
          <UserDetail />
          <ReportedUserDetail chatReportData={chatReportData} />
          <ReportedChat chatReportData={chatReportData} />
          <TakeAction />
        </div>
      </div>
    </div>
  )
}

export default ViewReportedChatsUser
