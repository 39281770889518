interface NoContentFoundProps {
    text: string;
}

export const NoContentFound = ({ text }: NoContentFoundProps) => {
    return (
        <p className="p-4 max-w-sm border-l-4 bg-red-100 border-red-400">
            {text}
        </p>
    );
};
