import React, { useState } from "react";
import { X } from "react-feather";

interface ImagePopupProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageZoomPopup: React.FC<ImagePopupProps> = ({ imageUrl, onClose }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const handleClose = (): void => {
    setModalOpen(false);
    onClose();
  };

  const style = {
    position: "absolute" as const,
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -25%)",
    width: "40%",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    paddingTop: "4px",
    paddingBottom: "4px",
    borderRadius: "2px",
    paddingLeft: "16px",
    paddingRight: "4px",
    overflow: "auto",
    zIndex: 9999,
  };
  const imageStyle = {
    maxWidth: "90%",
    maxHeight: "80%",
  };
  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50"
      style={{ display: modalOpen ? "flex" : "none" }}
      onClick={handleClose}
    >
      <div className="flex flex-col items-center bg-white p-4 rounded-lg" style={style} onClick={(e) => e.stopPropagation()}>
        <button
          className="absolute top-0.5 right-0.5 text-orange-500"
          style={{ color: "orange" }}
          onClick={handleClose}
        >
        <X />
        </button>
        <div className="flex justify-center items-center pt-2">
          <div className="flex flex-row gap-2">
            <img
              src={`data:image/jpeg;base64,${imageUrl}`}
              alt=""
              // className="w-400px h-150px object-contain"
              style={imageStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageZoomPopup;
