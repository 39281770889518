import { useState, useEffect, useContext, useRef } from 'react'
import {
  searchMatchPartnerEndpoint,
} from '../../adapters/endpoints'
import {  getContentWithPagination } from '../../adapters/get'
import { AdminRole, error } from '../../constants'
import { ToastContext } from '../Toast/ToastProvider'
import Pagination from '../Pagination/Pagination'
import Spinner from '../Spinner'
import { ReusableTable } from '../ReusableTable'
import ViewPendingMatchPartner from '../ViewPendingMatchPartner'


interface User {
  _id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string 
}

export default function MatchPartners () {
  const { handleCreateToast } = useContext(ToastContext)

  const [users, setUsers] = useState<User[]>([])
  const [userId, setUserId] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<any>("")
  const [showList, setShowList] = useState(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const listRef = useRef<HTMLUListElement | null>(null)
  const [countUsers, setCountUsers] = useState<any>([])
  const [Reports, setReports] = useState('')
  const [currentPage, setCurrentPage]=useState(0)


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false)
      }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false)
      }
    }

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [showList])

  // ...
  function onSuccess (users: any) {
    setUsers(users?.user)
    setMatchPartners(users?.user)
    setUserId(users?._id)
    setCountUsers(users.countUsers)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }


  useEffect(() => {
    const search = `${searchMatchPartnerEndpoint}${searchTerm}`
    getContentWithPagination(search, onSuccess, onFail,currentPage)
  }, [searchTerm,currentPage])


  const [matchPartners, setMatchPartners] = useState<any>(users)


  const partners = matchPartners.map((partner: any, idx: number) => {
    const newPartner = {
        _id: partner?._id,
        firstName: partner?.firstName,
        lastName: partner?.lastName,
        email: partner?.userId[0]?.email,
        phoneNumber: partner?.userId[0]?.phoneNumber
    }
    return newPartner
  })
console.log(partners);

  function handleRowClick(_id: string) {
    const user = matchPartners.find((item: any) => {
      return item?._id === _id;
    });

    const userIds: any = {
      _id: _id,
      userId: user.userId,
      imageIds: user.imageIds
    };
    setUserId(userIds);
    setShowUser(true);
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])  

  return (
    <div className='m-8 '>
      <div className='flex flex-col relative space-y-2  max-w-sm '>
        <label htmlFor='duration' className='font-semibold'>
          Users
        </label>
        <input
          type='text'
          placeholder='Search users'
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={e => {
            const searchTerm1 = e.target.value
            const filteredAdmins = Array.isArray(users)
              ? users.filter(user =>
                  user.firstName
                    .toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
              : []
              setMatchPartners(filteredAdmins)
            setSearchTerm(searchTerm1)
            setCurrentPage(0)
          }}
          className='block w-full rounded border-2 bg-gray-50 p-2 focus:border-blue-500  focus:ring-blue-500  dark:placeholder-gray-400  dark:focus:border-blue-500 dark:focus:ring-blue-500'
        />

      </div>

      {showUser && (
        <ViewPendingMatchPartner
          setShowUser={setShowUser}
          partnerIds={userId}
          setPendingMatchPartners={setReports}
        />
      )}
 
      {loading ? (
        <Spinner />
      ) : (
        <div className=' my-8  caret-transparent px-8 py-4  rounded-lg  bg-white shadow-sm'>
          <ReusableTable
            data={partners}
            columns={[
              {
                key: 'firstName',
                label: 'First Name'
              },
              {
                key: 'lastName',
                label: 'Last Name'
              },
              {
                key: 'email',
                label: 'Email'
              },
              {
                key: 'phoneNumber',
                label: 'Phone Number'
              }
           
            ]}
            onRowClick={handleRowClick}
          />

          {countUsers > 8 && (
            <div className='mt-8'>
               <Pagination
                totalItems={countUsers}
                itemsPerPage={8}
                setCurrentPage={setCurrentPage}
                currentPage={Number(currentPage)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
