import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { countUsersBasedOnTheirGender } from "../adapters/endpoints";
import { getContent } from "../adapters/get";
import { ToastContext } from "./Toast/ToastProvider";
import { error, success } from "../constants";

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,    
    },
  },
};

const UserGenderChart: React.FC<{}> = () => {
  const [genderData, setGenderData] = useState<any>([]);
  const { handleCreateToast } = useContext(ToastContext);

  function onSuccess(data: any) {
    setGenderData(data);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
      getContent(countUsersBasedOnTheirGender, onSuccess, onFail);
  },[]);

  const genderCount = genderData?.usersBasedOnTheirGender?.map((e: { count: any; }) => e.count);
  const labels= genderData?.usersBasedOnTheirGender?.map((e: { _id: any; }) => e._id);
  const genderLabel=labels?.map((e: { gender: any; }) => e.gender)
  
  const total = genderCount?.reduce((acc: any, val: any) => acc + val, 0);
  const percentageData = genderCount?.map((val: number) => ((val / total) * 100).toFixed(2));
  const data = {
    labels: genderLabel,
    datasets: [
      {
        label: "",
        data: genderCount,
        backgroundColor: [
          '#2AC76A',
          '#FCD064',
          '#535353',
        ],
        borderWidth: 0,
        hoverOffset: 4
      }
    ]
  };

  return (
    <div className="bg-white rounded w-1/3 h-fit p-8">
      <p className="font-semibold text-xl"> Users Gender</p>
      {percentageData?(
      <p className="font-tiny text-gray-400 text-sm mb-6">
      {percentageData[1]} percent users are male
    </p>
      ):null}
      <div className='flex flex-row gap-4 w-fit mt-12' > 
      <div className="flex w-48 ">
     <Doughnut data={data} options={options}/>
     </div>
     {genderCount?(
        <div className='grid grid-row-3 gap-4 mt-8 ml-8 h-full'>
        <div className='flex flex-row'>
        <div className='bg-green-400 rounded w-6 h-6 mt-2'></div>
        <div className='ml-2'>
          <p className='font-bold  text-base text-blue-900'>{genderCount[0]}</p>
          <p className='font-light text-sm text-gray-400'>{genderLabel[0]}</p>
        </div>
        </div>
        <div className='flex flex-row'>
        <div className='bg-amber-300  rounded w-6 h-6 mt-2'></div>
        <div className='ml-2'>
          <p className='font-bold  text-base text-blue-900'>{genderCount[1]}</p>
          <p className='font-light text-sm text-gray-400'>{genderLabel[1]}</p>
        </div>
        </div>
        {genderCount.length===3?(<div className='flex flex-row'>
        <div className='bg-neutral-600 rounded w-6 h-6 mt-2'></div>
        <div className='ml-2'>
          <p className='font-bold  text-base text-blue-900'>{genderCount[2]}</p>
          <p className='font-light text-sm text-gray-400'>{genderLabel[2]}</p>
        </div>
        </div>):null}
        
      </div>
       ):null}
      </div>
    </div>
  );
};

export default UserGenderChart;
