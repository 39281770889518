import React, { useContext ,useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { createContent } from "../adapters/create";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  adminManagersDashboard,
  EMAIL_IS_MANDATORY,
  ENTER_VALID_EMAIL,
  error,
  passwordLength,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  reviewersDashboard,
  success,
} from "../constants";
import Input from "../components/Input";
import InputPassword from "../components/InputForPassword";
import { signInEndPoint } from "../adapters/endpoints";
import { ToastContext } from "../components/Toast/ToastProvider";
import { AdminManagerContext } from "../components/AdminManager/AdminProvider";

export default function SignIn() {
  const { handleCreateToast } = useContext(ToastContext);
  const { setAdmin } = useContext(AdminManagerContext);
  const [loadingSignIn, setloadingSignIn] = useState(false)
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    email: Yup.string().email(ENTER_VALID_EMAIL).required(EMAIL_IS_MANDATORY),
    password: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) => {
    setloadingSignIn(false)
    handleCreateToast(response.message, success);

    setAdmin(response.existingAdmin);
    localStorage.setItem(
      "adminManager",
      JSON.stringify(response.existingAdmin)
    );

    if (response?.existingAdmin?.isAdminManager) {
      navigate(adminManagersDashboard);
    } else {
      navigate(reviewersDashboard);
    }
  };

  const onFail = (message: string) => {
    setloadingSignIn(false)
    handleCreateToast(message, error);
  }

  return (
    <div className=" flex flex-col  h-screen  place-content-center  max-w-sm m-auto space-y-8 w-full  ">
      <h1 className="text-3xl font-medium my-4  ">Sign in </h1>
      <form
        onSubmit={handleSubmit(async (data) => {
          setloadingSignIn(true)
          createContent(signInEndPoint, data, onSuccess, onFail);
        })}
      >
        <div className="space-y-2">
          <Input
            label="Email"
            type="text"
            name="email"
            defaultValue=""
            register={register}
            errors={errors}
          />
          <InputPassword
            label="Password"
            type="password"
            name="password"
            defaultValue=""
            register={register}
            errors={errors}
            forgotPassword={true}
          />
        </div>
        <button className="block text-center  w-full my-8  font-semibold  bg-blue-500 text-white px-14 rounded-md py-2 ">
          {" "}
          
          {
       loadingSignIn ?<div
         className="loader mx-auto h-4 w-4 animate-spin rounded-full border-2"
         role="status"
     ></div>:"Sign in"
                }
        </button>

        {/* <div className="flex items-center gap-1  justify-center ">
          <p className=" text-zinc-400"> Dont have account? </p>
          <Link to="/SignUp" className="  text-blue-500 underline">
            Sign up{" "}
          </Link>
        </div> */}
      </form>
    </div>
  );
}
