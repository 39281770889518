import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error, SIGN_IN_ROUTE, success } from "../constants";
import { getContent} from "../adapters/get"

function ReusableEmailVerification() {
  const currentUrl = window.location.pathname;
  const { handleCreateToast } = useContext(ToastContext);
  const [serverResponse, setServerResponse] = useState("");

  const navigate = useNavigate();

  const onSuccess = (response: any) => {
    handleCreateToast(response.message, success);

    setServerResponse(response.message);

    navigate(SIGN_IN_ROUTE);
  };

  const onFail = (message: string) => {
    handleCreateToast(message, error);
    setServerResponse(message);
  };

  useEffect(() => {
    getContent(currentUrl, onSuccess, onFail);
  }, []);

  return (
    <div className=" my-8 h-screen space-y-4  py-12 text-center ">
      <h3 className=" text-2xl">{serverResponse}</h3>
    </div>
  );
}

export default ReusableEmailVerification;
