import AgeofUsersChart from "../AgeofUsersChart";
import Dashboardinfo from "../Dashboardinfo";
import TotalSubscribers from "../TotalSubscribers";
import UserGenderChart from "../UserGenderChart";
import UserLocationChart from "../UserLocationChart";
import {
  countUsersBasedOnTheirStatus,
  getSubscribedUsersEndpoint,
} from "../../adapters/endpoints";
import { getContent } from "../../adapters/get";
import { ToastContext } from "../Toast/ToastProvider";
import { error, success } from "../../constants";
import { useState, useContext, useEffect } from "react";

interface props {
  dashboardinfo: {
    countUsersBasedOnTheirStatus: {
      activeUsers: number;
      deactivateUsers: number;
      unVerifiedUsers: number;
      verifiedUsers: number;
    };
    divideUsersByAge?: {
      count: number;
      _id: {
        age: number;
      };
    };
  };
}
export default function Home() {
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [subscribedData, setSubscribedData] = useState<any>([]);
  const { handleCreateToast } = useContext(ToastContext);
  function onSuccess(data: any) {
    setDashboardData(data.countUsersBasedOnTheirStatus);
  }

  function onSuccessSubscribed(data: any) {
    setSubscribedData(data);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContent(countUsersBasedOnTheirStatus, onSuccess, onFail);
    getContent(getSubscribedUsersEndpoint, onSuccessSubscribed, onFail);
  }, []);

    return (
      <div className="flex flex-wrap gap-16 px-16 py-8 scrollbar-hide max-h-[95%] w-[100%] overflow-auto">
      <Dashboardinfo dashboardinfo={dashboardData} subscribedData={subscribedData} />
      <div className="flex flex-col-2 sm:flex-row flex-wrap gap-10">
        
          <AgeofUsersChart />
          <UserGenderChart />
          <UserLocationChart />
      </div>
      </div>
        );
}
