import { useState, useEffect, useContext, useRef } from 'react'
import {
  GetUserInfoEndpoint,
  SearchUserEndpoint,
  findAllAdminsEndPoint
} from '../../adapters/endpoints'
import { getContent, getContentWithPagination } from '../../adapters/get'
import { AdminRole, error, getEnumKeyFromValue } from '../../constants'
import { ToastContext } from '../Toast/ToastProvider'
import Reviewer from './Reviewer'
import Pagination from '../Pagination/Pagination'
import usePagination from '../Pagination/usePagination'
import Spinner from '../Spinner'
import { ReusableTable } from '../ReusableTable'
import { getCountUsersRequestsBasedOnReviewerId } from '../../adapters/endpoints'
import ViewReportedUser from '../ViewReportedUser'
import ViewUser from './UserDetailPage'
import UserDetailPage from './UserDetailPage'
import ReusablePagination from '../Pagination/ReusablePagination'
// import ViewUser from '../ViewUser'

interface admin {
  createdAt: string
  firstName: string
  isAdminManager: boolean
  lastName: string
  role: string
  __v: 0
  _id: string
  total: number
  totalReviewed: number
  totalPending: number
}
interface User {
  _id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: {
    country: string
    region: string
    city: string
  }
}

export default function Users () {
  const { handleCreateToast } = useContext(ToastContext)

  const [admins, setAdmins] = useState<admin[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [userId, setUserId] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<any>("")
  const [showList, setShowList] = useState(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const listRef = useRef<HTMLUListElement | null>(null)
  const [countUsers, setCountUsers] = useState<any>([])
  const [Reports, setReports] = useState('')
  const [currentPage, setCurrentPage]=useState(0)

  const [report, setReport] = useState({
    _id: '',
    type: ''
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false)
      }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false)
      }
    }

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [showList])

  // ...
  function onSuccess (users: any) {
    setUsers(users?.user)
    setReviewerAdmins(users?.user)
    setUserId(users?.user[0]?._id)
    setCountUsers(users.countUsers)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  // function onCountUserRequestsSuccess (data: any) {
  //   setCountUsers(data.countUsers)
  // }
  // useEffect(() => {
  //   getContent(
  //     getCountUsersRequestsBasedOnReviewerId,
  //     onCountUserRequestsSuccess,
  //     onFail
  //   )
  // }, [])

  useEffect(() => {
    const search = `${SearchUserEndpoint}${searchTerm}`
    getContentWithPagination(search, onSuccess, onFail,currentPage)
  }, [searchTerm,currentPage])


  const [reviewerAdmins, setReviewerAdmins] = useState(users)

  // const [currentPage, setCurrentPage, itemsPerPage, currentItems] =usePagination(users)
  const headings: string[] = ['#', 'User', 'Total Actions', 'Role']

  const reviewers = reviewerAdmins.map((reviewer: any, idx: number) => {
    const newReviewer = {
      _id: reviewer?._id,
      firstName: reviewer?.firstName,
      lastName: reviewer?.lastName,
      email: reviewer?.email,
      phoneNumber: reviewer?.phoneNumber
      // pendingActions: reviewer?.totalPending
    }
    return newReviewer
  })

  const handleClick = (_id: any) => {
    setShowUser(true)
    setUserId(_id)
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (showUser === true) {
      getContent(`${GetUserInfoEndpoint}${userId}`, setFirst, onFail)
    }
  }, [showUser])

  return (
    <div className='m-8 '>
      <div className='flex flex-col relative space-y-2  max-w-sm '>
        <label htmlFor='duration' className='font-semibold'>
          Users
        </label>
        <input
          type='text'
          placeholder='Search users'
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={e => {
            const searchTerm1 = e.target.value
            const filteredAdmins = Array.isArray(users)
              ? users.filter(user =>
                  user.firstName
                    .toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
              : []
            setReviewerAdmins(filteredAdmins)
            setSearchTerm(searchTerm1)
            setCurrentPage(0)
          }}
          className='block w-full rounded border-2 bg-gray-50 p-2 focus:border-blue-500  focus:ring-blue-500  dark:placeholder-gray-400  dark:focus:border-blue-500 dark:focus:ring-blue-500'
        />

        {/* {showList && (
          <ul
            ref={listRef}
            className='shadow-md absolute top-20 w-full   bg-white max-h-[19.3rem] scrollbar-hide overflow-auto   rounded '
          >
            {Array.isArray(users) &&
              users?.map((user: any) => (
                <li
                  onClick={() => {
                    setUserId(user._id)
                    setShowList(false)
                    setShowUser(true)
                  }}
                  className='p-1 px-4 hover:bg-gray-100 '
                  key={user._id}
                >
                  {`${user.firstName}  ${user.lastName}  ${user.phoneNumber}`}
                </li>
              ))}
          </ul>
        )} */}
      </div>

      {showUser && (

        //  <ViewUser reportInfo={report} setShowUser={setShowUser} />
        <UserDetailPage
          userIds={userId}
          userInfo={first}
          setVerificationPendingUsers={setReports}
          setShowUser={setShowUser}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className=' my-8  caret-transparent px-8 py-4  rounded-lg  bg-white shadow-sm'>
          <ReusableTable
            data={reviewers}
            columns={[
              {
                key: 'firstName',
                label: 'First Name'
              },
              {
                key: 'lastName',
                label: 'Last Name'
              },
              {
                key: 'email',
                label: 'Email'
              },
              {
                key: 'phoneNumber',
                label: 'Phone Number'
              }
              // {
              //   key: 'pendingActions',
              //   label: 'Action Taken'
              // }
            ]}
            onRowClick={handleClick}
          />

          {countUsers > 8 && (
            <div className='mt-8'>
               <Pagination
                totalItems={countUsers}
                itemsPerPage={8}
                setCurrentPage={setCurrentPage}
                currentPage={Number(currentPage)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
