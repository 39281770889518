import { ChangeEvent, useContext, useEffect, useState } from "react";
import { X } from "react-feather";
import {
  findMessagesOfReportedChat,
  findReportedChatEndPoint,
  findReportedUserEndPoint,
  findVerifyImgRequestEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  getUserVerificationRequestImage,
  modifyReviewerActionTypeOnReportedChat,
  modifyReviewerActionTypeOnReportedUser,
  modifyReviewerActionTypeOnUserVerificationRequest,
} from "../../adapters/endpoints";
import { getContent } from "../../adapters/get";
import {
  error,
  giveWarningToUser,
  IdTypeEnum,
  monthNames,
  reportActionType,
  selectActionType,
  success,
  takeActionTextAreaText,
  UpdateActionAlertText,
  UsersTypeEnum,
  UserType,
  verifyActionType,
} from "../../constants";
import { ToastContext } from "../Toast/ToastProvider";
import { createContent } from "../../adapters/create";
import AlertPopup from "../AlertPopup";
import Button from "../Button";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { UserNameAvatar, UserNameAvatarForChat } from "../userNameAvatar";
import { InfoCard } from "../ViewReportedUser";
import ImageViewer from "../ImageViewer";
import Spinner, {DetailSpinner, LoadingDots} from "../Spinner";
const Box = ({ item }: any) => {
  const { title, value } = item;
  return (
    <div className="border-2 rounded-md py-2 text-center space-y-2 ">
      <p className="text-lg font-medium">{value}</p>
      <p className="text-gray-400 ">{title}</p>
    </div>
  );
};

const ReportDetail = ({
  reportId,
  reportType,
  reporterName,
  description,
  reviewerAction,
  noticeFromReviewer,
  noteToAdmin,
  isReviewed,
  isGenuine,
}: any) => {
  const [openVerificationImage, setOpenVerificationImage] = useState(false);
  const [openReferenceImage, setOpenReferenceImage] = useState(false);
  const [images, setImages] = useState<any>();
  const [loadingVerificationImage, setLoadingVerificationImage] =
    useState(false);

  function onSucessGettingUsersVerificationImgs(data: any) {
    setLoadingVerificationImage(false);
    setImages(data.image);
  }
  function onFail(message: any) {
    setLoadingVerificationImage(false);
  }
  useEffect(() => {
    if (reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION) {
      const VERIFY_USER_IMG = `${getUserVerificationRequestImage}${reportId}`;
      setLoadingVerificationImage(true);

      getContent(VERIFY_USER_IMG, onSucessGettingUsersVerificationImgs, onFail);
    }
  }, []);

  const referThisImg =
    images?.referenceImageForVerificationRequest[0]?.image?.data;
  const imgForVerification =
    images?.findUserImageToRespondUserVerificationRequest?.image?.data;

  return (
    <div>
    {loadingVerificationImage ? (
              <div>
              <DetailSpinner />
              </div>
            ):(
<div className="space-y-6 py-6">     
      <span className=" text-xl font-bold text-gray-900">{reporterName}</span>
      <p className=" py- font-medium text-gray-500">
        Reviewer Action :
        <span className=" m-2 bg-red-300 rounded-md p-2 text-red-800">
          {reviewerAction
            ? reviewerAction
            : isGenuine
            ? verifyActionType.verify
            : verifyActionType.decline}
        </span>
      </p>
      <div className=" h-[60vh] overflow-auto">

      <p className=" font-medium text-gray-500">
        Description : <br />{" "}
        <div className=" my-1 p-2 rounded bg-gray-100 text-gray-900">
          {description}
        </div>{" "}
      </p>
      <p className=" font-medium text-gray-500">
        Notice from Reviewer : <br />
        <div className=" my-1 p-2 rounded break-words  bg-gray-100 text-gray-900">
          {noticeFromReviewer}
        </div>{" "}
      </p>
      <p className=" font-medium text-gray-500">
        Notice from Reviewer to manager : <br />
        <div className=" my-1 p-2 rounded break-words  bg-gray-100 text-gray-900">
          {noteToAdmin}
        </div>{" "}
      </p>
      <div>
        {" "}
        {reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION ? (
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <p className=" text-sm  text-[#999999]">
                    Image took for Verification
                  </p>
                  <p className=" text-sm  text-[#999999]">
                    Image for Reference
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <img
                    className=" object-cover w-full h-64 rounded-md"
                    onClick={() => setOpenVerificationImage(true)}
                    src={`data:image/jpeg;base64,${imgForVerification}`}
                    alt=" Imgage took for Verification"
                  />
                  {openVerificationImage && (
                    <ImageViewer
                      imageUrl={imgForVerification}
                      onClose={() => setOpenVerificationImage(false)}
                    />
                  )}
                  <img
                    className=" object-cover w-full h-64 rounded-md"
                    onClick={() => setOpenReferenceImage(true)}
                    src={`data:image/jpeg;base64,${referThisImg}`}
                    alt=" Imgage took for Verification"
                  />
                  {openReferenceImage && (
                    <ImageViewer
                      imageUrl={referThisImg}
                      onClose={() => setOpenReferenceImage(false)}
                    />
                  )}
                </div>
              </div>
           
        ) : (
          " "
        )}
      </div>
      </div>
    </div>
            )
          }    
    </div>
  );
};

const UserDetail = ({ user,refreshTrigger  }: any) => {
  const {
    firstName = "",
    lastName = "",
    bio = "",
    gender = "",
    education = "",
    jobTitle = "",
    accountStatus = "",
    rating = "",
    age ="",
    address=""
  } = user || {};

  const { handleCreateToast } = useContext(ToastContext);
  const [userProfilePic, setUserProfilePic] = useState();
  const [responsibleImg, setResponsibleImg] = useState<any>(null);
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loadingUserImage, setLoadingUserImage] = useState(false);

  function onSucessGettingUsersUploadedImgs(data: any) {
    setLoadingUserImage(false);
    setResponsibleImg(data);
  }
  function onSucessGettingUsersImgs(data: any) {
    setUserProfilePic(data?.userProfilePicture[0]?.image?.data);
  }

  function onFail(message: any) {
    setLoadingUserImage(false);
    handleCreateToast(message, error);
  }
  useEffect(() => {
    const RESPONSIBE_ID = `${getReportedUserImage}${user._id}`;
    getContent(RESPONSIBE_ID, onSucessGettingUsersUploadedImgs, onFail);

    const USER_PROFILE_IMG = `${getReportedUserProfilePicture}${user._id}`;
    setLoadingUserImage(true);
    getContent(USER_PROFILE_IMG, onSucessGettingUsersImgs, onFail);
  }, [refreshTrigger ]);

  return (
    <div>
      {loadingUserImage ? (
       <div>
        <DetailSpinner />
       </div>
      ) : (
        <div className="gap-4 overflow-y-auto scrollbar-hide">
          {userProfilePic ? (
            <img
              className=" object-cover border rounded-full  h-16 w-16 "
              src={`data:image/jpeg;base64,${userProfilePic}`}
              alt={firstName}
            />
          ) : (
            <UserNameAvatar userName={firstName} userType={user?.USER} />
          )}
         
          <div className="space-y-2">
            <p className="font-bold text-gray-800 text-2xl">{`${firstName} ${
              lastName ?? ""
            }`}</p>
            <p className="text-gray-400">{bio}</p>
          </div>
          <div className="h-[60vh] overflow-auto">

          <div className="gap-2 divide-y-2 capitalize my-4">
            {gender && <InfoCard value={gender} label="Gender" />}
            {age && <InfoCard value={age} label="Age" />}
            {rating && <InfoCard value={rating} label="Rate" />}
            {accountStatus && (
              <InfoCard
                value={accountStatus}
                label="Account Status"
              />
            )}
            { address.country&& <InfoCard value={address.country} label="Country" />}
            {education && <InfoCard value={education} label="Education" />}
            {jobTitle && <InfoCard value={jobTitle} label="Job" />}
            
          </div>

          <div className="mt-2 h-[100%]">
            <p className=" text-sm  text-[#999999]">Uploaded Images</p>
            <div className="grid grid-cols-2 gap-4 my-4">
              {responsibleImg?.length > 0 ? (
                <>
                  {responsibleImg?.map((image: any, idx: number) => {
                    const handleClick = () => {
                      setOpenZoomPic(true);
                      setSelectedImage(image?.image?.data);
                    };
                    return (
                      <>
                        <img
                          key={idx}
                          className=" object-cover w-full h-64 rounded-md"
                          onClick={handleClick}
                          src={`data:image/jpeg;base64,${image?.image?.data}`}
                          alt=" Imgage took for Verification"
                        />
                        {OpenZoomPic && (
                          <ImageViewer
                            imageUrl={selectedImage}
                            onClose={() => setOpenZoomPic(false)}
                          />
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <LoadingDots />
                )}
            </div>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TakeAction = ({ reportType, reportId, setShowUser }: any) => {
  const [showPopup, setShowPopup] = useState<Boolean>(false);
  const [selectedActionType, setSelectedActionType] = useState("");
  // const [reviewDescription, setReviewDescription] = useState('')
  const [uniqueid, setuniqueid] = useState("intial");
  const { handleCreateToast } = useContext(ToastContext);

  const onSuccess = (data: any, response: any) => {
    setShowPopup(false);
    setuniqueid(crypto.randomUUID());
    handleCreateToast(response.message, success);
    setShowUser(false);
  };

  const onFail = (message: string) => {
    handleCreateToast(message, error);
    setShowPopup(false);
  };

  const submitReview = () => {
    const data = {
      // reasonForModifyingTheAction: reviewDescription,
      actionType: selectedActionType,
      [reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
        ? IdTypeEnum.VERIFICATION_REQUEST_ID
        : reportType === UsersTypeEnum.REPORTED_USER
        ? IdTypeEnum.REPORTED_USER_ID
        : IdTypeEnum.REPORTED_CHAT_ID]: reportId,
    };
    let endpoint;
    switch (reportType) {
      case UsersTypeEnum.REPORTED_USER:
        endpoint = modifyReviewerActionTypeOnReportedUser;
        break;
      case UsersTypeEnum.USERS_CHAT_GOT_REPORTED:
        endpoint = modifyReviewerActionTypeOnReportedChat;
        break;
      case UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION:
        endpoint = modifyReviewerActionTypeOnUserVerificationRequest;
        break;
    }
    createContent(endpoint, data, onSuccess, onFail);
  };

  return (
    <div
      key={uniqueid}
      className={` scrollbar-hide flex flex-col justify-between h-[75vh] overflow-auto  `}
    >
      <div>
        <h2 className="text-base md:text-lg font-semibold mb-2 md:mb-4 sm:left-2">
          {" "}
          Action Type{" "}
        </h2>
        <Dropdown
          name={selectActionType}
          list={
            reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
              ? verifyActionType
              : reportActionType
          }
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedActionType(e.currentTarget.innerText);
          }}
        />
        {/* <TextArea
          text={takeActionTextAreaText}
          change={(e: ChangeEvent<HTMLInputElement>) => {
            setReviewDescription(e.target.value)
          }}
        /> */}

        {showPopup ? (
          <AlertPopup
            message={UpdateActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
          ></AlertPopup>
        ) : null}
      </div>
      <Button text={giveWarningToUser} onClick={() => setShowPopup(true)} />
    </div>
  );
};

const ReportedChat = ({ chatReportData, report, reportType }: any) => {
  const [message, setMesssage] = useState([]);
  const [senderId, setSenderId] = useState();
  const [chats, setChats] = useState<any>();
  const [sender, setSender] = useState();
  const [receiver, setReceiver] = useState();
  const [receiverId, setReceiverId] = useState();
  const chatId = report?.responsibleChatId;
  const [loadingUserChats, setLoadingUserChats] = useState(false);

  const { handleCreateToast } = useContext(ToastContext);

  const endpoint = `${findMessagesOfReportedChat}${chatId}`;

  const monthIndex = new Date(chats?.updatedAt)?.getMonth();
  const monthName = monthNames[monthIndex];

  const day = new Date(chats?.updatedAt).getDate();

  const onSucess = (data: any) => {
    setLoadingUserChats(false)
    setChats(data.messagesOfReportedChat[0]);
    setSenderId(data.messagesOfReportedChat[0]?.senderId);
    setReceiverId(data.messagesOfReportedChat[0]?.receiverId);
    setMesssage(data.messagesOfReportedChat[0]?.messageIds);
  };

  const onFail = (message: any) => {
    setLoadingUserChats(false)
    handleCreateToast(message, error);
  };

  useEffect(() => {
    if (chatId?.length > 0) {
      getContent(endpoint, onSucess, onFail);
      setLoadingUserChats(true)
    }
  }, [chatId]);

  const [senderProfilePic, setSenderProfilePic] = useState("");
  const [receiverProfilePic, setReceiverProfilePic] = useState("");

  function onSucessGettingSenderImg(data: any) {
    setSenderProfilePic(data?.userProfilePicture[0]?.image?.data);
  }

  function onSucessGettingReceiverImg(data: any) {
    setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data);
  }

  useEffect(() => {
    if (chats) {
      const findSenderImg = `${getReportedUserProfilePicture}${senderId}`;
      const findReceiverImg = `${getReportedUserProfilePicture}${receiverId}`;

      getContent(findSenderImg, onSucessGettingSenderImg, onFail);
      getContent(findReceiverImg, onSucessGettingReceiverImg, onFail);
    }
  }, [chats, senderId, receiverId]);

  return (
    <div>
       {loadingUserChats ? (
        <div>
          <DetailSpinner />
        </div>
      ):( <div
        className={`h-[75vh] overflow-auto space-y-6 relative  `}
      >
        <div className="flex  ">
          <div className=" absolute rounded-full bg-gray-200 text-gray-400 px-4 max-w-max right-2/4  ">
            {monthName && monthName} {day && day}
          </div>
        </div>
        <ChatUI
          report={report}
          messages={message}
          senderId={senderId}
          senderProfilePic={senderProfilePic}
          receiverProfilePic={receiverProfilePic}
        />
      </div>)}
   
    </div>
  );
};

const ChatUI = ({
  report,
  messages,
  senderId,
  senderProfilePic,
  receiverProfilePic,
}: any) => {
  const ImgCircle = ({ userImg, userName }: any) => {
    return (
      <div className="h-10 w-10 overflow-hidden rounded-full">
        <img
          className="object-cover h-full w-full"
          src={`data:image/jpeg;base64,${userImg}`}
          alt={userName}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow h-0 overflow-y-auto scrollbar-hide">
        {messages?.map((message: any) => {
          const dateString = message?.createdAt;
          const date = new Date(dateString);
          const time = date.toLocaleTimeString();

          const repliedMessage = messages.find(
            (msg: any) => msg._id === message.repliedToMessageId
          );

          const isSender = message.senderId === senderId;
          const messageClassName = `px-4 py-2 rounded-2xl bg-gray-200 ${
            isSender ? "rounded-br-none" : "rounded-bl-none"
          }`;

          return (
            <div
              key={message._id}
              id={message._id}
              className={`flex gap-2 my-2 ${
                isSender ? "flex-row-reverse" : "flex"
              }`}
            >
              <div className={`flex items-end `}>
                {isSender ? (
                  <>
                    {senderProfilePic ? (
                      <ImgCircle
                        userImg={senderProfilePic}
                        userName={report?.reporterId[0]?.firstName}
                      />
                    ) : (
                      <UserNameAvatarForChat
                        userType={UserType.SENDER}
                        userName={report?.reporterId[0]?.firstName}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {receiverProfilePic ? (
                      <ImgCircle
                        userImg={receiverProfilePic}
                        userName={report?.responsibleId[0]?.firstName}
                      />
                    ) : (
                      <UserNameAvatarForChat
                        userType={UserType.RECIEVER}
                        userName={report?.responsibleId[0]?.firstName}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex flex-col mt-6">
                {message.repliedToMessageId && repliedMessage ? (
                  <div
                    className="bg-gray-100 rounded-lg p-2 mb-2 cursor-pointer"
                    style={{ borderLeft: "4px solid #e0e0e0" }}
                    onClick={() => {
                      const originalMessageElement = document.getElementById(
                        repliedMessage?._id
                      );
                      if (originalMessageElement) {
                        originalMessageElement.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }}
                  >
                    <p className="text-sm font-semibold text-gray-600 ">
                      {repliedMessage.messageBody.slice(0, 50)}...
                    </p>
                  </div>
                ) : null}
                <div className={messageClassName}>
                  <p className="font-semibold">{message.messageBody}</p>
                </div>
                <div className="flex justify-end">
                  <p
                    className={`text-xs text-gray-400 mt-2 font-small ${
                      isSender ? "ml-auto" : "mr-auto"
                    }`}
                  >
                    {time}
                  </p>
                  <div className="flex justify-end">
                    {message.reactionFromSender && (
                      <div
                        className={`h-8 w-8 bg-white border-2 border-gray-300 ${
                          isSender ? "mr-0" : "ml-0"
                        } rounded-full flex items-center justify-center`}
                      >
                        <span role="img" aria-label="emoji">
                          {message.reactionFromSender}
                        </span>
                      </div>
                    )}
                    {message.reactionFromReceiver && (
                      <div
                        className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${
                          isSender ? "mr-0" : "ml-0"
                        } rounded-full flex items-center justify-center`}
                      >
                        <span role="img" aria-label="emoji">
                          {message.reactionFromReceiver}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function ViewUser({ reportInfo, setShowUser }: any) {
  const [tab, setTab] = useState(1);
  const { handleCreateToast } = useContext(ToastContext);
  const [report, setReport] = useState<any>();
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Add refreshTrigger state

  function updateEndpointOnTheBasisOfUsersType(type: string): string {
    const endpoints = {
      REPORTEDUSERS: findReportedUserEndPoint,
      REPORTEDCHATS: findReportedChatEndPoint,
      VERIFICATIONUSERS: findVerifyImgRequestEndPoint,
    };

    return endpoints[type as keyof typeof endpoints];
  }

  function onSuccess(data: any) {
    switch (reportInfo.type) {
      case UsersTypeEnum.REPORTED_USER:
        setReport(data?.reportedUser[0]);
        break;
      case UsersTypeEnum.USERS_CHAT_GOT_REPORTED:
        setReport(data?.reportedChat[0]);
        break;
      case UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION:
        setReport(data?.verificationRequest[0]);
        break;
    }
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  const GET_REPORT_DETAIL = `${updateEndpointOnTheBasisOfUsersType(
    reportInfo?.type
  )}${reportInfo?._id}`;

  useEffect(() => {
    getContent(GET_REPORT_DETAIL, onSuccess, onFail);
  }, [GET_REPORT_DETAIL]);

  const tabComponents = {
    1: (
      <UserDetail
        user={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? report?.userInfoId[0]
            : report?.responsibleId[0]
        }
        refreshTrigger={refreshTrigger} 
      />
    ),
    2: (
      <ReportDetail
        reporterName={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? ""
            : `${report?.reporterId[0]?.firstName} ${
                report?.reporterId[0]?.lastName ?? ""
              }`
        }
        reportId={report?._id}
        reportType={reportInfo.type}
        description={report?.description}
        isReviewed={report?.isReviewed}
        isGenuine={report?.isGenuine}
        reviewerAction={report?.actionType}
        noticeFromReviewer={report?.reviewerReasonForTakingAction}
        noteToAdmin={report?.noteToAdminManager}
      />
    ),
    3: (
      <UserDetail
        user={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? "no user"
            : report?.reporterId[0]
        }
        refreshTrigger={refreshTrigger} 
      />
    ),
    4: <ReportedChat report={report} reportInfo={reportInfo} />,
    5: (
      <TakeAction
        reportType={reportInfo?.type}
        reportId={reportInfo?._id}
        setShowUser={setShowUser}
      />
    ),
  };
  const handleTabClick = (selectedTab: number) => {
    setTab(selectedTab);
    setRefreshTrigger((prev) => !prev); // Toggle the refreshTrigger to trigger useEffect
  };
  return (
    <div className=" fixed inset-0 z-40 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 ">
      <div className="p-8 absolute space-y-4  top-0 right-0 w-[30%] bg-white rounded-md z-50 h-full ">
        <div className="flex justify-between">
          <h1 className=" text-2xl ">{""}</h1>

          <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
        </div>
        <div className={`flex  flex-row-1 font-bold  overflow-x-auto scrollbar-hide`}>
          <p
            onClick={() =>  handleTabClick(1) }
            className={`p-4 pt-0 cursor-pointer ${
              tab === 1 && "border-b-4 border-[#2e2e2e]"
            }`}
          >
            User
          </p>
          <p
            onClick={() => setTab(2)}
            className={`p-4 pt-0 cursor-pointer ${
              tab === 2 && "border-b-4 border-[#2e2e2e]"
            }`}
          >
            Report
          </p>
          {reportInfo.type !== UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION && (
            <p
              onClick={() => handleTabClick(3)}
              className={`p-4 pt-0 cursor-pointer ${
                tab === 3 && "border-b-4 border-[#2e2e2e]"
              }  `}
            >
              Reporter
            </p>
          )}
          {reportInfo.type === UsersTypeEnum.USERS_CHAT_GOT_REPORTED && (
            <p
              onClick={() => setTab(4)}
              className={`p-4 pt-0 cursor-pointer ${
                tab === 4 && "border-b-4 border-[#2e2e2e]"
              }  `}
            >
              Chats
            </p>
          )}
          <p
            onClick={() => setTab(5)}
            className={`p-4 pt-0 cursor-pointer  ${
              tab === 5 && "border-b-4 border-[#2e2e2e]"
            }  `}
          >
            Modify Action
          </p>
        </div>
        {report !== null &&
          report !== undefined &&
          Object.keys(report).length > 0 && (
            <>
              {tab in tabComponents &&
                tabComponents[tab as keyof typeof tabComponents]}
            </>
          )}
      </div>
    </div>
  );
}
