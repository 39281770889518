import { useState } from "react";
import { planProps } from "./Settings";

interface Props {
  name: string;
  label: string;
  rows?: number;
  errors: any;
  setCurrPlan: React.Dispatch<React.SetStateAction<planProps>>;
  value: string;
}

const ReusableTextArea: React.FC<Props> = ({
  name,
  label,
  rows = 4,
  errors,
  setCurrPlan,
  value,
}) => {
  const [currValue, setCurrValue] = useState(value);

  return (
    <div className="flex flex-col  space-y-2">
      <label htmlFor={name} className="font-semibold">
        {label}
      </label>
      <textarea
        name={name}
        value={currValue}
        onChange={(e) => {
          setCurrValue(e.target.value);
          setCurrPlan((prevPlan) => {
            prevPlan.description = e.target.value;
            return prevPlan;
          });
        }}
        rows={rows}
        className={`
								outline-none focus:border-[#0F85F2]  font-medium 
								border-2    px-4 py-2 rounded
                ${
                  errors[name]
                    ? "border-red-500 focus-within:border-red-500"
                    : null
                } 
								
								`}
      />
      <p className={`text-sm ${errors[name] ? "text-red-500" : ""}`}>
        {errors[name]?.message}
      </p>
    </div>
  );
};

export default ReusableTextArea;
