import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReviewerDashboard from "./pages/Dashboard";
import AdminDashboard from "./components/AdminManager/Dashboard";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ToastProvider from "./components/Toast/ToastProvider";
import ToastShelf from "./components/Toast/ToastShelf";
import AdminManagerProvider from "./components/AdminManager/AdminProvider";
import AccountActionVerificationPage from "./pages/ReusableEmailVerification";
import {
  ADMIN_MANAGER_DASHBOARD_ROUTE,
  FORGET_PASSWORD_ROUTE,
  REVIEWER_DASHBOARD_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  VERIFY_FORGET_PASSWORD_ROUTE,
  VERIFY_SIGN_UP_ROUTE,
} from "./constants";
import ProtectedRoute from './ProtectedRoute';

export interface IAlert {
  msg: string;
  type: string;
  show: boolean;
}

const App = () => {
  return (
    <ToastProvider>
      <ToastShelf />
      <AdminManagerProvider>
        <div>
          <Router>
            <Routes>
              <Route index element={<Home />} />
              <Route
                path={REVIEWER_DASHBOARD_ROUTE}
                element={<ProtectedRoute element={<ReviewerDashboard />} />}
              />
              <Route
                path={ADMIN_MANAGER_DASHBOARD_ROUTE}
                element={<ProtectedRoute element={<AdminDashboard />}/>}
              />
              <Route path={SIGN_IN_ROUTE} element={<SignIn />} />
              <Route path={SIGN_UP_ROUTE} element={<SignUp />} />
              <Route path={FORGET_PASSWORD_ROUTE} element={<ForgotPassword />} />
              <Route
                path={VERIFY_SIGN_UP_ROUTE}
                element={<AccountActionVerificationPage />}
              />
              <Route
                path={VERIFY_FORGET_PASSWORD_ROUTE}
                element={<AccountActionVerificationPage />}
              />
            </Routes>
          </Router>
        </div>
      </AdminManagerProvider>
    </ToastProvider>
  ); 
};

export default App;
