import { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  EMAIL,
  EMAIL_IS_MANDATORY,
  ENTER_VALID_EMAIL,
  error,
  PASSWORD,
  passwordLength,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  success,
  TEXT,
} from "../constants";
import Input from "../components/Input";
import InputPassword from "../components/InputForPassword";
import { createContent } from "../adapters/create";
import { forgetAdminPassword } from "../adapters/endpoints";
import { ToastContext } from "../components/Toast/ToastProvider";

export default function ForgotPassword() {
  const { handleCreateToast } = useContext(ToastContext);

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
    email: Yup.string()
      .email(ENTER_VALID_EMAIL)
      .required(EMAIL_IS_MANDATORY),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) => {
    handleCreateToast(response.message, success);
  };

  const onFail = (message: string) => handleCreateToast(message, error);

  return (
    <div className=" flex flex-col  h-screen  place-content-center  max-w-sm m-auto space-y-8 w-full  ">
      <h1 className="text-3xl font-medium my-4  ">Reset your Password</h1>
      <form
        onSubmit={handleSubmit(async (data) => {
          createContent(forgetAdminPassword, data, onSuccess, onFail);
        })}
      >
        <div className="space-y-2">
          <Input
            label="Email"
            type={TEXT}
            name={EMAIL}
            defaultValue=""
            register={register}
            errors={errors}
          />
          <InputPassword
            label="New Password"
            type={PASSWORD}
            name={PASSWORD}
            defaultValue=""
            register={register}
            errors={errors}
          />
        </div>
        <button className="block text-center  w-full my-8  font-semibold  bg-blue-500 text-white px-14 rounded-md py-2 ">
          Reset your Password
        </button>
      </form>
    </div>
  );
}
