import { createContent, postApi } from "../adapters/create";
import { deleteContent } from "../adapters/delete";

interface deletePlanProps {
    ENDPOINT: string;
    onSuccess: (response : any) => void;
    onFail: ( message : string) => void;
    setContentsAfterDeletion: React.Dispatch<React.SetStateAction<boolean>>;
    message:string;
}

export default function ConfirmDeleteDialog({
    ENDPOINT,
    onSuccess,
    onFail,
    setContentsAfterDeletion,
    message,
}: deletePlanProps) {

    return (
        <div className=" fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 ">
            <div className="bg-white p-8 rounded-lg  ">
                <p className=" text-xl font-bold w-[60%] ">
                   {message}
                </p>
                <p className="  text-gray-400 font-bold mt-2">
                    This action cannot be undone
                </p>
                <div className="flex justify-between gap-x-4 mt-14 ">
                    <button
                        onClick={() => setContentsAfterDeletion(false)}
                        type="submit"
                        className=" w-full   font-semibold  bg-[#F0F0F0]  text-[#2E2E2E] px-14 rounded py-4 "
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => postApi(ENDPOINT , onSuccess , onFail)}
                        type="submit"
                        className=" w-full   font-semibold  bg-[#2e2e2e] hover:bg-[#2e2e2eed] text-white px-14 rounded py-4 "
                    >
                        Confirm{" "}
                    </button>
                </div>
            </div>
        </div>
    );
}
