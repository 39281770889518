import { useState, ChangeEvent, useContext, useEffect, useRef } from "react";
import { X } from "react-feather";
import Dropdown from "../Dropdown";
import AlertPopup from "../AlertPopup";
import TextArea from "../TextArea";
import Button from "../Button";

import {
  takeActionTextAreaText,
  verificationUpdateButton,
  selectActionType,
  verifyActionType,
  noteToAdmin,
  TakeActionAlertText,
  reportActionType,
  giveWarningToUser,
  subscriptionDurations,
  selectSubscriptionDuration,
  UserType,
  userRate,
  PlanName,
  selectPlanName,
  userGender,
  UpdatePreferenceGender,
  UpdateUserGender,
  UpdateUserRating,
} from "../../constants";
import { ToastContext } from "../../components/Toast/ToastProvider";
import { error, success } from "../../constants";
import { createContent } from "../../adapters/create";
import {
  findVerifyImgRequestEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  getUserVerificationRequestImage,
  takeActionOnUserEndpoint,
  saveUserSubscription,
  takeActionOnUserVerificationRequestEndPoint,
  sendMessageToUser,
  getBotMessages,
  updateUserRate,
  getSubscriptionHistory,
  updateMistakeGender,
  updateMistakePreferenceGender,
} from "../../adapters/endpoints";
import { getContent } from "../../adapters/get";
import { LoadingDots, SideBarSpinner } from "../Spinner";
import { UserNameAvatar } from "../userNameAvatar";
import ImageViewer from "../ImageViewer";
import InfoCard from "../InfoCard";

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  userIds: any;
  userInfo: any;
  setVerificationPendingUsers: any;
}
const IconCard = ({ icon: Icon, title, color, children }: any) => {
  return (
    <div className="flex gap-2">
      {/* <div className={`bg-${color}-100 rounded-md p-2`}>
        <Icon className=" text-[#2e2e2e] " />
      </div> */}
      <div>
        <strong>{children}</strong>
        <p className=" text-sm text-gray-400 ">{title}</p>
      </div>
    </div>
  );
};
const UserDetailPage: React.FC<Props> = ({
  setShowUser,
  userIds,
  userInfo,
  setVerificationPendingUsers,
}) => {
  const [tab, setTab] = useState(1);
  const [userVerifyData, setUserVerifyData] = useState<any>();
  const [isVerifyDataLoading, setisVerifyDataLoading] = useState(false);
  const [images, setImages] = useState<any>();
  const [responsibleImg, setResponsibleImg] = useState<any>(null);
  const [userProfilePic, setUserProfilePic] = useState();
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [openVerificationImage, setOpenVerificationImage] = useState(false);
  const [openReferenceImage, setOpenReferenceImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showPreferenceDetails, setShowPreferenceDetails] = useState(false);
  const [showActivityDetails, setShowActivityDetails] = useState(false);

  function onSucessGettingUsersVerificationImgs(data: any) {
    setImages(data.image);
  }

  function onSucessGettingUsersImgs(data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image?.data);
  }

  function onSucessGettingUsersUploadedImgs(data: any) {
    setResponsibleImg(data);
  }

  function onFail(message: any) {
    setisVerifyDataLoading(false);
  }

  function onSuccessGetVerifyUserData(data: any) {
    setUserVerifyData(data.verificationRequest[0]);
    setisVerifyDataLoading(false);
  }

  useEffect(() => {
    if (userIds !== 0) {
      const GET_VERIFY_USER_DATA = `${findVerifyImgRequestEndPoint}${userIds}`;
      getContent(GET_VERIFY_USER_DATA, onSuccessGetVerifyUserData, onFail);
      setisVerifyDataLoading(true);
    }
  }, []);

  useEffect(() => {
    const RESPONSIBE_ID = `${getReportedUserImage}${userIds}`;
    getContent(RESPONSIBE_ID, onSucessGettingUsersUploadedImgs, onFail);

    const USER_IMG = `${getReportedUserProfilePicture}${userIds}`;
    getContent(USER_IMG, onSucessGettingUsersImgs, onFail);

    // const VERIFY_USER_IMG = `${getUserVerificationRequestImage}${userIds}`
    // getContent(VERIFY_USER_IMG, onSucessGettingUsersVerificationImgs, onFail)
  }, []);

  let user: any = {};

  useEffect(() => {
    if (userVerifyData) {
      user = userVerifyData?.userInfoId[0];
    }
  }, [userVerifyData]);

  const referThisImg =
    images?.referenceImageForVerificationRequest[0]?.image?.data;
  const imgForVerification =
    images?.findUserImageToRespondUserVerificationRequest?.image?.data;

  function userStatus() {
    if (userInfo?.userStatus === true) {
      return (
        <div className="inline-flex items-center">
          <span className="h-4 w-4 rounded-full bg-green-500 mr-1"></span>
        </div>
      );
    } else {
      return null;
    }
  }
  function age() {
    let age = userInfo[0]?.userInfo[0]?.dateOfBirth;
  }
  
  const UserDetail = () => {
    
    return (
      <div className={`${tab === 1 ? "block  " : "hidden"}  `}>
        {userInfo ? (
          <div className="scrollbar-hide  h-[60%]">
            <div className="flex flex-col gap-6 mb-4 ">
              <div className="flex  gap-6 items-center ">
                {userProfilePic ? (
                  <img
                    className=" object-cover border rounded-full  h-16 w-16 "
                    src={`data:image/jpeg;base64,${userProfilePic}`}
                    alt={userInfo?.userInfo[0]?.firstName}
                  />
                ) : (
                  <UserNameAvatar
                    userName={userInfo?.userInfo[0]?.firstName}
                    userType={user?.USER}
                  />
                )}
                <p className="font-bold text-lg">
                  {userInfo?.userInfo && userInfo?.userInfo[0]?.firstName}{" "}
                  {userInfo?.userInfo && userInfo?.userInfo[0]?.lastName}
                </p>
                {userStatus()}
              </div>
              <p>
                Phone number:{" "}
                {userInfo?.userInfo && userInfo?.userInfo[0]?.phoneNumber}
              </p>
              <div className="h-[60vh] overflow-auto">
                <div className=" gap-2 p-4 divide-y-2 capitalize my-2">
                  {userInfo?.userInfo[0]?.accountStatus && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {userInfo?.userInfo[0]?.rating && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {userInfo?.userInfo[0]?.gender && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userInfo?.userInfo[0]?.age && (
                    <InfoCard value={userInfo?.userInfo[0]?.age} label="Age" />
                  )}

                  {userInfo?.userInfo[0]?.address?.country && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {userInfo?.userInfo[0]?.address?.region && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  {userInfo?.userInfo[0]?.jobTitle && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.jobTitle}
                      label="Job"
                    />
                  )}

                  {userInfo?.userInfo[0]?.subscriptionStatus && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.subscriptionStatus}
                      label="Subscription"
                    />
                  )}
                  {userInfo?.userInfo[0]?.subscriptionEndDate && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.subscriptionEndDate}
                      label="Subscription End Date"
                    />
                  )}
                  {userInfo?.userInfo[0]?.lastSeenAt && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
                <div className="max-w-sm mx-auto">
                <div className="bg-white border border-burgundy rounded-md">
                <div
            className="flex justify-between items-center cursor-pointer py-4"
            onClick={() => setShowPreferenceDetails(!showPreferenceDetails)}
          >
            <p className=" text-black text-lg mb-2 ml-2 font-bold">More about preference</p>
            <button
              className="text-black py-2 px-2 rounded-md flex items-center justify-center" type="button"
            >
              <span className={`transform transition-transform ${showPreferenceDetails ? 'rotate-180' : ''}`}>
                ▼
              </span>
            </button>
          </div>
               
                  {showPreferenceDetails && (
                <div className="gap-2 divide-y-2 capitalize my-2">
                  
                {userInfo?.matchpreferences[0]?.gender && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userInfo?.matchpreferences && userInfo.matchpreferences.length > 0
                   && userInfo.matchpreferences[0].age && (
                  <InfoCard
                 value={`${userInfo.matchpreferences[0].age.min}-${userInfo.matchpreferences[0].age.max}`}
                 label="Age"
                 />
                 )}
                  {userInfo?.matchpreferences[0]?.hasOwnProperty('onlyVerified') && (
                  <InfoCard
                   value={userInfo?.matchpreferences[0]?.onlyVerified ? "Yes" : "No"}
                  label="Show only verified users"
                    />
                   )}
                    {userInfo?.matchpreferences[0]?.address?.country && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.address?.country}
                      label="Country"
                    />
                  )}
                   {userInfo?.matchpreferences[0]?.address?.region && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  
                   {userInfo?.matchpreferences[0]?.religion && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.religion}
                      label="Religion"
                    />
                  )}
                  
                </div>
                  )}
                   </div>
                   </div>
                   <div className="max-w-sm mx-auto py-4">
                   <div className="bg-white border border-burgundy rounded-md">
                   <div
            className="flex justify-between items-center cursor-pointer py-4"
            onClick={() => setShowActivityDetails(!showActivityDetails)}
          >
            <p className=" text-black text-lg mb-2 ml-2 font-bold">More about user activity</p>
            <button
              className="text-black py-2 px-2 rounded-md flex items-center justify-center"
            >
              <span className={`transform transition-transform ${showActivityDetails ? 'rotate-180' : ''}`}>
                ▼
              </span>
            </button>
          </div>
          {showActivityDetails && (
            <div className="gap-2 divide-y-2 capitalize my-2">
                {userInfo?.userconnections[0]?.matchedUserIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.matchedUserIds}
                      label="Matched Users"
                    />
                  )}
                  {userInfo?.userconnections[0]?.likesReceivedFromIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.likesReceivedFromIds}
                      label="Like Received"
                    />
                  )}
                  {userInfo?.userconnections[0]?.likesSentToIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.likesSentToIds}
                      label="Like Sent"
                    />
                  )}
                  {userInfo?.userconnections[0]?.chatIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.chatIds}
                      label="Chat Users"
                    />
                  )}
                  {userInfo?.userconnections[0]?.blockedUserIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.blockedUserIds}
                      label="Blocked Users"
                    />
                  )}
                </div>
          )}
                </div>
                   </div>
                
                <div className="mt-2 h-[100%]">
                  <p className=" text-sm  text-[#999999]">Uploaded Images</p>
                  <div className="grid grid-cols-2 gap-4 my-4">
                    {responsibleImg?.length > 0 ? (
                      <>
                        {responsibleImg?.map((img: any, idx: number) => {
                          const handleClick = () => {
                            setOpenZoomPic(true);
                            setSelectedImage(img?.image?.data);
                          };
                          return (
                            <>
                              <img
                                key={idx}
                                className=" object-cover w-full h-64 rounded-md"
                                onClick={handleClick}
                                src={`data:image/jpeg;base64,${img?.image?.data}`}
                                alt=" Img took for Verification"
                              />
                              {OpenZoomPic && (
                                <ImageViewer
                                  imageUrl={selectedImage}
                                  onClose={() => setOpenZoomPic(false)}
                                />
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <LoadingDots />
                    )}
                  </div>
                  {/* <div className='grid grid-cols-2 gap-4'>
                  <p className=' text-sm  text-[#999999]'>
                    Image took for Verification
                  </p>
                  <p className=' text-sm  text-[#999999]'>Image for Reference</p>
                </div>
                <div className='grid grid-cols-2 gap-4 my-4'>
                  <img
                    className=' object-cover w-full h-64 rounded-md'
                    onClick={() => setOpenVerificationImage(true)}
                    src={`data:image/jpeg;base64,${imgForVerification}`}
                    alt=' Img took for Verification'
                  />
                  {openVerificationImage && (
                    <ImageViewer
                      imageUrl={imgForVerification}
                      onClose={() => setOpenVerificationImage(false)}
                    />
                  )}
                  <img
                    className=' object-cover w-full h-64 rounded-md'
                    onClick={() => setOpenReferenceImage(true)}
                    src={`data:image/jpeg;base64,${referThisImg}`}
                    alt=' Img took for Verification'
                  />
                  {openReferenceImage && (
                    <ImageViewer
                      imageUrl={referThisImg}
                      onClose={() => setOpenReferenceImage(false)}
                    />
                  )}
                </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6 top-1/2 sm:top-[44%] ">
            <SideBarSpinner />
          </div>
        )}
      </div>
    );
  };

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [selectedActionType, setSelectedActionType] = useState("");
    const [reviewDescription, setReviewDescription] = useState("");
    const [noteToAdminManager, setNoteToAdminManager] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [newMessage, setNewMessage] = useState<string>('');


    const onSuccess = (data: any, response: any) => {
     
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        actionType: selectedActionType,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(takeActionOnUserEndpoint, data, onSuccess, onFail);
    };
    const sentMessage = () => {
      const data = {
        message: newMessage,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(sendMessageToUser, data, onSuccess, onFail);
    };
    return (
      <div className={` h-full ${tab === 2 ? "block  " : "hidden"}`}>
        <div className="flex flex-col justify-between h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
          <div>
            <h2 className=" text-lg font-semibold  mb-2 left-2">
              Action Type{" "}
            </h2>
            <Dropdown
              name={selectActionType}
              list={reportActionType}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText);
              }}
            />
          </div>
          <div className='flex justify-between items-center p-4 border-t border-gray-200'>
        <textarea
          className='w-3/4 p-2 border rounded'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='Type your message...'
        />
        <button
          className='bg-blue-500 text-white p-2 rounded cursor-pointer'
          onClick={sentMessage}
          disabled={!newMessage.trim()}
          >
          Send
        </button>
      </div>        
        <Button
            disabled={!selectedActionType}
            text={giveWarningToUser}
            onClick={() => setShowPopup(true)}
          />
        </div>
        
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    );
  };
  const SaveEthioUserSubscription = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [duration, setDuration] = useState("");
    const [planName, setPlanName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [paymentInfo, setPaymentInfo] = useState<any>([]);
    const onSuccess = (data: any, response: any) => {
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        planName: planName,
        duration: duration,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(saveUserSubscription, data, onSuccess, onFail);
    };

    function onSucessGettingPaymentInfo(data: any) {
      setPaymentInfo(data.paymentInfo);
    }

    useEffect(() => {
      if (userIds !== 0) {
        const GET_PAYMENT_INFO = `${getSubscriptionHistory}${userIds}`;
        getContent(GET_PAYMENT_INFO, onSucessGettingPaymentInfo, onFail);
      }
    }, []);

    return (
      <div className={`scrollbar-hide  h-[80%] ${tab === 3 ? "block" : "hidden"}`}>
  <div className="grid grid-cols-3 gap-y-4 bg-gray-50 p-4">
    <IconCard icon="" color="gray" title="Silver">
      {paymentInfo[0]?.silver}
    </IconCard>
    <IconCard icon="" color="gray" title="Gold">
      {paymentInfo[0]?.gold}
    </IconCard>
    <IconCard icon="" color="gray" title="Platinum">
      {paymentInfo[0]?.platinum}
    </IconCard>
  </div>
  <div className="flex flex-col justify-between h-full max-h-[85%] scrollbar-hide overflow-y-auto">
  
    <div className="flex flex-row justify-between items-center ">
      <div>
        <h2 className="text-lg font-semibold mb-2">Subscription Type</h2>
        <Dropdown
          name={selectPlanName}
          list={PlanName}
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            setPlanName(e.currentTarget.innerText);
          }}
        />
      </div>
      <div>
        <h2 className="text-lg font-semibold mb-2">Duration Type</h2>
        <Dropdown
          name={selectSubscriptionDuration}
          list={subscriptionDurations}
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            setDuration(e.currentTarget.innerText);
          }}
        />
      </div>
    </div>
    <Button
      disabled={!duration  || !planName}
      text={giveWarningToUser}
      onClick={() => setShowPopup(true)}
    />
  </div>

  {showPopup ? (
    <AlertPopup
      message={TakeActionAlertText}
      Close={() => {
        setShowPopup(false);
      }}
      onSubmit={submitReview}
      isLoading={isLoading}
    ></AlertPopup>
  ) : null}
</div>

    );
  };
  const UpdateUserRate = () => {
    const [ratingShowPopup, setRatingShowPopup] = useState<Boolean>(false);
    const [genderShowPopup, setGenderShowPopup] = useState<Boolean>(false);
    const [preferenceGendershowPopup, setPreferenceGenderShowPopup] = useState<Boolean>(false);   
     const [rate, setRate] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [gender, setGender] = useState("");
    const [preferenceGender, setPreferenceGender] = useState("");
    const onSuccess = (data: any, response: any) => {
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        rate: rate,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(updateUserRate, data, onSuccess, onFail);
    };
    const updateGender = () => {
      setIsLoading(true);
      const data = {
        userId: userInfo?.userInfo[0]._id,
        gender: gender,
    
      };
    
      createContent(updateMistakeGender, data, onSuccess, onFail);
    };
    const updatePreferenceGender = () => {
      setIsLoading(true);
      const data = {
        userId: userInfo?.userInfo[0]._id,
        gender: preferenceGender,
      };
    
      createContent(updateMistakePreferenceGender, data, onSuccess, onFail);
    };
    return (
      <div className={` h-full ${tab === 4 ? "block  " : "hidden"}`}>
        <h2>Update user rate</h2>
        <div className="flex justify-between items-center scrollbar-hide  overflow-y-auto ">
          <div className="border border-gray-300 rounded-md p-4 w-full max-w-md">
            <input
              type="text"
              name="rate"
              onChange={(e) => setRate(e.target.value)}
              className="border-none focus:outline-none w-full"
              placeholder="Enter rating (1-5)"
            />
          </div>
          <Button
            disabled={!rate}
            text={giveWarningToUser}
            onClick={() => setRatingShowPopup(true)}
          />
           
          {ratingShowPopup ? (
             <AlertPopup
              message={UpdateUserRating}
             Close={() => {
             setRatingShowPopup(false);
            }}
             onSubmit={submitReview}
            isLoading={isLoading}
           ></AlertPopup>
        ) : null}
        </div>
        <div className=" pt-6 mt-14">
          <h2>Update user gender</h2>
          <div className="flex justify-between items-center content-center">
          <div className="w-full max-w-md">
          <Dropdown
              name={gender}
              list={userGender}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setGender(e.currentTarget.innerText);
              }}
            />
          </div>
         <div className="">
         <Button
            disabled={!gender}
            text={giveWarningToUser}
            onClick={() => setGenderShowPopup(true)}/>

           {genderShowPopup ? (
             <AlertPopup
              message={UpdateUserGender}
             Close={() => {
             setGenderShowPopup(false);
            }}
             onSubmit={updateGender}
            isLoading={isLoading}
           ></AlertPopup>
        ) : null}
         </div>
         </div>
        </div>
        <div className=" pt-6 mt-14">
          <h2>Update Preference gender</h2>
          <div className="flex justify-between items-center content-center">
          <div className="w-full max-w-md">
          <Dropdown
              name={preferenceGender}
              list={userGender}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setPreferenceGender(e.currentTarget.innerText);
              }}
            />
          </div>
         <div className="">
         <Button
            disabled={!preferenceGender}
            text={giveWarningToUser}
            onClick={() => setPreferenceGenderShowPopup(true)}
            />
            {preferenceGendershowPopup ? (
             <AlertPopup
              message={UpdatePreferenceGender}
             Close={() => {
             setPreferenceGenderShowPopup(false);
            }}
             onSubmit={updatePreferenceGender}
            isLoading={isLoading}
           ></AlertPopup>
        ) : null}
         </div>
         </div>
        </div>
      </div>
    );
  };
  return (
    <div className="fixed inset-0 z-40 flex overflow w-full items-center justify-center bg-gray-900 bg-opacity-50 ">
      <div className="p-8 absolute  top-0 right-0 h-full w-[30%] bg-white rounded-l-md z-50  ">
        <div className="flex justify-end">
          <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
        </div>
        <div className=" h-full space-y-8 ">
          <div className="flex  font-bold ">
            <p
              onClick={() => setTab(1)}
              className={`p-4 cursor-pointer ${
                tab === 1 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              User Detail
            </p>
            <p
              onClick={() => setTab(2)}
              className={`p-4 cursor-pointer ${
                tab === 2 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              Take Action
            </p>
            <p
              onClick={() => setTab(3)}
              className={`p-4 cursor-pointer ${
                tab === 3 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              Subscribe
            </p>
            <p
              onClick={() => setTab(4)}
              className={`p-4 cursor-pointer ${
                tab === 4 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              Update User Data
            </p>
          </div>
          <UserDetail />
          <TakeAction />
          <SaveEthioUserSubscription />
          <UpdateUserRate />
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
