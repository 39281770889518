import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../Input";
import Button from "../Button";
import InputPassword from "../InputForPassword";
import {
  accFirstName,
  accLastName,
  error,
  FIRST_NAME_MAX_CHAR_LENGTH,
  LAST_NAME_MAX_CHAR_LENGTH,
  passwordLength,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  success,
} from "../../constants";
import AddNewReviewers from "../AddNewReviewers";
import SubscriptionOptions from "./SubscriptionOptions";
import { ToastContext } from "../Toast/ToastProvider";
import {
  changePasswordEndPoint,
  updateAdminEndPoint,
} from "../../adapters/endpoints";
import { AdminManagerContext } from "./AdminProvider";
import { createContent } from "../../adapters/create";
import { updateContent } from "../../adapters/update";
import { signOut } from "../../adapters/general";
import AddNewEngineers from "../AddNewEngineers";
import Spinner, { DetailSpinner, SideBarSpinner } from "../Spinner";

export type subscriptionPlan = {
  duration: string;
  price: number;
};

export interface planProps {
  _id: string;
  planName: string;
  description: string;
  benefits: string[];
  subscriptionOptions: subscriptionPlan[];
}

const EditProfile = () => {
  const { handleCreateToast } = useContext(ToastContext);
  const { admin, setAdmin } = useContext(AdminManagerContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    firstName: Yup.string().max(accFirstName, FIRST_NAME_MAX_CHAR_LENGTH),
    lastName: Yup.string().max(accLastName, LAST_NAME_MAX_CHAR_LENGTH),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) => {
    setAdmin(data);

    localStorage.setItem("adminManager", JSON.stringify(data));
    handleCreateToast(response.message, success);
    setLoading(false)
  };

  const onFail = (message: string) => {
    handleCreateToast(message, error);
    setLoading(false); // Set loading to false after submission failure
  };
  
  return (
    <div className="bg-white p-8 divide-y divide-gray-400">
      <div>
        <form
          onSubmit={handleSubmit(async (data) => {
            setLoading(true)
            const newAdmin = { ...admin, ...data };

            updateContent(
              `${updateAdminEndPoint}${newAdmin._id}`,
              newAdmin,
              onSuccess,
              onFail
            );
          })}
        >
          <div className="grid  grid-cols-2 gap-4 ">
            <Input
              label="First Name"
              type="text"
              name="firstName"
              register={register}
              errors={errors}
              defaultValue={admin?.firstName}
            />
            <Input
              label="Last Name"
              type="text"
              name="lastName"
              register={register}
              errors={errors}
              defaultValue={admin?.lastName}
            />
          </div>
          <button className=" mt-6  font-semibold  bg-[#2e2e2e] hover:bg-[#2e2e2eed] text-white px-14 rounded py-4 "
           disabled={loading} >
          {loading ? <DetailSpinner /> : 'Save'}
          </button>
        </form>
      </div>
    </div>
  );
};

const EditPassword = () => {
  const { handleCreateToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false)
  const formSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
    newPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
    confirmNewPassword: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .oneOf([Yup.ref("newPassword")], "New Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) => {
    handleCreateToast(response.message, success);

    setValue("currentPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    setLoading(false)
  };

  const onFail = (message: string) =>
  { 
    handleCreateToast(message, error)
    setLoading(false)
  };

  return (
    <div className={`bg-white p-8`}>
      {" "}
      <form
        onSubmit={handleSubmit(async (data) => {
          setLoading(true)
          const { newPassword, currentPassword } = data;

          const passwords = {
            newPassword,
            oldPassword: currentPassword,
          };

          createContent(changePasswordEndPoint, passwords, onSuccess, onFail);
        })}
      >
        <div className="grid  grid-cols-2 gap-4 ">
          <InputPassword
            label="Current Password"
            type="password"
            name="currentPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />
          <InputPassword
            label="New Password"
            type="password"
            name="newPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />{" "}
          <InputPassword
            label="Confirm New Password"
            type="password"
            name="confirmNewPassword"
            defaultValue=""
            register={register}
            errors={errors}
          />
        </div>
        <div className="w-max mt-6 rounded ">
          <button className=" mt-6  font-semibold  bg-[#2e2e2e] hover:bg-[#2e2e2eed] text-white px-14 rounded py-4 " disabled = {loading}>  {loading ? <DetailSpinner /> : 'Save'}</button>
        </div>
      </form>
    </div>
  );
};

export default function Settings() {
  const [tab, setTab] = useState(1);

  const tabComponents = {
    1: <EditProfile />,
    2: <EditPassword />,
    3: <AddNewReviewers />,
    4: <AddNewEngineers />,
    5: <SubscriptionOptions />,
  };

  return (
    <div className="ml-8 w-[100%] scrollbar-hide h-[100vh] py-8 overflow-auto  ">
      <div className="flex max-w-max  font-bold bg-white p-2 pb-0 my-4 rounded-md   justify-between">
        <p
          onClick={() => setTab(1)}
          className={`p-4  cursor-pointer ${
            tab === 1 && "border-b-4 border-[#2e2e2e]"
          }`}
        >
          Edit Profile
        </p>
        <p
          onClick={() => setTab(2)}
          className={`p-4  cursor-pointer ${
            tab === 2 && "border-b-4 border-[#2e2e2e]"
          }`}
        >
          Edit Password
        </p>
        <p
          onClick={() => setTab(3)}
          className={`p-4  cursor-pointer ${
            tab === 3 && "border-b-4 border-[#2e2e2e]"
          }`}
        >
          Add New Reviewers
        </p>
        <p
          onClick={() => setTab(4)}
          className={`p-4  cursor-pointer ${
            tab === 4 && "border-b-4 border-[#2e2e2e]"
          }`}
        >
          Add New Engineers
        </p>
        <p
          onClick={() => setTab(5)}
          className={`p-4  cursor-pointer ${
            tab === 5 && "border-b-4 border-[#2e2e2e]"
          }`}
        >
          Update Subscribtion Plans
        </p>
      </div>
      {tab in tabComponents && tabComponents[tab as keyof typeof tabComponents]}
    </div>
  );
}
