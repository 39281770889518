import { useState, ChangeEvent, useContext, useEffect } from "react";
import { X } from "react-feather";
import Dropdown from "./Dropdown";
import AlertPopup from "./AlertPopup";
import TextArea from "./TextArea";
import Button from "./Button";
import {
  selectActionType,
  noteToAdmin,
  TakeActionAlertText,
  reviewMatchPartnerUsers,
  VerifyMatchPartner,
} from "../constants";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error, success } from "../constants";
import { createContent } from "../adapters/create";
import {
  findMatchingPartner,
  getReportedUserProfilePicture,
  getpartnerImage,
  sendMessageToUser,
  takeActionOnMatchingPartner,
} from "../adapters/endpoints";
import { fetchAndDisplayImages, getContent } from "../adapters/get";
import { DetailSpinner, LoadingDots, SideBarSpinner } from "./Spinner";
import { UserNameAvatar } from "./userNameAvatar";
import InfoCard from "./InfoCard";
import ImageViewer from "./ImageViewer";
import PostImageZoomPopup from "./PostImageViewer";

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  partnerIds: any;
  setPendingMatchPartners: any;
}

const ViewPendingMatchPartner: React.FC<Props> = ({
  setShowUser,
  partnerIds,
  setPendingMatchPartners,
}) => {
  const [tab, setTab] = useState(1);
  const [matchPartnerData, setMatchPartnerData] = useState<any>();
  const [isVerifyDataLoading, setisVerifyDataLoading] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [verificatioStatus, setVerificationStatus] = useState()
  const [partnerImage, setPartnerImage] = useState([]);
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  function onSucessGettingUsersProfileImgs(data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image?.data);
    setLoading(false);
  }
  function onSucessGettingPartnerImgs(data: any) {
    setPartnerImage(data);
    setLoading(false);
  }
  function onFail(message: any) {
    console.error(message);
    setisVerifyDataLoading(false);
    setLoading(false);
  }
  
  function onSuccessGetVerifyUserData(data: any) {
    setMatchPartnerData(data.findMatchingPartner.matchingPartner[0]);
    setVerificationStatus(data.findMatchingPartner.isAccountVerified)

    setisVerifyDataLoading(false);
  }
  function userStatus() {
    if (verificatioStatus === true) {

      return (
        <div className="inline-flex items-center">
          <span className="h-4 w-4 rounded-full bg-green-500 mr-1"></span>
        </div>
      );
      
    } else {      
      return null;
    }
  }
  useEffect(() => {
    if (partnerIds !== 0) {
      const GET_VERIFY_USER_DATA = `${findMatchingPartner}${partnerIds._id}`;
      getContent(GET_VERIFY_USER_DATA, onSuccessGetVerifyUserData, onFail);

      setLoading(true);
    }
  }, []);
  useEffect(() => {
    if (partnerIds.imageIds && partnerIds.imageIds.length > 0 ) {
      const ENDPOINTS = partnerIds.imageIds.map(
        (imageId: any) => `${getpartnerImage}${imageId}`
      );
      fetchAndDisplayImages(ENDPOINTS, onSucessGettingPartnerImgs, onFail);

      setImageLoading(true);
    }
  }, [partnerIds]);

  useEffect(() => {
    const USER_IMG = `${getReportedUserProfilePicture}${partnerIds.userId}`;
    getContent(USER_IMG, onSucessGettingUsersProfileImgs, onFail);
    setLoading(true);
  }, []);

  let user: any = {};

  useEffect(() => {
    if (matchPartnerData) {
      user = matchPartnerData;
    }
  }, [matchPartnerData]);

  const UserDetail = () => {
    return (
      <div className={`${tab === 1 ? "block  " : "hidden"}  `}>
      
          <>
            {matchPartnerData ? (
              <div className="scrollbar-hide  overflow-y-auto h-[50%]">
                <div className="flex flex-col gap-6 mb-4 ">
                  <div className="flex  gap-6 items-center ">
                    {userProfilePic ? (
                      <img
                        className=" object-cover border rounded-full  h-16  "
                        src={`data:image/jpeg;base64,${userProfilePic}`}
                        alt={user?.firstName}
                      />
                    ) : (
                      <UserNameAvatar
                        userName={matchPartnerData?.firstName}
                        userType={user.USER}
                      />
                    )}
                    <p className="font-bold text-lg">
                      {matchPartnerData?.firstName} {matchPartnerData?.lastName}
                    </p>
                    {userStatus()}
                  </div>
                  {matchPartnerData?.userId[0]?.phoneNumber && (
                    <p
                    >{matchPartnerData?.userId[0]?.phoneNumber}
                    </p>
                  )}
                  
                  <div className=" h-[60vh] overflow-auto">
                  <div>
                    <p className="text-gray-400 text-sm">Bio</p>
                    <p className=" text-gray-600 text-sm">
                      {" "}
                      {matchPartnerData?.bio}
                    </p>
                  </div>
                    <div className="gap-2 divide-y-2 capitalize my-2">
                      {matchPartnerData?.status && (
                        <InfoCard
                          value={matchPartnerData?.status}
                          label="Status"
                        />
                      )}
                      {matchPartnerData?.category && (
                        <InfoCard
                          value={matchPartnerData?.category}
                          label="category"
                        />
                      )}

                      {matchPartnerData?.gender && (
                        <InfoCard
                          value={matchPartnerData?.gender}
                          label="Gender"
                        />
                      )}
                      {matchPartnerData?.age && (
                        <InfoCard value={matchPartnerData?.age} label="Age" />
                      )}
                      {matchPartnerData?.userId[0]?.address?.country && (
                        <InfoCard
                          value={matchPartnerData?.userId[0]?.address?.country}
                          label="Country"
                        />
                      )}
                      {matchPartnerData?.userId[0]?.address?.region && (
                        <InfoCard
                          value={matchPartnerData?.userId[0]?.address?.region}
                          label="Region"
                        />
                      )}
                      {matchPartnerData?.profession && (
                        <InfoCard
                          value={matchPartnerData?.profession}
                          label="Profession"
                        />
                      )}
                      {matchPartnerData?.assets && (
                        <InfoCard
                          value={matchPartnerData?.assets}
                          label="Assets"
                        />
                      )}
                      {matchPartnerData?.maritalStatus && (
                        <InfoCard
                          value={matchPartnerData?.maritalStatus}
                          label="maritalStatus"
                        />
                      )}
                      {matchPartnerData?.residenceLocation && (
                        <InfoCard
                          value={matchPartnerData?.residenceLocation}
                          label="residenceLocation"
                        />
                      )}
                      {matchPartnerData?.educationLevel && (
                        <InfoCard
                          value={matchPartnerData?.educationLevel}
                          label="educationLevel"
                        />
                      )}
                      {matchPartnerData?.wantsChildren && (
                        <InfoCard
                          value={matchPartnerData?.wantsChildren}
                          label="wantsChildren"
                        />
                      )}
                      {matchPartnerData?.hasChildren && (
                        <InfoCard
                          value={matchPartnerData?.hasChildren}
                          label="hasChildren"
                        />
                      )}
                      {matchPartnerData?.conduct && (
                        <InfoCard
                          value={matchPartnerData?.conduct}
                          label="conduct"
                        />
                      )}
                      {matchPartnerData?.createdAt && (
                        <InfoCard
                          value={matchPartnerData?.createdAt.split("T")[0]}
                          label="Date"
                        />
                      )}
                    </div>
                    <div className="mt-2">
                      <p className=" text-sm  text-[#999999]">
                        Uploaded Images
                      </p>
                      <div className="grid grid-cols-2 gap-4 my-4">
                        {imageLoading ? (
                          partnerImage.length > 0 ? (
                            <>
                              {partnerImage.map(
                                (imageUrl: any, index: number) => {
                                  const handleClick = () => {
                                    setOpenZoomPic(true);
                                    setSelectedImage(imageUrl);
                                  };
                                  return (
                                    <div key={index}>
                                      <img
                                        className="object-cover w-full h-full rounded-md"
                                        onClick={handleClick}
                                        src={imageUrl}
                                        alt={`Partner ${index + 1}`}
                                      />
                                      {OpenZoomPic && (
                                        <PostImageZoomPopup
                                          imageUrl={selectedImage}
                                          onClose={() => setOpenZoomPic(false)}
                                        />
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>Loading...</div>
                          )
                        ) : (
                          <div>This post does not have any images.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <DetailSpinner />
              </div>
            )}
          </>
      </div>
    );
  };
  
  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [selectedActionType, setSelectedActionType] = useState("");
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [accountType, setAccountType] = useState<any>({});
    const [newMessage, setNewMessage] = useState<string>('');

    useEffect(() => {
      const existingAccount = localStorage.getItem("adminManager");
      if (existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, []);
    useEffect(() => {
      if (accountType?.isAdminManager === true) {
        setNoteToAdminManager("adminManager");
      }
    }, [accountType?.isAdminManager]);
    const onSuccess = (data: any, response: any) => {
      setPendingMatchPartners((prevUsers: any) => {
        const nextUsers = [...prevUsers]?.filter(
          (user: any) => user._id !== partnerIds._id
        );
        return nextUsers;
      });

      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };
    const onSuccessMessage = (data: any, response: any) => {
      
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };
    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        actionType: selectedActionType,
        matchingPartnerId: partnerIds._id,
      };

      createContent(takeActionOnMatchingPartner, data, onSuccess, onFail);
    };
    const sentMessage = () => {
      const data = {
        message: newMessage,
        userId: partnerIds.userId,
      };

      createContent(sendMessageToUser, data, onSuccessMessage, onFail);
    };
    return (
      <div className={` h-full ${tab === 2 ? "block  " : "hidden"}`}>
        <div className="flex flex-col justify-between h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
          <div>
            <h2 className=" text-lg font-semibold  mb-2 left-2">
              Action Type{" "}
            </h2>
            <Dropdown
              name={selectActionType}
              list={VerifyMatchPartner}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText);
              }}
            />

            {accountType?.isAdminManager === false && (
              <TextArea
                text={noteToAdmin}
                change={(e: ChangeEvent<HTMLInputElement>) => {
                  setNoteToAdminManager(e.target.value);
                }}
              />
            )}
          </div>
          <div className='flex justify-between items-center p-4 border-t border-gray-200'>
        <textarea
          className='w-3/4 p-2 border rounded'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='Type your message...'
        />
        <button
          className='bg-blue-500 text-white p-2 rounded cursor-pointer'
          onClick={sentMessage}
          disabled={!newMessage.trim()}
          >
          Send
        </button>
      </div>    
          <Button
            disabled={!selectedActionType || !noteToAdminManager}
            text={reviewMatchPartnerUsers}
            onClick={() => setShowPopup(true)}
          />
        </div>
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-40 flex overflow w-full items-center justify-center bg-gray-900 bg-opacity-50 ">
      <div className="p-8 absolute  top-0 right-0 h-full w-[30%] bg-white rounded-l-md z-50  ">
        <div className="flex justify-end">
          <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
        </div>
        <div className=" h-full space-y-8 ">
          <div className="flex  font-bold ">
            <p
              onClick={() => setTab(1)}
              className={`p-4 cursor-pointer ${
                tab === 1 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              Post Detail
            </p>
            <p
              onClick={() => setTab(2)}
              className={`p-4 cursor-pointer ${
                tab === 2 && "border-b-4 border-[#2e2e2e]"
              }`}
            >
              Take Action
            </p>
          </div>
          <UserDetail />
          <TakeAction />
        </div>
      </div>
    </div>
  );
};

export default ViewPendingMatchPartner;
