import { useState, useEffect, useContext } from 'react'
import {
  getReportedUserProfilePicture,
  getReportedUserImage
} from '../adapters/endpoints'
import { getContent } from '../adapters/get'
import { InfoCard } from './ViewReportedUser'
import { UserNameAvatar } from './userNameAvatar'
import { UserType, error } from '../constants'
import { ToastContext } from './Toast/ToastProvider'
import ImageViewer from './ImageViewer'
import { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'

interface userReportProps {
  userReportData: any
  tab: any
}
interface ReporterUser {
  lastSeenAt: any
  rating: any
  phoneNumber: any
  _id: string
  firstName?: string
  lastName?: string
  bio?: string
  age?: string
  gender?: string
  height?:  {
    ft: number,
    m: number
  },
  location?: string
  address?: {
    region: any
    country: string
  }
  jobTitle?: string
  accountStatus?: string
}
const UserDetailComponent = ({ userReportData, tab }: userReportProps) => {
  const [userProfilePicture, setUserProfilePic] = useState<any>()
  const [reporterImg, setReporterImg] = useState<any>(null)
  const { handleCreateToast } = useContext(ToastContext)

  useEffect(() => {
    if (userReportData) {
      const ReporterUserId = userReportData?.reporterId[0]?._id
      const REPORTER_USER_PROFILE_IMG = `${getReportedUserProfilePicture}${ReporterUserId}`
      const REPORTER_IMAGE = `${getReportedUserImage}${ReporterUserId}`

      getContent(REPORTER_USER_PROFILE_IMG,setUserProfilePic,handleCreateToast )
      getContent(REPORTER_IMAGE, setReporterImg, handleCreateToast)
    }
  }, [userReportData])

  return (
    <div
      className={`${
        tab === 1 ? 'block' : 'hidden'
      } scrollbar-hide h-[75vh] overflow-auto`}
    >
      {userReportData ? (
        <div>
          {userProfilePicture ? (
            <img
              className='object-cover border rounded-full h-16 w-16'
              src={`data:image/jpeg;base64,${userProfilePicture?.userProfilePicture[0]?.image?.data}`}
              alt={userReportData?.reporterId[0]?.firstName}
            />
          ) : (
            <UserNameAvatar
              userName={userReportData?.reporterId[0]?.firstName}
              userType='sender'
            />
          )}
          {/* return ( */}
          <div className='flex flex-col gap-6 mb-4'>
            <div className='flex flex-col gap-1'>
              {userProfilePicture?.image?.data}
            </div>
            {userReportData.reporterId.map(
              (reporter: ReporterUser, index: number) => {
                return (
                  <ReporterDetails
                    reporter={reporter}
                    reporterImg={reporterImg}
                    key={index}
                  />
                )
              }
            )}
            {/* <div>
              <p className=' text-md text-gray-400'>Complaint</p>
              <p className=' text-gray-800 font-medium'>
                {userReportData.description}
              </p>
            </div> */}
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
          <DetailSpinner />
        </div>
      )}
    </div>
  )
}

interface ReporterDetailsProps {
  reporter: ReporterUser
  reporterImg: any
}

const ReporterDetails = ({ reporter, reporterImg }: ReporterDetailsProps) => {
  const [OpenZoomPic, setOpenZoomPic] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  return (
    <div>
      <div className='space-y-2'>
        <p className='font-bold text-gray-800 text-2xl'>
          {reporter.firstName} {reporter.lastName ?? ""}
        </p>
        {reporter.phoneNumber && <p> {reporter.phoneNumber}</p>}

        <p className='text-gray-400'>{reporter?.bio}</p>
      </div>

      <div className=' h-[60vh] overflow-auto'>
      <div className='gap-2 divide-y-2 capitalize my-4'>
        {/* {reporter?.height?.m && <InfoCard value={reporter.height.m} label='Height' />} */}
        {reporter.accountStatus&& (
                  <InfoCard
                    value={reporter?.accountStatus}
                    label='Account Status'
                  />
                )}
               {reporter.rating && <InfoCard value={reporter.rating} label='Rate' />}
               {reporter.gender && <InfoCard value={reporter.gender} label='Gender' />}

        {reporter.age && <InfoCard value={reporter.age} label='Age' />}

        {reporter?.address?.country && (
          <InfoCard value={reporter?.address?.country} label='Country' />
        )}
       {reporter?.address?.region && <InfoCard value={reporter?.address?.region} label='Region' />}  
       {reporter.lastSeenAt && <InfoCard value={reporter.lastSeenAt.split("T")[0]} label='Last Seen' />}
      
      </div>
      <p className='text-sm text-[#999999]'>Uploaded Images</p>
      <div className='grid grid-cols-2 gap-4 my-4'>
        {reporterImg?.length > 0 ? (
          <>
            {reporterImg?.map((img: any, idx: number) => {
              const handleClick = () => {
                setOpenZoomPic(true)
                setSelectedImage(img?.image?.data)
              }
              return (
                <>
                  <img
                    className='object-cover w-full h-64 rounded-md'
                    src={`data:image/jpeg;base64,${img?.image?.data}`}
                    onClick={handleClick}
                    alt={reporter.firstName}
                  />
                  {OpenZoomPic && (
                    <ImageViewer
                      imageUrl={selectedImage}
                      onClose={() => setOpenZoomPic(false)}
                    />
                  )}
                </>
              )
            })}
          </>
        ) : (
          <LoadingDots />
          )}
      </div>
      </div>
    </div>
  )
}

export default UserDetailComponent
