import { useState, ChangeEvent, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import Dropdown from './Dropdown'
import AlertPopup from './AlertPopup'
import TextArea from './TextArea'
import Button from './Button'
import {
  takeActionTextAreaText,
  verificationUpdateButton,
  selectActionType,
  verifyActionType,
  noteToAdmin,
  TakeActionAlertText
} from '../constants'
import { ToastContext } from '../components/Toast/ToastProvider'
import { error, success } from '../constants'
import { createContent } from '../adapters/create'
import {
  findVerifyImgRequestEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  getUserVerificationRequestImage,
  sendMessageToUser,
  takeActionOnUserVerificationRequestEndPoint
} from '../adapters/endpoints'
import { getContent } from '../adapters/get'
import  { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'
import { UserNameAvatar } from './userNameAvatar'
import ImageViewer from './ImageViewer'
import InfoCard from './InfoCard'


interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>
  userIds: any
  setVerificationPendingUsers: any
}

const ViewUser: React.FC<Props> = ({
  setShowUser,
  userIds,
  setVerificationPendingUsers
}) => {
  const [tab, setTab] = useState(1)
  const [userVerifyData, setUserVerifyData] = useState<any>()
  const [isVerifyDataLoading, setisVerifyDataLoading] = useState(false)
  const [images, setImages] = useState<any>()
  const [responsibleImg, setResponsibleImg] = useState<any>(null)
  const [userProfilePic, setUserProfilePic] = useState()
  const [OpenZoomPic, setOpenZoomPic] = useState(false)
  const [openVerificationImage, setOpenVerificationImage] = useState(false)
  const [openReferenceImage, setOpenReferenceImage] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [loading, setLoading] = useState(false)
  function onSucessGettingUsersVerificationImgs (data: any) {
    setImages(data.image)
    setLoading(false)

  }

  function onSucessGettingUsersImgs (data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image?.data)
    setLoading(false)

  }

  function onSucessGettingUsersUploadedImgs (data: any) {
    setResponsibleImg(data)
    setLoading(false)
  }

  function onFail (message: any) {
    console.error(message)
    setisVerifyDataLoading(false)
    setLoading(false)
  }

  function onSuccessGetVerifyUserData (data: any) {
    setUserVerifyData(data.verificationRequest[0])
    setisVerifyDataLoading(false)
  }

  useEffect(() => {
    if (userIds.verificationRequestId !== 0) {
      const GET_VERIFY_USER_DATA = `${findVerifyImgRequestEndPoint}${userIds.verificationRequestId}`
      getContent(GET_VERIFY_USER_DATA, onSuccessGetVerifyUserData, onFail)
      setisVerifyDataLoading(true)
    }
  }, [])

  useEffect(() => {
    const RESPONSIBE_ID = `${getReportedUserImage}${userIds._id}`
    getContent(RESPONSIBE_ID, onSucessGettingUsersUploadedImgs, onFail)

    const USER_IMG = `${getReportedUserProfilePicture}${userIds._id}`
    getContent(USER_IMG, onSucessGettingUsersImgs, onFail)

    const VERIFY_USER_IMG = `${getUserVerificationRequestImage}${userIds.verificationRequestId}`
    getContent(VERIFY_USER_IMG, onSucessGettingUsersVerificationImgs, onFail)
    setLoading(true)
  }, [])

  let user: any = {}

  useEffect(() => {
    if (userVerifyData) {
      user = userVerifyData?.userInfoId[0]
    }
  }, [userVerifyData])

  const referThisImg =
    images?.referenceImageForVerificationRequest[0]?.image?.data
  const imgForVerification =
    images?.findUserImageToRespondUserVerificationRequest?.image?.data


  const UserDetail = () => {
    return (
      <div className={`${tab === 1 ? 'block  ' : 'hidden'}  `}>
        {loading ? (
      <DetailSpinner />
        ):(
          <>
           {userVerifyData ? (
          <div className='scrollbar-hide  overflow-y-auto h-[60%]'>
            <div className='flex flex-col gap-6 mb-4 '>
              <div className='flex  gap-6 items-center '>
                {userProfilePic ? (
                  <img
                    className=' object-cover border rounded-full  h-16 w-16 '
                    src={`data:image/jpeg;base64,${userProfilePic}`}
                    alt={user?.firstName}
                  />
                ) : (
                  <UserNameAvatar
                    userName={userVerifyData?.userInfoId[0]?.firstName}
                    userType={user.USER}
                  />
                )}
                <p className='font-bold text-lg'>
                  {userVerifyData?.userInfoId[0]?.firstName}{' '}
                  {userVerifyData?.userInfoId[0]?.lastName}

                </p>
              </div>
                 <p>
                  Phone number:{" "}
                  {userVerifyData?.userInfoId[0] && userVerifyData?.userInfoId[0]?.phoneNumber}
                </p>
              <div className=' h-[60vh] overflow-auto'> 
              <div className='gap-2 divide-y-2 capitalize my-2'>
              {userVerifyData?.userInfoId[0]?.accountStatus && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.accountStatus}
                    label='Account Status'
                  />
                )}
                 {userVerifyData?.userInfoId[0]?.rating && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.rating}
                    label='Rate'
                  />
                )}
               {userVerifyData?.userInfoId[0]?.gender && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.gender}
                    label='Gender'
                  />
                )}
                {userVerifyData?.userInfoId[0]?.age && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.age}
                    label='Age'
                  />
                )}
                   {userVerifyData?.userInfoId[0]?.address?.country && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.address?.country}
                    label='Country'
                  />
                )}
                {userVerifyData?.userInfoId[0]?.address?.region && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.address?.region}
                    label='Region'
                  />
                )}
                 {userVerifyData?.userInfoId[0]?.lastSeenAt && (
                  <InfoCard
                    value={userVerifyData?.userInfoId[0]?.lastSeenAt.split('T')[0]}
                    label='Last seen'
                  />
                )}
              </div>
              <div className='mt-2 h-[100%]'>
                <p className=' text-sm  text-[#999999]'>Uploaded Images</p>
                <div className='grid grid-cols-2 gap-4 my-4'>
                  {responsibleImg?.length > 0 ? (
                    <>
                      {responsibleImg?.map((img: any, idx: number) => {
                        const handleClick = () => {
                          setOpenZoomPic(true);
                          setSelectedImage(img?.image?.data);
                        };
                        return (
                          <>
                            <img
                              key={idx}
                              className=' object-cover w-full h-64 rounded-md'
                              onClick={handleClick}
                              src={`data:image/jpeg;base64,${img?.image?.data}`}
                              alt=' Img took for Verification'
                            />
                            {OpenZoomPic && (
                              <ImageViewer
                                imageUrl={selectedImage}
                                onClose={() => setOpenZoomPic(false)}
                              />
                            )}
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <LoadingDots />

                  )}
                </div>
                <div className='grid grid-cols-2 gap-4'>
                  <p className=' text-sm  text-[#999999]'>
                    Image took for Verification
                  </p>
                  <p className=' text-sm  text-[#999999]'>Image for Reference</p>
                </div>
                <div className='grid grid-cols-2 gap-4 my-4'>
                  <img
                    className=' object-cover w-full h-64 rounded-md'
                    onClick={() => setOpenVerificationImage(true)}
                    src={`data:image/jpeg;base64,${imgForVerification}`}
                    alt=' Img took for Verification'
                  />
                  {openVerificationImage && (
                    <ImageViewer
                      imageUrl={imgForVerification}
                      onClose={() => setOpenVerificationImage(false)}
                    />
                  )}
                  <img
                    className=' object-cover w-full h-64 rounded-md'
                    onClick={() => setOpenReferenceImage(true)}
                    src={`data:image/jpeg;base64,${referThisImg}`}
                    alt=' Img took for Verification'
                  />
                  {openReferenceImage && (
                    <ImageViewer
                      imageUrl={referThisImg}
                      onClose={() => setOpenReferenceImage(false)}
                    />
                  )}
                </div>
              </div>
            </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <SideBarSpinner />
          </div>
        )}
          </>
        )
        }
       
      </div>
    )
  }

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false)
    const [selectedActionType, setSelectedActionType] = useState('')
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext)
    const [accountType,setAccountType] = useState<any>({})
    const [newMessage, setNewMessage] = useState<string>('');

    useEffect(() => {
  
      const existingAccount =localStorage.getItem('adminManager');
      if(existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, [])
    useEffect(() => {
      if(accountType?.isAdminManager === true){
        setNoteToAdminManager('adminManager')        
      }
    }, [accountType?.isAdminManager])
    const onSuccess = (data: any, response: any) => {
      setVerificationPendingUsers((prevUsers: any) => {
        const nextUsers = [...prevUsers]?.filter(
          (user: any) => user._id !== userIds.verificationRequestId
        )
        return nextUsers
      })

      setShowUser(false)
      handleCreateToast(response.message, success)
      setIsLoading(false); 
    }

    const onFail =(message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false); 
    }

    const submitReview = () => {
      setIsLoading(true); 
      const data = {
        // reviewerReasonForTakingAction: reviewDescription,
        actionType: selectedActionType,
        verif_req_image_id: userIds.verificationRequestId,
        noteToAdminManager: noteToAdminManager
      }

      createContent(
        takeActionOnUserVerificationRequestEndPoint,
        data,
        onSuccess,
        onFail
      )
    }
    const sentMessage = () => {
      const data = {
        message: newMessage,
        userId: userIds?._id,
      };

      createContent(sendMessageToUser, data, onSuccess, onFail);
    };
    return (
      <div className={` h-full ${tab === 2 ? 'block  ' : 'hidden'}`}>
        <div className='flex flex-col justify-between h-full max-h-[85%] scrollbar-hide  overflow-y-auto '>
          <div>
            <h2 className=' text-lg font-semibold  mb-2 left-2'>
              Action Type{' '}
            </h2>
            <Dropdown
              name={selectActionType}
              list={verifyActionType}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText)
              }}
            />
            {/* <TextArea
              text={takeActionTextAreaText}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setReviewDescription(e.target.value)
              }}
            /> */}
           {accountType?.isAdminManager === false && (
            <TextArea
              text={noteToAdmin}
              change={(e: ChangeEvent<HTMLInputElement>) => {
                setNoteToAdminManager(e.target.value)
              }}
            />
          )}
          </div>
          <div className='flex justify-between items-center p-4 border-t border-gray-200'>
        <textarea
          className='w-3/4 p-2 border rounded'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='Type your message...'
        />
        <button
          className='bg-blue-500 text-white p-2 rounded cursor-pointer'
          onClick={sentMessage}
          disabled={!newMessage.trim()}
          >
          Send
        </button>
      </div>    
          <Button
            disabled={!selectedActionType || !noteToAdminManager}
            text={verificationUpdateButton}
            onClick={() => setShowPopup(true)}
          />
        </div>
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false)
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    )
  }

  return (
    <div className='fixed inset-0 z-40 flex overflow w-full items-center justify-center bg-gray-900 bg-opacity-50 '>
      <div className='p-8 absolute  top-0 right-0 h-full w-[30%] bg-white rounded-l-md z-50  '>
        <div className='flex justify-end'>
          <X onClick={() => setShowUser(false)} className=' cursor-pointer ' />
        </div>
        <div className=' h-full space-y-8 '>
          <div className='flex  font-bold '>
            <p
              onClick={() => setTab(1)}
              className={`p-4 cursor-pointer ${
                tab === 1 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              User Detail
            </p>
            <p
              onClick={() => setTab(2)}
              className={`p-4 cursor-pointer ${
                tab === 2 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Take Action
            </p>
          </div>
          <UserDetail />
          <TakeAction />
        </div>
      </div>
    </div>
  )
}

export default ViewUser
