const addReviewer = "/api/v1/admin/addReviewerForReviewUserRequests";
const createSubscriptionPlansEndPoint = "/api/v1/plan/createSubscriptionPlans";
const signUpEndPoint =
  "/api/v1/temporaryDataForAdminSignUp/createTemporaryAdminDataForSignUp";
const signInEndPoint = "/api/v1/admin/adminSignIn";
const signOutEndPoint = "/api/v1/admin/adminSignOut";
const changePasswordEndPoint = "/api/v1/admin/changeAdminPassword";
const resetPasswordEndPoint =
  "/api/v1/temporaryAdminDataForForgetPassword/createTemporaryAdminDataForForgetPassword";
const getAdminEndPoint = "/api/v1/admin/findAdmin/";
const findAllAdminsEndPoint = "/api/v1/admin/findAllAdmins";
const updateAdminEndPoint = "/api/v1/admin/updateAdminManagerInfo/";
const deleteAdminEndPoint = "/api/v1/admin/deleteAdmin";
const deleteSubscriptionPlanEndPoint = "/api/v1/plan/deleteSubscriptionPlan";
const updateSubscriptionPlanEndPoint = "/api/v1/plan/updatSubscriptionPlan";
const deactivateSubscriptionPlanEndPoint = "/api/v1/plan/deactivateSubscriptionPlan";
const findAllSubscriptionPlansEndPoint =
  "/api/v1/plan/findAllSubscriptionPlans";
const getListReportedUsers = "/api/v1/userReport/findReviewedUserReports";
const getReportedChatsUsers =
  "/api/v1/reportedChats/findReviewedUserChatReports";
const getVerificationAppliedUsers =
  "/api/v1/verifImgReq/findReviewedVerifReqImg";
const getCountReportedUsers = "/api/v1/adminDashbord/countReportedUsers";
const getUser = "api/v1/user/findUser";

const findAllReportedChatsEndPoint =
  "/api/v1/reportedChats/findAllReportedChats";
const findReportedChatEndPoint = "/api/v1/reportedChats/findReportedChat/";
const takeActionOnReportedChatEndPoint =
  "/api/v1/reportedChats/takeActionOnReportedChat";
const takeActionOnReportedUserEndPoint =
  "/api/v1/reportedUsers/takeActionOnReportedUser";
const findAllReportedUsersEndPoint =
  "/api/v1/reportedUsers/findAllReportedUsers";
const findReportedUserEndPoint = "/api/v1/reportedUsers/findReportedUser/";
const takeActionOnUserVerificationRequestEndPoint =
  "/api/v1/userVerificationRequests/takeActionOnUserVerificationRequest";
const findVerifyImgRequestEndPoint =
  "/api/v1/userVerificationRequests/findUserVerificationRequest/";
const findAllVerifyImgRequestEndPoint =
  "/api/v1/userVerificationRequests/findAllUserVerificationRequests";
const deActivateAdminAccount = "/api/v1/admin/deactivateAdminAccount/";
const removeFromReviewer =
  "/api/v1/admin/removeAdminFromReviewingUserRequests/";
const findReviewedReportedUser = `/api/v1/reportedUsers/findReviewedReportedUser/`;
const findReviewedUserVerificationRequest = `/api/v1/userVerificationRequests/findReviewedUserVerificationRequest/`;
const findReviewedReportedChat = `/api/v1/reportedChats/findReviewedReportedChat/`;
const updateReviewerRole = `/api/v1/admin/updateReviewerRole/`;
const forgetAdminPassword = `/api/v1/admin/forgotAdminPassword`;
const countUsersBasedOnTheirStatus =
  "/api/v1/adminDashbord/countUsersBasedOnTheirStatus";
const countUsersBasedOnTheirAge =
  "/api/v1/adminDashbord/countUsersBasedOnTheirAge";
const countUsersBasedOnTheirGender =
  "/api/v1/adminDashbord/countUsersBasedOnTheirGender";
const countUsersBasedOnTheirCountry =
  "/api/v1/adminDashbord/countUsersBasedOnTheirCountry";
const getSubscribedUsersEndpoint = "/api/v1/adminDashbord/getSubscriberUsers";
const findAllReviewedRequestsByReviewerEndPoint =
  "/api/v1/adminDashbord/findAllReviewedRequestsByReviewerWithID/";
const getTotalSubscribedAmount =
  "/api/v1/adminDashbord/getTotalSubscribedAmount";

const modifyReviewerActionTypeOnReportedUser =
  "/api/v1/reportedUsers/modifyReviewerActionTypeOnReportedUser";
  const modifyReviewerActionTypeOnUserVerificationRequest ="/api/v1/userVerificationRequests/modifyReviewerActionTypeOnUserVerificationRequest"
  const modifyReviewerActionTypeOnReportedChat ="/api/v1/reportedChats/modifyReviewerActionTypeOnReportedChat"
const getSubscribedAmountWithPlanName=  "/api/v1/adminDashbord/getSubscribedAmountWithPlanName";

const getReportedUserImage= "/api/v1/reportedUsers/findUserImages/";
const getReportedUserProfilePicture="/api/v1/reportedUsers/findUserProfilePicture/";
const getUserVerificationRequestImage="/api/v1/userVerificationRequests/findUserImageToRespondUserVerificationRequest/";
const getCountUserRequestsByReviewerId="/api/v1/adminDashbord/countUserRequestsByReviewerId/";
const getCountUsersRequestsBasedOnReviewerId = "/api/v1/adminDashbord/countUserRequestsBasedOnReviewerId"
const findMessagesOfReportedChat='/api/v1/reportedChats/findMessagesOfReportedChat/'
const AddEngineers='/api/v1/admin/addEngineersEmail'
const SearchUserEndpoint = "/api/v1/adminDashbord/searchUser/";
const GetUserInfoEndpoint = "/api/v1/adminDashbord/getUserInfo/";
const searchMatchPartnerEndpoint = "/api/v1/matchPartner/searchMatchPartners/";


const takeActionOnUserEndpoint =
  "/api/v1/adminDashbord/takeActionOnUser";
const getReviewedUserVerificationRequestsEndpoint="/api/v1/userVerificationRequests/getReviewedUserVerificationRequests/";
const getReviewedReportedUserEndpoint="/api/v1/reportedUsers/getReviewedReportedUser/";
const getReviewedReportedChatsEndpoint="/api/v1/reportedChats/getReviewedReportedChats/";
const getChatBots="/api/v1/chatBots/getChatBots"
const getBotMessages='/api/v1/chatBots/getBotMessages/' 
const sendMessageToUser='/api/v1/chatBots/sendMessageToUser'
const saveUserSubscription = '/api/v1/admin/saveUserSubscription'
const listReviewVerifiedUsers = "/api/v1/userVerificationRequests/getVerifiedUsers";
const updateUserRate = "/api/v1/adminDashbord/updateUserRating";
const getSubscriptionHistory = "/api/v1/admin/getSubscriptionHistory/";
const findAllPendingMatchPartners = "/api/v1/matchPartner/findAllPendingMatchPartners"
const findAllActiveMatchPartners = "/api/v1/matchPartner/findAllActiveMatchPartners"
const findMatchingPartner = "/api/v1/matchPartner/findMatchingPartner/"
const takeActionOnMatchingPartner = "/api/v1/matchPartner/takeActionOnMatchingPartner"
const getpartnerImage = '/api/v1/matchPartner/getpartnerImage/'
const updateMistakeGender =  "/api/v1/adminDashbord/updateMistakeGender"
const updateMistakePreferenceGender =  "/api/v1/adminDashbord/updateMistakePreferenceGender"
export { 
  findAllPendingMatchPartners,
  findAllActiveMatchPartners,
  findMatchingPartner,
  takeActionOnMatchingPartner,
  addReviewer,
  createSubscriptionPlansEndPoint,
  updateSubscriptionPlanEndPoint,
  signUpEndPoint,
  signInEndPoint,
  changePasswordEndPoint,
  resetPasswordEndPoint,
  getAdminEndPoint,
  findAllAdminsEndPoint,
  deleteAdminEndPoint,
  deleteSubscriptionPlanEndPoint,
  deactivateSubscriptionPlanEndPoint,
  findAllSubscriptionPlansEndPoint,
  updateAdminEndPoint,
  signOutEndPoint,
  getListReportedUsers,
  getReportedChatsUsers,
  getVerificationAppliedUsers,
  getCountReportedUsers,
  getUser,
  findAllReportedChatsEndPoint,
  findReportedChatEndPoint,
  takeActionOnReportedChatEndPoint,
  takeActionOnReportedUserEndPoint,
  findAllReportedUsersEndPoint,
  findReportedUserEndPoint,
  takeActionOnUserVerificationRequestEndPoint,
  findVerifyImgRequestEndPoint,
  findAllVerifyImgRequestEndPoint,
  countUsersBasedOnTheirStatus,
  countUsersBasedOnTheirAge,
  countUsersBasedOnTheirGender,
  countUsersBasedOnTheirCountry,
  getSubscribedUsersEndpoint,
  deActivateAdminAccount,
  removeFromReviewer,
  findReviewedReportedUser,
  updateReviewerRole,
  forgetAdminPassword,
  findAllReviewedRequestsByReviewerEndPoint,
  getTotalSubscribedAmount,
  findReviewedUserVerificationRequest,
  findReviewedReportedChat,
  getSubscribedAmountWithPlanName,
  getReportedUserImage,
  getReportedUserProfilePicture,
  getUserVerificationRequestImage,
  modifyReviewerActionTypeOnReportedUser,
  getCountUserRequestsByReviewerId,
  getCountUsersRequestsBasedOnReviewerId,
  modifyReviewerActionTypeOnUserVerificationRequest,
  modifyReviewerActionTypeOnReportedChat,
  findMessagesOfReportedChat,
  AddEngineers,
  SearchUserEndpoint,
  GetUserInfoEndpoint,
  takeActionOnUserEndpoint,
  getReviewedReportedChatsEndpoint,
  getReviewedReportedUserEndpoint,
  getReviewedUserVerificationRequestsEndpoint,
  getChatBots,
  getBotMessages,
  sendMessageToUser,
  saveUserSubscription,
  listReviewVerifiedUsers,
  updateUserRate,
  getSubscriptionHistory,
  searchMatchPartnerEndpoint,
  getpartnerImage,
  updateMistakeGender,
  updateMistakePreferenceGender
};
