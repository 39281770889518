import React from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    // const pageNumbers = [];

    // if (totalPages <= 5) {
    //   for (let i = 1; i <= totalPages; i++) {
    //     pageNumbers.push(i);
    //   }
    // } else {
    //   if (currentPage <= 2) {
    //     pageNumbers.push(1, 2, 3, '...', totalPages);
    //   } else if (currentPage >= totalPages - 1) {
    //     pageNumbers.push(1, totalPages - 2, totalPages - 1, totalPages);
    //   } else {
    //     pageNumbers.push(1, currentPage + 1, currentPage + 2, '...', totalPages);
    //   }
    // }

    // return pageNumbers;

    // const pageNumbers = [];
		// if (totalPages <= 5) {
		// 	for (let i = 1; i <= totalPages; i++) {
		// 		pageNumbers.push(i);
		// 	}
		// } else {
		// 	if (currentPage <= 2) {
		// 		pageNumbers.push(1, 2, 3, '...', totalPages);
		// 	} else if (currentPage >= totalPages - 2) {
		// 		pageNumbers.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
		// 	} else {
		// 		pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
		// 	}
		// }
		// return pageNumbers;
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 4);
  
      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push('...');
        }
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
  
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
      }
    }
  
    return pageNumbers;

  };

  const handlePageClick = (pageNumber: any) => {
    if (pageNumber !== '...') {
      setCurrentPage(pageNumber - 1);
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex gap-8 items-center text-center justify-center">
        {currentPage > 0 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage)}
          >
            <ChevronLeft />
          </button>
        )}
        {getPageNumbers().map((pageNumber, index) => (
          <button
            key={index}
            className={`pagination-item ${pageNumber === currentPage + 1 ? 'active' : ''}`}
            onClick={() => (typeof pageNumber === 'number' ? handlePageClick(pageNumber) : null)}
  
          >
            {pageNumber !== '...' && <span className="page-number ml-4">{pageNumber}</span>}
            {pageNumber === '...' && <span className="page-number ml-4">...</span>}
          </button>
        ))}
        {currentPage < totalPages - 1 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage + 2)}
          >
            <ChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
