import { useContext } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  accFirstName,
  accLastName,
  EMAIL_IS_MANDATORY,
  ENTER_VALID_EMAIL,
  error,
  FIRST_NAME_IS_MANDATORY,
  FIRST_NAME_MAX_CHAR_LENGTH,
  LAST_NAME_IS_MANDATORY,
  LAST_NAME_MAX_CHAR_LENGTH,
  passwordLength,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  SIGN_IN_ROUTE,
  success,
} from "../constants";
import Input from "../components/Input";
import InputPassword from "../components/InputForPassword";
import { signUpEndPoint } from "../adapters/endpoints";
import { ToastContext } from "../components/Toast/ToastProvider";
import { createContent } from "../adapters/create";

export default function SignUp() {
  const { handleCreateToast } = useContext(ToastContext);

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(FIRST_NAME_IS_MANDATORY)
      .max(accFirstName, FIRST_NAME_MAX_CHAR_LENGTH),
    lastName: Yup.string()
      .required(LAST_NAME_IS_MANDATORY)
      .max(accLastName, LAST_NAME_MAX_CHAR_LENGTH),
    email: Yup.string().email(ENTER_VALID_EMAIL).required(EMAIL_IS_MANDATORY),
    password: Yup.string()
      .required(PASSWORD_IS_MANDATORY)
      .min(passwordLength, PASSWORD_MAX_CHAR_LENGTH),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSuccess = (data: any, response: any) =>
    handleCreateToast(response.message, success);
  const onFail = (message: string) => handleCreateToast(message, error);

  return (
    <div className=" flex flex-col  h-screen  place-content-center  max-w-sm m-auto space-y-8 w-full  ">
      <h1 className="text-3xl font-medium my-4  ">Sign up </h1>
      <form
        onSubmit={handleSubmit(async (data) => {
          createContent(signUpEndPoint, data, onSuccess, onFail);
        })}
      >
        <div className="space-y-2">
          <Input
            label="First Name"
            type="text"
            name="firstName"
            register={register}
            errors={errors}
            defaultValue=""
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            register={register}
            errors={errors}
            defaultValue=""
          />
          <Input
            label="Email"
            type="email"
            name="email"
            register={register}
            errors={errors}
            defaultValue=""
          />
          <InputPassword
            label="Password"
            type="password"
            name="password"
            register={register}
            errors={errors}
            defaultValue=""
          />
        </div>
        <button className="block text-center  w-full my-8  font-semibold  bg-blue-500 text-white px-14 rounded-md py-2 ">
          Sign up
        </button>

        <div className="flex items-center gap-1  justify-center ">
          <p className=" text-zinc-400"> Already have account ? </p>
          <Link to={SIGN_IN_ROUTE} className="  text-blue-500 underline">
            Sign in{" "}
          </Link>
        </div>
      </form>
    </div>
  );
}
