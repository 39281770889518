import { createContext, useEffect, useState } from "react";

interface Admin {
    isAccountActive: boolean;
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    isAdminManager: boolean;
    role: string;
    createdAt: string;
    __v: number;
}


const defaultAdmin = {
    isAccountActive: false,
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    isAdminManager: false,
    role: '',
    createdAt: '',
    __v: 0
  }

interface AdminContextType {
  admin: Admin;
  setAdmin: React.Dispatch<Admin>
}

export const AdminManagerContext = createContext<AdminContextType>({
    admin : defaultAdmin, 
    setAdmin: () => {},
});

function AdminManagerProvider({ children }: any) {
  const KEY = "adminManager"
  const adminAsString = localStorage.getItem(KEY) || "";
  const [admin, setAdmin] = useState<Admin | any>();


  useEffect(() => {
    if (adminAsString && adminAsString !== "undefined") {
      setAdmin(JSON.parse(adminAsString));
    }
  }, [adminAsString]);


  return (
    <AdminManagerContext.Provider
      value={{
        admin,
        setAdmin,
      }}
    >
      {children}
    </AdminManagerContext.Provider>
  );
}

export default AdminManagerProvider;
