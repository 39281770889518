import React, { useState, useEffect } from 'react';

export const LoadingDots = () =>{
    const [dots, setDots] = useState('');
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
      }, 500);
  
      return () => clearInterval(intervalId);
    }, []);
  
    return (
      <div  className="items-center">
        <div className="text-sm text-[#999999]">Loading{dots}</div>
      </div>
    );
}
const Spinner = () => {
    return (
        <div className="z-50 fixed py-6 px-8 w-1/4 sm:left-[45%] top-1/2 sm:top-[44%]">
        <div className="mx-auto my-auto inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent  motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...
      </span>
      </div>
      </div>
 )}

export default Spinner

export const SideBarSpinner = () => {
  return (
    // <div className="mx-auto my-auto inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
     <div className="mx-auto my-auto inline-block h-14 w-14 animate-spin rounded-full border-4 border-solid border-current border-r-transparent  motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...
      </span>
      {/* </div> */}
    </div>
  );
};

export const DetailSpinner = () => {
  return (
    <div className="absolute inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
    <div className="mx-auto my-auto inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent  motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...
  </span>
  </div>
  </div>
)
}